import axios from 'axios'
import moment from 'moment'
import { TimeConverter } from '../components/core'

const CancelToken = axios.CancelToken
let canceler

const getFreebetReport = async (query) => {
    const response = await axios.get(`Report/freebetReport?${query}`).catch(console.log)
    return response?.data?.result
}

const exportFreebetReport = async (query, user) => {
    const config = {
        params: query,
        responseType: 'blob',
        cancelToken: new CancelToken((c) => (canceler = c))
    }

    const response = await axios
        .get(`Report/exportFreebetReport?${query}`)
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            const dateTime = new Date();
            const timeStr = TimeConverter.UtctoGmtMinus4DateTime(dateTime).format('yyyy-MM-DD_HH_mm_ss')
            link.href = url
            link.setAttribute('download', `FreebetQuery_${user.name}_${timeStr}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(console.log)
    return response?.data?.result
}

export const ReportService = {
    getFreebetReport,
    exportFreebetReport,
    cancel: () => canceler()
}