import axios from 'axios'
import { LogService } from '../services'

const getDailyMissions = async (query) => {
    const response = await axios.get(`DailyMission/listPromotions?${query}`).catch((error) => { LogService.error('getDailyMissions', JSON.stringify(error.toJSON())) })
    return response?.data?.result
}

const getDailyMission = async (id) => {
    const response = await axios.get(`DailyMission/${id}`).catch((error) => { LogService.error('getDailyMission', JSON.stringify(error.toJSON())) })
    return response?.data?.result
}

const createDailyMission = async (formData) => {
    const response = await axios.post('DailyMission', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).catch((error) => { LogService.error('createDailyMission', JSON.stringify(error.toJSON())) })
    return response?.data
}

const updateDailyMission = async (id, formData) => {
    const response = await axios.put(`DailyMission/${id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).catch((error) => { LogService.error('updateDailyMission', JSON.stringify(error.toJSON())) })
    return response?.data
}

const getDefaultDailyMissionList = async (lang, supportedSports, currency) => {
    const response = await axios.get(`DailyMission/defaultDailyMissionList?lang=${lang}&supportedSports=${supportedSports}&currency=${currency}`).catch((error) => { LogService.error('getDefaultDailyMissionList', JSON.stringify(error.toJSON())) })
    return response?.data?.result
}

const getDailyMissionTermsNConditions = async (dmTnC) => {
    const response = await axios.post(`DailyMission/dailyMissionTermsNConditions`, dmTnC).catch((error) => { LogService.error('getDailyMissionTermsNConditions', JSON.stringify(error.toJSON())) })
    return response?.data?.result
}

export const DailyMissionService = {
    getDailyMissions,
    getDailyMission,
    createDailyMission,
    updateDailyMission,
    getDefaultDailyMissionList,
    getDailyMissionTermsNConditions
}