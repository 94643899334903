import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Empty } from 'antd'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from '../utils'

export const LuckyDrawParticipantsTable = ({ dataSource, pageSize = 50 }) => {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)

  const columns = [
    { title: t('Num'), dataIndex: 'id', width: '60px', align: 'right', render: (text, record, index) => index + 1 + pageSize * (page - 1) },
    {
      title: t('Rank'),
      dataIndex: 'ranking',
      defaultSortOrder: 'ascend',
      align: 'right',
      render: (text, record, index) => <span className="ranking">{record.ranking}</span>,
      sorter: (a, b) => a.ranking - b.ranking,
      multiple: 2
    },
    {
      title: t('Member Code'),
      dataIndex: 'memberCode',
      sorter: (a, b) => a.memberCode.localeCompare(b.memberCode),
      defaultSortOrder: 'ascend',
      multiple: 1
    },
    {
      title: t('Valid Turn Over'),
      dataIndex: 'validTurnover',
      align: 'right',
      render: (text, record, index) => (
        <span className="symbol">
          <span className="currency-symbol">{record.currencyCode}</span>
          <span className="currency-value">{formatCurrency(record.validTurnover)}</span>
        </span>
      ),
      sorter: (a, b) => a.validTurnover - b.validTurnover
    },
    {
      title: t('Draw Count'),
      dataIndex: 'drawCount',
      align: 'right',
      sorter: (a, b) => a.drawCount - b.drawCount
    },
    {
      title: t('Reward Points'),
      dataIndex: 'rewardPoints',
      align: 'right',
      sorter: (a, b) => a.rewardPoints - b.rewardPoints
    }
  ]

  return (
    <Table
      columns={columns}
      scroll={{ x: 'scroll' }}
      pagination={{
        pageSize: pageSize,
        onChange(current) {
          setPage(current)
        }
      }}
      dataSource={dataSource}
      rowKey="memberCode"
      size="small"
      locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('No record found')} /> }}
    />
  )
}

LuckyDrawParticipantsTable.propTypes = {
  onRowClick: PropTypes.func,
  pageSize: PropTypes.number,
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      rank: PropTypes.number,
      memberCode: PropTypes.string,
      validTurnover: PropTypes.number,
      drawCount: PropTypes.number,
      rewardPoints: PropTypes.number
    })
  )
}
