import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Button, Popover, Empty } from 'antd'
import { InfoCircleTwoTone } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Constants, TimeConverter } from './core'
import { PermissionsService } from '../services'

export const PromoTable = ({ dataSource, isLoading, pageSize = 15, onRowClick, hidePromoCategory = false }) => {
  const { t, i18n } = useTranslation()
  const [page, setPage] = useState(1)

  function handleRowClick(data) {
    if (onRowClick) onRowClick(data)
  }

  const getTranslatedPromoName = () => {
    if (i18n.language === 'ch') return 'nameCn'
    if (i18n.language === 'th') return 'nameTh'
    if (i18n.language === 'vn') return 'nameVn'
    return 'nameEn'
    }

  const getAnyPromoName = (record) => {
    if (record.nameCn !== '') return record.nameCn
    if (record.nameTh !== '') return record.nameTh
    if (record.nameVn !== '') return record.nameVn
    return 'nameEn'
  }

  const columns = [
    { title: t('Number'), dataIndex: 'id', render: (text, record, index) => index + 1 + pageSize * (page - 1) },
    { title: t('Company'), dataIndex: 'company', isHide: !PermissionsService.isBusinessUnit(), sorter: (a, b) => a.company.localeCompare(b.merchant) },
    {
      title: t('Promo Status'),
      dataIndex: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status) => (
        <>
          <span style={{ color: Constants.statuses[status] === 'Enabled' ? 'black' : 'red' }}>{t(Constants.statuses[status])}</span>
        </>
      )
    },
    !hidePromoCategory ? {
      title: t('Promo Category'),
      dataIndex: 'category',
      sorter: (a, b) => a.category.localeCompare(b.category),
      render: (category) => (
        <>
          <span>{t(Constants.categories[category])}</span>
        </>
      )
    } : {},
    { title: t('Promo ID'), dataIndex: 'id', sorter: (a, b) => a.id - b.id },
    {
      title: t('Promo Name'),
      dataIndex: getTranslatedPromoName(),
      sorter: (a, b) => a.nameEn.localeCompare(b.nameEn),
      render: (text, record, index) => (
        <>
          {PermissionsService.checkPermission(Constants.pageActions.viewDailyMission) || PermissionsService.checkPermission(Constants.pageActions.updateDailyMission) 
          ? (<Button type="link" onClick={() => handleRowClick(record)}>
              {text !== ''? text : getAnyPromoName(record)}
            </Button>) 
          : (<Button type="text">
              {text !== ''? text : getAnyPromoName(record)}
            </Button>) 
          }
        </>
      )
    },
    { title: t('Promo Currency'), dataIndex: 'currency', sorter: (a, b) => a.currency.localeCompare(b.currency) },
    {
      title: t('Promo Start'),
      dataIndex: 'promoStart',
      sorter: (a, b) => new Date(a.promoStart) - new Date(b.promoStart),
      render: (promoStart) => (
        <>
          <span>{TimeConverter.UtctoGmtMinus4DateTime(promoStart).format('yyyy-MM-DD')}</span>
        </>
      )
    },
    {
      title: t('Promo End'),
      dataIndex: 'promoEnd',
      sorter: (a, b) => new Date(a.promoEnd) - new Date(b.promoEnd),
      render: (promoEnd) => (
        <>
          <span>{TimeConverter.UtctoGmtMinus4DateTime(promoEnd).format('yyyy-MM-DD')}</span>
        </>
      )
    },
    { title: t('Last Updated By'), dataIndex: 'updatedBy', key: 'updatedBy', sorter: (a, b) => a.updatedBy.localeCompare(b.updatedBy) },
    {
      title: t('Last Updated Date'),
      dataIndex: 'updatedDate',
      defaultSortOrder: 'descend',
        sorter: (a, b) => new Date(a.updatedDate) - new Date(b.updatedDate),
        render: (updatedDate) => (
        <>
                <span>{TimeConverter.UtctoGmtMinus4(updatedDate)}</span>
        </>
      )
    },
    {
      title: t('Remarks'),
      dataIndex: 'remarks',
      render: (text) => {
        return text ? (
          <Popover placement="topRight" arrowPointAtCenter content={text}>
            <InfoCircleTwoTone />
          </Popover>
        ) : (
          <></>
        )
      }
    }
  ]
  
  return (
    <Table
      columns={columns.filter(column => !column.isHide)}
      dataSource={dataSource}
      loading={isLoading}
      scroll={{ x: 'scroll' }}
      pagination={{
        pageSize: pageSize,
        showSizeChanger: false,
        onChange(current) {
          setPage(current)
        }
      }}
      rowKey="id"
      className={'promoListingTable'}
      size="small"
      locale={{ 
        emptyText: <Empty image={isLoading  ? '' : Empty.PRESENTED_IMAGE_SIMPLE} 
        description={isLoading ? '' : t('No record found')} />
      }}
    />
  )
}

PromoTable.propTypes = {
  onRowClick: PropTypes.func,
  pageSize: PropTypes.number,
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      company: PropTypes.string,
      name: PropTypes.string,
      nameEn: PropTypes.string,
      nameCn: PropTypes.string,
      nameTh: PropTypes.string,
      category: PropTypes.string,
      status: PropTypes.string,
      remarks: PropTypes.string,
      promoStart: PropTypes.string,
      promoEnd: PropTypes.string,
      updatedBy: PropTypes.string,
      updatedDate: PropTypes.string
    })
  )
}
