import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import {createRoot} from 'react-dom/client'

import './i18n'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
const rootElement = document.getElementById('root')
const rootComponent = (
  <Suspense
    fallback={
      <div className="center">
        <div className="loading-logo">
          <div className="loader"></div>
        </div>
      </div>
    }
  >
    <BrowserRouter basename={baseUrl}>
      <App />
    </BrowserRouter>
  </Suspense>
)

createRoot(rootElement).render(rootComponent)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
