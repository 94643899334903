import React, { useState, useEffect } from 'react'
import { Table, Switch, Spin, Popconfirm, Space } from 'antd'
/*import { LoadingOutlined } from '@ant-design/icons';*/
import moment from 'moment'
import { SettingService } from '../../services'

export const WindowServiceStatusSettingListPage = () => {

    const statuses = {
        Active: true,
        Inactive: false
    }

    function resetCounter(index) {
        let newData = [...data];
        newData[index].pending = false

        setData(newData)
    }

    //const antIcon = (
    //    <LoadingOutlined
    //        style={{
    //            fontSize: 18,
    //        }}
    //        spin
    //    />
    //);

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const confirm = async (item, record, rowIndex) => {
        var newStatus = !item
        var result = await SettingService.update(record.instanceName, Object.keys(statuses).find(key => statuses[key] === newStatus))

        if (result) {
            let newData = [...data];
            var index = newData.findIndex(x => x.instanceName === record.instanceName)

            newData[index].status = newStatus
            newData[index].pending = !newStatus // Set true if service stop
            newData[index].updatedTimestamp = new Date()
            setData(newData)
            if (!newStatus)
                setTimeout(() => resetCounter(index), 10000); // Countdown if service stop 10s
        }
    };

    const cancel = (e) => {
    };

    useEffect(() => {
        const getWindowServiceInstance = async () => {
            var services = await SettingService.getAll()
            var tableData = []
            if (services) {
                tableData = constructTableData(services)
            }
            setData(tableData ?? [])
            setLoading(false)
        }
        getWindowServiceInstance()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    function constructTableData(services) {
        var list = []
        services.forEach((service, index) => {
            list.push(
                {
                    instanceName: service.instanceName,
                    status: service.status === 0,
                    pending: false,
                    updatedTimestamp: service.updatedDateTime
                }
            );
        });
        return list
    }


    const columns = [
        {
            title: 'Instance Name',
            dataIndex: 'instanceName',
            width: "30%",
            key: 'instanceName',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.instanceName.localeCompare(b.instanceName),
            render: (instanceName) => (instanceName)
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            width: "40%",
            sorter: (a, b) => a.status - b.status,
            render: (item, record, index) => (
                <Space size="large" direction="horizontal" align="center">
                    <Popconfirm
                        title="Are you sure to change this status?"
                        onConfirm={() => confirm(item, record, index)}
                        onCancel={cancel}
                        okText="Ok"
                        cancelText="Cancel"
                    >
                        <Switch checked={record.status} loading={record.pending} />
                    </Popconfirm>
                    {
                        record.pending?
                            <span>{record.status ? "Starting service..." : "Stopping service..."}</span>
                        :<></>
                    }
                    {/*<div className="">*/}
                    {/*    <Spin delay={100} size='small' spinning={record.pending} indicator={antIcon} tip={record.status ? "Starting service..." : "Stopping service..."} style={{ fontSize: 14 }}> </Spin>*/}
                    {/*</div>*/}
                </Space>
            )
        },
        {
            title: 'Updated Date (GMT+8)',
            dataIndex: 'updatedTimestamp',
            key: 'updatedTimestamp',
            width: "30%",
            sorter: (a, b) => new Date(a.updatedTimestamp) - new Date(b.updatedTimestamp),
            render: (value) => {
                const date = moment(value)
                return date.isValid() ? date.format('YYYY-MM-DD hh:mm:ss A') : ''
            }
        }
    ]

    return (
        <>
            <Table rowKey="id" columns={columns} dataSource={data} size="small" loading={loading} bordered />
        </>
    )
}
