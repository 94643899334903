import moment from 'moment-timezone'

function UtctoGmtMinus4(datetime) {
  var gmtMinusFour = UtctoGmtMinus4DateTime(datetime)
  return gmtMinusFour.format('yyyy-MM-DD hh:mm:ss A')
}


function UtctoGmtMinus4DateTime(datetime) {
    const offset = -240
    var nowWithoutDST = moment(datetime).utcOffset(offset);
    return nowWithoutDST;

    // Note: Remove timezone id to prevent daylight saving
    //const utcDate = moment.utc(datetime)
    //return utcDate.tz('Canada/Atlantic')
}

export const TimeConverter = {
  UtctoGmtMinus4,
  UtctoGmtMinus4DateTime
}


