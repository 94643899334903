import React, { useState, useEffect } from 'react'
import { Row, Col, Select, Form, List, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import './sportForm.css'

export const SportForm = ({ sports, competitions, onSelectAll, updateSportInfo, isUpdateMode, isPromotionStarted, selectedSport, selectedCompetitions }) => {
  const { t } = useTranslation()
  const { Option } = Select
  const [enableCompetition, setEnableCompetition] = useState(true)
  const [selectedCompetitons, setSelectedCompetitions] = useState(selectedCompetitions)
  const [sport, setSport] = useState(selectedSport)

  const labelCol = { flex: '120px' }
  const wrapperCol = { flex: '0 1 500px' }

  function onselectCompetitionChange(competitionSelected) {
    if (competitionSelected) {
      setSelectedCompetitions(competitionSelected)
    }
  }

  function onSportChange(sportSelected) {
    if (sportSelected) {
      if (sportSelected !== sport) {
        setSelectedCompetitions([])
        onSelectAll('All', { competitions: [] })
      }

      setEnableCompetition(false)
      setSport(sportSelected)
      updateSportInfo(sportSelected)
    }
  }

  useEffect(() => {
    if (selectedSport) {
      setEnableCompetition(false)
      setSelectedCompetitions(selectedCompetitions)
    }
  }, [selectedSport]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Form.Item name="sport" label={t('Sport')} rules={[{ required: true, message: t('This is a mandatory field') }]} labelCol={labelCol} wrapperCol={wrapperCol}>
        <Select placeholder={t('Select Sport')} onChange={onSportChange} disabled={isUpdateMode}>
          {Object.entries(sports)
            .sort(([, a], [, b]) => a.localeCompare(b))
            .map(([key, value]) => (
              <Option value={key} key={key}>
                {t(value)}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item name="competitions" label={t('Competitions')} rules={[{ required: true, message: t('You must select at least one competition') }]} labelCol={labelCol} wrapperCol={wrapperCol}>
        <Select
          placeholder={t('Select Competition')}
          tagRender={(_) => {}}
          mode="multiple"
          allowClear
          onChange={onselectCompetitionChange}
          disabled={enableCompetition || isPromotionStarted}
          optionFilterProp="children"
          autoClearSearchValue={false}
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          <Option value="0" key="0" disabled={selectedCompetitons.length > 0 && !selectedCompetitons.includes('0')} style={{ visibility: Object.keys(competitions).length > 0 ? '' : 'hidden' }}>
            {t('All')}
          </Option>
          {Object.entries(competitions)
            .sort(([, a], [, b]) => a.localeCompare(b))
            .map(([key, value]) => (
              <Option value={key} key={key} disabled={selectedCompetitons.includes('0')}>
                {t(value)}
              </Option>
            ))}
          {(competitions && Object.keys(competitions).length > 0) || !sport ? (
            ''
          ) : (
            <Option disabled={true}>
              <Spin />
            </Option>
          )}
        </Select>
      </Form.Item>
      <Row>
        <Col span={24}>
          <List
            size="small"
            style={{ marginBottom: 10 }}
            header={
              <b>
                {t('Competitions')} ({selectedCompetitons.includes('0') ? Object.keys(competitions).length : selectedCompetitons.length})
              </b>
            }
            pagination={{ defaultPageSize: 10, size: 'small', hideOnSinglePage: selectedCompetitons.length <= 10 }}
            bordered
            dataSource={Object.keys(competitions).length > 0 && !selectedCompetitons.includes('0') ? selectedCompetitons.map((x) => competitions[x]) : selectedCompetitons.includes('0') ? ['All'] : ''}
            renderItem={(item) => <List.Item style={{ height: '30px' }}>{item}</List.Item>}
          />
        </Col>
      </Row>
    </>
  )
}
