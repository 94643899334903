import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { AppLayout } from './AppLayout'
import { AuthService } from '../../services'

export const PrivateRoute = ({ component: Component, title, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const currentUser = AuthService.currentUserValue
        if (!currentUser) {
          return <Redirect to={{ pathname: '/unauthorized', state: { from: props.location } }} />
        }

        return (
          <AppLayout title={title} userName={currentUser?.name || ''}>
            <Component {...props} user={currentUser} />
          </AppLayout>
        )
      }}
    />
  )
}
