import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col, Table, InputNumber, Select, Checkbox, Button, Popconfirm } from 'antd'
import { MinusCircleOutlined } from '@ant-design/icons'
import './RulesForm.css'

export const RulesForm = ({ form, isPromotionStarted, props }) => {
    const { i18n, t } = useTranslation()
    const { Option } = Select
    const labelCol = { flex: '200px' }
    const wrapperCompetitionCol = { flex: '0 1 650px' }

    let checkCompetitionFilter = props.checkCompetitionFilter
    let sportCompetitions = props.sportCompetitions
    let competitionData = props.competitionData
    let setCompetitionData = props.setCompetitionData
    let selectedSports = props.selectedSports
    let selectedSport = props.selectedSport
    let tagRender = props.tagRender
    let handleSportChange = props.handleSportChange
    let handleSportSelectionChange = props.handleSportSelectionChange
    let handleSportDeselectionChange = props.handleSportDeselectionChange
    let handleSportRuleSelectionChange = props.handleSportRuleSelectionChange
    let handleCompetitionSelectionChange = props.handleCompetitionSelectionChange
    let onCheckCompetitionFilterChange = props.onCheckCompetitionFilterChange
    let handleCurrencyTypesSelectionChange = props.handleCurrencyTypesSelectionChange
    let currencies = props.currencies
    let freeBetValidity = props.freeBetValidity
    let onOddsChange = props.onOddsChange
    let minimalOdds = props.minimalOdds
    let oddsTableData = props.oddsTableData
    let sports = props.sports
    let minBetAmountByCurrencyType = props.minBetAmountByCurrencyType

    const columns = [
        {
            title: t('Odds Type'),
            dataIndex: 'oddsKind',
            key: 'oddsKind',
            render: (oddsKind) => <>{t(oddsKind)}</>
        },
        {
            title: t('Minimal Odds'),
            dataIndex: 'odds',
            key: 'odds'
        }
    ]

    const competitionColumns = [
        { 
            title: t('Number'), 
            dataIndex: 'id', 
            render: (text, record, index) => index + 1 
        },
        {
            width: '15%',
            title: t('Sport'),
            dataIndex: 'sport',
            key: 'sport',
            render: (sport) => <>{t(sport)}</>
        },
        {
            title: t('Competition'),
            dataIndex: 'competition',
            key: 'competition'
        },
        {
            width: '5%',
            render: (_, record) =>
                competitionData.length >= 1 ? (
                    <Button
                        type="link"
                        icon={<MinusCircleOutlined style={{ color: 'red' }} />}
                        disabled={isPromotionStarted}
                        onClick={() => {
                            setCompetitionData(competitionData.filter(cmp =>
                                cmp.competitionId !== record.competitionId ||
                                cmp.sportId !== record.sportId))

                        }}
                    />
                ) : null,
        }
    ]

    useEffect(() => {
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Form form={form} name="dailyMissionRules">
            <Row gutter={[16, 8]}>
                <Col span={7}>
                    <Row gutter={[8, 8]} className={`form-${i18n.language}`}>
                        <Col span={24}>
                            <Form.Item name="sportRule" style={{ margin: '0' }} label={t('Sports')} labelCol={labelCol} rules={[{ required: true, message: t('This is a mandatory field') }]}>
                                <Select placeholder={t('Sports')} mode="multiple"
                                    style={{ width: '80%' }}
                                    disabled={isPromotionStarted}
                                    value={selectedSports}
                                    onSelect={handleSportSelectionChange}
                                    onDeselect={handleSportDeselectionChange}>
                                    {Object.entries(sports)
                                        .map(([k, v]) => (
                                        <Option value={JSON.stringify(v)} key={v.id}>
                                            {t(v.name)}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="competitionFiltering" style={{ margin: '5px 200px', width: '400px'}}
                                rules={[
                                    {
                                        validator: (_, value) => checkCompetitionFilter && competitionData.length === 0 ?
                                            Promise.reject(new Error(t('You must select at least one competition'))) :
                                            Promise.resolve()
                                    },
                                ]}
                            >
                                <Row justify="left">
                                    <Col span={24}>
                                        {(
                                            competitionData !== null && competitionData !== undefined && competitionData.length > 0 && checkCompetitionFilter
                                                ?
                                                <Popconfirm title={t('Uncheck competition filtering')}
                                                    onConfirm={onCheckCompetitionFilterChange}
                                                    okText={t('Yes')}
                                                    cancelText={t('No')}>
                                                    <Checkbox style={{ margin: '0 0px' }} checked={checkCompetitionFilter} disabled={isPromotionStarted || selectedSports === null || selectedSports === undefined || selectedSports.length <= 0}>{t('Competition Filtering')}</Checkbox>
                                                </Popconfirm>
                                                :
                                                <Checkbox style={{ margin : '0 0px' }} checked={checkCompetitionFilter} onChange={onCheckCompetitionFilterChange} disabled={isPromotionStarted || selectedSports === null || selectedSports === undefined || selectedSports.length <= 0}>{t('Competition Filtering')}</Checkbox>
                                        )}
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="currencyTypes" label={t('Currency')} labelCol={labelCol} rules={[{ required: true, message: t('This is a mandatory field') }]}>
                                <Select
                                    style={{ width: '80%' }}
                                    disabled={isPromotionStarted}
                                    onChange={handleCurrencyTypesSelectionChange}>
                                    {Object.entries(currencies)
                                        .sort(([, a], [, b]) => a.localeCompare(b))
                                        .map(([key, value]) => (
                                            <Option value={key} key={key}>
                                                {t(value)}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="minBetAmount" label={t('Minimal Bet Amount')} labelCol={labelCol} rules={
                                [
                                    { required: true, message: t('This is a mandatory field') },
                                    {
                                        validator: (_, value) => value < minBetAmountByCurrencyType ?
                                            Promise.reject(new Error(t('Min bet amount must be at least ') + `${minBetAmountByCurrencyType}`)) :
                                            Promise.resolve()
                                    }]
                            }>
                                <InputNumber placeholder={t('Min Bet Amount')} min={minBetAmountByCurrencyType} disabled={isPromotionStarted} style={{ width: '80%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="freeBetValidity" label={t('Free Bet Validity')} labelCol={labelCol} initialValue={freeBetValidity} rules={[{ required: true, message: t('This is a mandatory field') }]}>
                                <InputNumber placeholder={t('Free Bet Validity')} addonAfter={t('Days')} value={7} disabled={true} style={{ width: '80%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="minimalOdds" label={t('Minimal Odds (HK)')} labelCol={labelCol}
                                rules={
                                    [
                                        {
                                            required: true,
                                            validator: async (_, minOdds) => {
                                                if (!minOdds) {
                                                    return Promise.reject(new Error(t('This is a mandatory field')))
                                                }
                                                if (minOdds < minimalOdds) {
                                                    return Promise.reject(new Error(t('The Minimal Odds (HK) is') + ` ` + `${minimalOdds}`))
                                                }
                                            }
                                        }
                                    ]}
                            >
                                <InputNumber placeholder={t('Minimal Odds')} onChange={onOddsChange} min={0} disabled={isPromotionStarted} style={{ width: '80%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={21}>
                            <Table columns={columns} dataSource={oddsTableData} size="small" pagination={false} />
                        </Col>
                    </Row>
                </Col>
                {checkCompetitionFilter ?
                    <>
                        <Col span={8}>
                            <Row gutter={[8, 8]}>
                                <Col span={4}>
                                    <label>{t('Competitions')}:</label>
                                </Col>
                                <Col span={20}>
                                    <Form.Item name="sportSelection" wrapperCol={wrapperCompetitionCol} /*rules={[{ required: true, message: t('This is a mandatory field') }]}*/>
                                        <Select placeholder={t('Sports')}
                                            disabled={isPromotionStarted}
                                            onChange={handleSportRuleSelectionChange}>
                                            {Object.entries(selectedSports).map(([k, v]) => (
                                                <Option value={JSON.stringify(v)} key={v.id}>
                                                    {t(v.name)}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="competitionSelection" wrapperCol={wrapperCompetitionCol} /*rules={[{ required: true, message: t('This is a mandatory field') }]}*/>
                                        <Select placeholder={t('Competitions')}
                                            showSearch optionFilterProp="value"
                                            disabled={selectedSport === null || selectedSport === undefined || selectedSport === ''}
                                            onChange={handleCompetitionSelectionChange}>
                                            {Object.entries(sportCompetitions).map(([key, val]) => (
                                                <Option value={JSON.stringify(([key, val]))} key={key}>
                                                    {t(val)}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={9}>
                            <Row gutter={[8, 8]}>
                                <Col flex="0 1 850px">
                                    <Table columns={competitionColumns} dataSource={competitionData} size="small" pagination={false} rowClassName="competition-table"/>
                                </Col>
                            </Row>
                        </Col>
                    </>
                   : <></>}
            </Row>
        </Form>
    )
}

