/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react'
import { PageContent, TimeConverter } from '../../components/core'
import { Form, Button, message, Steps, Col, Row, notification, Tag, Popconfirm, Spin } from 'antd'
import { PromoInfoForm, RulesForm, DailyMissionsForm, DailyMissionSummaryForm } from '.'
import './DailyMissionDetailsPage.css'
import moment from 'moment'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AuthService, PageOptionsService, DailyMissionService, DailyMissionSettingService, PermissionsService } from '../../services'
import { Constants } from '../../components/core'

export const DailyMissionDetailsPage = (props) => {

    let history = useHistory()
    let query = new URLSearchParams(useLocation().search)
    
    const { t, i18n } = useTranslation()
    const [form] = Form.useForm()
    const [freeBetValidity, setFreeBetValidity] = useState(null)
    const [companies, setCompanies] = useState({})
    const [currencyCode, setCurrencyCode] = useState({})
    const [currencies, setCurrencies] = useState({})
    const [oddsTableData, setOddsTableData] = useState(null)
    const [sports, setSports] = useState({})
    const [wagerTypes, setWagerTypes] = useState({})
    const [wagerType, setWagerType] = useState(null)
    const [betTypes, setBetTypes] = useState({})
    const [betTypesBySports, setBetTypesBySports] = useState({})
    const [footerInfo, setFooterInfo] = useState({})

    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState();

    const [promotionDailyMission, setPromotionDailyMission] = useState({})
    const [isPromotionStarted, setPromotionStarted] = useState(false)
    const [isUpdateMode, setIsUpdateMode] = useState(false)
    const [existingMissionCount, setExistingMissionCount] = useState(0)

    const [enComponentDisabled, setEnComponentDisabled] = useState(false);
    const [cnComponentDisabled, setCnComponentDisabled] = useState(false);
    const [thComponentDisabled, setThComponentDisabled] = useState(false);
    const [vnComponentDisabled, setVnComponentDisabled] = useState(false);

    // Rules
    const [checkCompetitionFilter, setCheckCompetitionFilter] = useState(false)
    const [sportCompetitions, setSportCompetitions] = useState({})
    const [competitionData, setCompetitionData] = useState([])
    const [selectedSports, setSelectedSports] = useState([])
    const [selectedSport, setSelectedSport] = useState('')
    const [minBetAmountByCurrencyType, setMinBetAmountByCurrencyType] = useState(100)

    const dateFormat = 'YYYY-MM-DD'
    var pickerStart = new Date()
    var startDate  = pickerStart.getDate()
    pickerStart.setDate(startDate + 1)
    pickerStart.setUTCHours(4, 0, 0, 0)

    var pickerEnd = new Date();
    var endate = pickerEnd.getDate()
    pickerEnd.setDate(endate + 30)
    pickerEnd.setUTCHours(4, 0, 0, 0)

    const tzAndPlaces = {
        "America/Detroit": "Eastern Time (US and Canada)",
        UTC: "Dublin, Edinburgh, Lisbon, London",
        "Asia/Kuala_Lumpur": "Kuala Lumpur, Singapore",
    };

    const buildTZ = (id) => {
        var momentTz = moment.tz(id);
        var offset = momentTz.utcOffset();
        var zone = momentTz.format("Z");
        var gmt = `GMT${offset ? zone : ""}`;
        gmt = gmt === 'GMT' ? 'UTC' : gmt;
        return { id, gmt, offset, zone };
    };

    const timezones = moment.tz
        .names()
        .filter((tz) => tzAndPlaces[tz])
        .reduce((acum, id) => [...acum, buildTZ(id)], [])
        .sort((a, b) => a.offset - b.offset)
        .concat();

    const buildTZWithMinutes = (minutes) => {
        var offset = minutes;
        var zone = moment().utcOffset(offset).format("Z");
        var gmt = `GMT${offset ? zone : ""}`;
        gmt = gmt === 'GMT' ? 'UTC' : gmt;
        return { gmt, offset, zone };
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 }
        },
        labelAlign: 'left'
    }

    const fontStyle = { fontSize: '11px' }

    const pageInitialValues = {
        status: 'Enabled',
        promoDateRange: [moment(pickerStart, dateFormat), moment(pickerEnd, dateFormat)],
        company: AuthService.currentUserValue.companyId ? [AuthService.currentUserValue.companyId] : null,
        promoTimeZone: {
            label: 'GMT-04:00', value: '-04:00'
        },
        minBetAmount: 100,
        minimalOdds: 0.5,
        currencyTypes: 'rmb'
    }

    const openNotificationWithIcon = (type, title, description, duration, destroy = true) => {
        if (destroy)
            notification.destroy()
        notification[type]({
            message: title,
            description: description,
            duration: duration
        })
    }

    function constructFormData(dailyMissionViewModel, isUpdate) {
        var json = JSON.stringify(dailyMissionViewModel)
        const formData = new FormData()
        if (isUpdate)
            formData.append('_method', 'put');

        if (promotionDailyMission[0].promoBannerEn !== undefined && promotionDailyMission[0].promoBannerEn[0]?.originFileObj !== undefined)
            formData.append('images', promotionDailyMission[0].promoBannerEn[0]?.originFileObj, dailyMissionViewModel.promotionBannerUrlEn)

        if (promotionDailyMission[0].promoBannerCn !== undefined && promotionDailyMission[0].promoBannerCn[0]?.originFileObj !== undefined)
            formData.append('images', promotionDailyMission[0].promoBannerCn[0]?.originFileObj, dailyMissionViewModel.promotionBannerUrlCn)

        if (promotionDailyMission[0].promoBannerTh !== undefined && promotionDailyMission[0].promoBannerTh[0]?.originFileObj !== undefined)
            formData.append('images', promotionDailyMission[0].promoBannerTh[0]?.originFileObj, dailyMissionViewModel.promotionBannerUrlTh)

        if (promotionDailyMission[0].promoBannerVn !== undefined && promotionDailyMission[0].promoBannerVn[0]?.originFileObj !== undefined)
            formData.append('images', promotionDailyMission[0].promoBannerVn[0]?.originFileObj, dailyMissionViewModel.promotionBannerUrlVn)

        if (promotionDailyMission[0].missionBannerEn !== undefined && promotionDailyMission[0].missionBannerEn[0]?.originFileObj !== undefined)
            formData.append('images', promotionDailyMission[0].missionBannerEn[0]?.originFileObj, dailyMissionViewModel.missionBannerUrlEn)

        if (promotionDailyMission[0].missionBannerCn !== undefined && promotionDailyMission[0].missionBannerCn[0]?.originFileObj !== undefined)
            formData.append('images', promotionDailyMission[0].missionBannerCn[0]?.originFileObj, dailyMissionViewModel.missionBannerUrlCn)

        if (promotionDailyMission[0].missionBannerTh !== undefined && promotionDailyMission[0].missionBannerTh[0]?.originFileObj !== undefined)
            formData.append('images', promotionDailyMission[0].missionBannerTh[0]?.originFileObj, dailyMissionViewModel.missionBannerUrlTh)

        if (promotionDailyMission[0].missionBannerVn !== undefined && promotionDailyMission[0].missionBannerVn[0]?.originFileObj !== undefined)
            formData.append('images', promotionDailyMission[0].missionBannerVn[0]?.originFileObj, dailyMissionViewModel.missionBannerUrlVn)

        formData.append('promotionDailyMissionViewModelJson', json)
        return formData
    }

    async function createDailyMission() {
        if (promotionDailyMission) {
            var dailyMissionViewModel = constructPromotionDailyMissionViewModel(null)
            var formData = constructFormData(dailyMissionViewModel, false)
            var response = await DailyMissionService.createDailyMission(formData)
            let isError = false

            if (response?.message === '') {
                message.success(t('Daily missions saved'))
            } else {
                if (response?.message) {
                    openNotificationWithIcon('error', t(response?.message), '', 5, false)
                }
                isError =  true
            }

            return isError
        }
    }

    async function updateDailyMission(id) {
        if (promotionDailyMission) {
            var dailyMissionViewModel = constructPromotionDailyMissionViewModel(id)
            var formData = constructFormData(dailyMissionViewModel, true)
            var response = await DailyMissionService.updateDailyMission(id, formData)
            let isError = false

            if (response?.message === '') {
                message.success(t('Daily missions saved'))
            } else {
                if (response?.message) {
                    openNotificationWithIcon('error', t(response?.message), '', 5, false)
                }
                isError = true
            }

            return isError
        }
}

    function constructPromotionDailyMissionViewModel(id) {
        const startDate = new Date(promotionDailyMission[0].promoDateRange[0])
        startDate.setUTCHours(4, 0, 0, 0)

        const endDate = new Date(promotionDailyMission[0].promoDateRange[1])
        endDate.setUTCHours(4, 0, 0, 0)

        const timeZone = promotionDailyMission[0].promoTimeZone?.value === undefined ? promotionDailyMission[0].promoTimeZone : promotionDailyMission[0].promoTimeZone?.value

        const unixTimestamp = moment().unix()

        const dailyMissionViewModels = constructDailyMissionViewModels()

        const companyId = Array.isArray(promotionDailyMission[0].company) ? promotionDailyMission[0].company[0] : promotionDailyMission[0].company
        const company = Object.keys(companies).find(key => companies[key] === companyId);

        const grpCompetitionDict = Object.groupBy(competitionData, ({ sportId }) => sportId);
        var sportCompDict = {}
        for (const [key, value] of Object.entries(grpCompetitionDict)) {
            var competitionList = Object.entries(value).map(([x, y]) => { return y.competitionId })
            sportCompDict[parseInt(key)] = competitionList
        }

        return {
            languages: JSON.stringify(promotionDailyMission[0].language),
            promotionDailyMissionId: id !== null ? parseInt(id) : 0,
            promotionNameEn: promotionDailyMission[0].nameEn ?? '',
            promotionNameCn: promotionDailyMission[0].nameCn ?? '',
            promotionNameTh: promotionDailyMission[0].nameTh ?? '',
            promotionNameVn: promotionDailyMission[0].nameVn ?? '',
            promotionTaglineEn: promotionDailyMission[0].taglineEn ?? '',
            promotionTaglineCn: promotionDailyMission[0].taglineCn ?? '',
            promotionTaglineTh: promotionDailyMission[0].taglineTh ?? '',
            promotionTaglineVn: promotionDailyMission[0].taglineVn ?? '',
            promotionDescriptionEn: promotionDailyMission[0].descriptionEn ?? '',
            promotionDescriptionCn: promotionDailyMission[0].descriptionCn ?? '',
            promotionDescriptionTh: promotionDailyMission[0].descriptionTh ?? '',
            promotionDescriptionVn: promotionDailyMission[0].descriptionVn ?? '',
            promotionBannerUrlEn: promotionDailyMission[0].promoBannerEn === undefined ? '' : promotionDailyMission[0].promoBannerEn[0]?.originFileObj !== undefined ? `promo_banner_eng_${unixTimestamp}.${promotionDailyMission[0].promoBannerEn[0].name.split('.').pop()}` : '',
            promotionBannerUrlCn: promotionDailyMission[0].promoBannerCn === undefined ? '' : promotionDailyMission[0].promoBannerCn[0]?.originFileObj !== undefined ? `promo_banner_chs_${unixTimestamp}.${promotionDailyMission[0].promoBannerCn[0].name.split('.').pop()}` : '',
            promotionBannerUrlTh: promotionDailyMission[0].promoBannerTh === undefined ? '' : promotionDailyMission[0].promoBannerTh[0]?.originFileObj !== undefined ? `promo_banner_tha_${unixTimestamp}.${promotionDailyMission[0].promoBannerTh[0].name.split('.').pop()}` : '',
            promotionBannerUrlVn: promotionDailyMission[0].promoBannerVn === undefined ? '' : promotionDailyMission[0].promoBannerVn[0]?.originFileObj !== undefined ? `promo_banner_vie_${unixTimestamp}.${promotionDailyMission[0].promoBannerVn[0].name.split('.').pop()}` : '',
            missionBannerUrlEn: promotionDailyMission[0].missionBannerEn === undefined ? '' : promotionDailyMission[0].missionBannerEn[0]?.originFileObj !== undefined ? `mission_banner_eng_${unixTimestamp}.${promotionDailyMission[0].missionBannerEn[0].name.split('.').pop()}` : '',
            missionBannerUrlCn: promotionDailyMission[0].missionBannerCn === undefined ? '' : promotionDailyMission[0].missionBannerCn[0]?.originFileObj !== undefined ? `mission_banner_chs_${unixTimestamp}.${promotionDailyMission[0].missionBannerCn[0].name.split('.').pop()}` : '',
            missionBannerUrlTh: promotionDailyMission[0].missionBannerTh === undefined ? '' : promotionDailyMission[0].missionBannerTh[0]?.originFileObj !== undefined ? `mission_banner_tha_${unixTimestamp}.${promotionDailyMission[0].missionBannerTh[0].name.split('.').pop()}` : '',
            missionBannerUrlVn: promotionDailyMission[0].missionBannerVn === undefined ? '' : promotionDailyMission[0].missionBannerVn[0]?.originFileObj !== undefined ? `mission_banner_vie_${unixTimestamp}.${promotionDailyMission[0].missionBannerVn[0].name.split('.').pop()}` : '',
            companyId: parseInt(companyId),
            companyName: company,
            status: promotionDailyMission[0].status,
            promotionStart: startDate,
            promotionEnd: endDate,
            timeZone: timeZone.replace('+', ''),
            termsNConditionsEn: promotionDailyMission[0].tncEn ?? '',
            termsNConditionsCn: promotionDailyMission[0].tncCn ?? '',
            remarks: promotionDailyMission[0].remarks ?? '',

            minBetAmount: parseFloat(promotionDailyMission[1].minBetAmount),
            freeBetValidity: promotionDailyMission[1].freeBetValidity,
            minimalHkOdds: parseFloat(promotionDailyMission[1].minimalOdds),
            currencies: JSON.stringify({ [promotionDailyMission[1].currencyTypes]: promotionDailyMission[1].currencyTypes.toUpperCase() }),
            dailyMissionViewModels: dailyMissionViewModels,
            competitions: JSON.stringify(sportCompDict),
            supportedSports: JSON.stringify(Object.fromEntries(selectedSports.map(item => [parseInt(item.id), item.name])))
        }
    }

    function constructDailyMissionViewModels() {
        var dailyMissionViewModels = []
        promotionDailyMission[2].dailyMission.forEach((mission, index) => {
            dailyMissionViewModels.push(
                {
                    id: mission.id ?? null,
                    numOfTickets: mission.noWager,
                    wagerTypeId: parseInt(Object.keys(wagerTypes)[0]),
                    wagerType: Object.values(wagerTypes)[0],
                    sportId: parseInt((JSON.parse(mission.sport?.value === undefined ? mission.sport : mission.sport?.value))[0]),
                    sportName: (JSON.parse(mission.sport?.value === undefined ? mission.sport : mission.sport?.value))[1],
                    betTypeId: parseInt((JSON.parse(mission.betType?.value === undefined ? mission.betType : mission.betType?.value))[0]),
                    betType: (JSON.parse(mission.betType?.value === undefined ? mission.betType : mission.betType?.value))[1],
                    rewardAmount: parseFloat(mission.freebetReward),
                    dailyBudget: parseFloat(mission.freebetBudget),
                }
            );
        });

        return dailyMissionViewModels
    }

    function getFileName(filePath) {
        return filePath.replace(/^.*[\\\/]/, '')
    }

    function constructSelectedSports(supportedSports) {
        const parsedSports = JSON.parse(supportedSports)
        const newArray = []
        for (const [key, value] of Object.entries(parsedSports)) {
            newArray.push({ id: key, name: value })
        }
        return newArray
    }

    async function constructCompetitionData(competitions, supportedSports) {
        const parsedSports = JSON.parse(supportedSports)
        const parsedCompetitions = JSON.parse(competitions)
        const newArray = []
        for (const [sportId, competitionArry] of Object.entries(parsedCompetitions)) {
            let sportCompetitions = await PageOptionsService.getPageOptionsCompetitionsBySport(sportId, i18n.language)
            competitionArry.forEach((competitionId) => {
                Object.entries(sportCompetitions).forEach(([cId, cName]) => {
                    if (parseInt(cId) === competitionId) {
                        var sportName = parsedSports[parseInt(sportId)]
                        newArray.push({ competitionId: parseInt(cId), competition: cName, sport: sportName, sportId: parseInt(sportId) })
                    }
                });
            });
        }
        return newArray
    }

    async function setFieldsValue(data) {
        const cdnUrl = await DailyMissionSettingService.getCdnUrl()
        const timeZone = buildTZWithMinutes(data.timeZoneInMinutes)
        const enableEn = data.languages.includes('en')
        const enableCn = data.languages.includes('ch')
        const enableTh = data.languages.includes('th')
        const enableVn = data.languages.includes('vn')
        const supportedSports = constructSelectedSports(data.supportedSports)
        const competitions = await constructCompetitionData(data.competitions, data.supportedSports)
        const currency = JSON.parse(data.currencies)
        //const currencyType = Object.keys(currency)[0]

        setEnComponentDisabled(enableEn)
        setCnComponentDisabled(enableCn)
        setThComponentDisabled(enableTh)
        setVnComponentDisabled(enableVn)
        setSelectedSports(supportedSports)
        setCheckCompetitionFilter(competitions.length > 0)
        setCompetitionData(competitions)

        var dailyMissions = []
        data.dailyMissionViewModels.forEach(async (mission, index) => {
            var sport = { value: `["${mission.sportId}","${mission.sportName}"]`, label: t(mission.sportName) }
            var betType = { value: `["${mission.betTypeId}","${mission.betType}"]`, label: t(mission.betType) }
            dailyMissions.push(
                {
                    id: mission.id,
                    noWager: mission.numOfTickets,
                    wagerType: t(mission.wagerType),
                    sport: sport,
                    betType: betType,
                    freebetReward: mission.rewardAmount,
                    freebetBudget: mission.dailyBudget
                }
            );
        })

        form.setFieldsValue({
            language: JSON.parse(data.languages),
            nameEn: data.promotionNameEn,
            nameCn: data.promotionNameCn,
            nameTh: data.promotionNameTh,
            nameVn: data.promotionNameVn,
            taglineEn: data.promotionTaglineEn,
            taglineCn: data.promotionTaglineCn,
            taglineTh: data.promotionTaglineTh,
            taglineVn: data.promotionTaglineVn,
            descriptionEn: data.promotionDescriptionEn,
            descriptionCn: data.promotionDescriptionCn,
            descriptionTh: data.promotionDescriptionTh,
            descriptionVn: data.promotionDescriptionVn,
            missionBannerEn: data.missionBannerUrlEn === '' ? undefined : [ 
                {
                    uid: '-1',
                    name: getFileName(data.missionBannerUrlEn),
                    status: 'done',
                    url: cdnUrl + data.missionBannerUrlEn,
                    thumbUrl: cdnUrl + data.missionBannerUrlEn
                },
            ],
            missionBannerCn: data.missionBannerUrlCn === '' ? undefined : [
                {
                    uid: '-2',
                    name: getFileName(data.missionBannerUrlCn),
                    status: 'done',
                    url: cdnUrl + data.missionBannerUrlCn,
                    thumbUrl: cdnUrl + data.missionBannerUrlCn
                },
            ],
            missionBannerTh: data.missionBannerUrlTh === '' ? undefined : [
                {
                    uid: '-3',
                    name: getFileName(data.missionBannerUrlTh),
                    status: 'done',
                    url: cdnUrl + data.missionBannerUrlTh,
                    thumbUrl: cdnUrl + data.missionBannerUrlTh
                },
            ],
            missionBannerVn: data.missionBannerUrlVn === '' ? undefined : [
                {
                    uid: '-4',
                    name: getFileName(data.missionBannerUrlVn),
                    status: 'done',
                    url: cdnUrl + data.missionBannerUrlVn,
                    thumbUrl: cdnUrl + data.missionBannerUrlVn
                },
            ],
            promoBannerEn: data.promotionBannerUrlEn === '' ? undefined : [
                {
                    uid: '-5',
                    name: getFileName(data.promotionBannerUrlEn),
                    status: 'done',
                    url: cdnUrl + data.promotionBannerUrlEn,
                    thumbUrl: cdnUrl + data.promotionBannerUrlEn
                },
            ],
            promoBannerCn: data.promotionBannerUrlCn === '' ? undefined : [
                {
                    uid: '-6',
                    name: getFileName(data.promotionBannerUrlCn),
                    status: 'done',
                    url: cdnUrl + data.promotionBannerUrlCn,
                    thumbUrl: cdnUrl + data.promotionBannerUrlCn,
                },
            ],
            promoBannerTh: data.promotionBannerUrlTh === '' ? undefined : [
                {
                    uid: '-7',
                    name: getFileName(data.promotionBannerUrlTh),
                    status: 'done',
                    url: cdnUrl + data.promotionBannerUrlTh,
                    thumbUrl: cdnUrl + data.promotionBannerUrlTh
                },
            ],
            promoBannerVn: data.promotionBannerUrlVn === '' ? undefined : [
                {
                    uid: '-8',
                    name: getFileName(data.promotionBannerUrlVn),
                    status: 'done',
                    url: cdnUrl + data.promotionBannerUrlVn,
                    thumbUrl: cdnUrl + data.promotionBannerUrlVn,
                },
            ],
            company: [`${data.companyId}`],
            status: data.status,
            promoDateRange: [moment.utc(data.promotionStart, dateFormat), moment.utc(data.promotionEnd, dateFormat)],
            promoTimeZone: { label: timeZone.gmt, value: timeZone.zone },
            tncEn: data.termsNConditionsEn,
            tncCn: data.termsNConditionsCn,
            remarks: data.remarks,

            minBetAmount: data.minBetAmount,
            freeBetValidity: data.freeBetValidity,
            minimalOdds: data.minimalHkOdds,
            currencyTypes: Object.keys(currency)[0],
            dailyMission: dailyMissions,
            sportRule: supportedSports.map((item) => JSON.stringify(item))
            //sportSelection: supportedSports // TODO
        })
        onOddsChange()
        setMinBetAmountByCurrencyType(getMinBetAmountFromCurrencyTypesSelection())
    }

    async function onFinish() {
        var isError = false
        var failMessage = ''
        setLoading(true)

        if (isUpdateMode) {
            var id = query.get('id')
            isError = await updateDailyMission(id)
            failMessage = t('Update fail')
        } else {
            isError = await createDailyMission(null)
            failMessage = t('Create fail')
        }
        setLoading(false)
        if (!isError) {
            //openNotificationWithIcon('success', t('Successful'), '', 5)
            history.push('/dailyMissions')
        } else openNotificationWithIcon('error', failMessage, '', 5, false)
    }

    function onFinishFailed() {
    }

    function onValuesChange() {
    }

    function onSelectAll(selectedValue, field) {
        if (selectedValue === 'All') {
            form.setFieldsValue(field)
        }
    }

    async function getPromotionDailyMission () {
        var promoDailyMissionId = query.get('id')
        if (promoDailyMissionId) {
            //setIsUpdateMode(prevState => ({ ...prevState, ...shouldUpdate })); 
            setIsUpdateMode(true); 
            var dailyMission = await DailyMissionService.getDailyMission(promoDailyMissionId)
            await setFieldsValue(dailyMission)
            setExistingMissionCount(dailyMission.dailyMissionViewModels.length)
            
            return dailyMission
        }
        return null
    }

    async function getPageOptionsByCompany(data) {
        var companyId = props.user.companyId ? props.user.companyId : data?.companyId

        var freeBetValidity = await DailyMissionSettingService.getFreeBetValidityInDays()
        var currencies = await DailyMissionSettingService.getCurrencies();
        var wagerTypes = await DailyMissionSettingService.getWagerTypes();
        var sports = await DailyMissionSettingService.getSports();
        var sportBetTypes = await DailyMissionSettingService.getSportBetTypes();

        setFreeBetValidity(freeBetValidity)
        //setCurrencyCode(`${Object.values(currencies)[0]}`);
        setCurrencies(currencies)
        setSports(sports)
        setBetTypesBySportsAsync(sportBetTypes)
        setWagerType(`${Object.values(wagerTypes)[0]}`)
        setWagerTypes(wagerTypes)

        const pageOptions = await PageOptionsService.getPageOptionsCompanies(i18n.language)
        if (pageOptions !== undefined) {
            setCompanies(pageOptions.companies)
        }
    }

    const handleSportSelectionChange = (value) => {
        let sportDto = JSON.parse(value)
        setSelectedSports([...selectedSports, sportDto])
    }

    const handleSportChange = (value) => {
        if (value.length === 0) {
            setCheckCompetitionFilter(false)
        }
        else if(selectedSports.length > 0 && competitionData.length > 0 && value.length < selectedSports.length){
            message.warning(`Please remove all selected X competition before deselecting sport`, 5)
            let existingSelectedSports = selectedSports
            setSelectedSports(existingSelectedSports)
        }
        else if(value.length > 0){
            const selectedSportsJson = value.map((item) => JSON.parse(item))
            setSelectedSports(selectedSportsJson)
        }
    }
       
    const handleSportDeselectionChange = (value) => {
        
        var parsedVal = JSON.parse(value)
        const isSportSelected = competitionData !== null && competitionData !== undefined && competitionData.length > 0 && competitionData.some((competition) => {
            return competition.sportId.toString() === parsedVal.id.toString()
        })

        let existDm = false
        var sportId = parseInt(parsedVal.id)
        var missionObj = form.getFieldValue('dailyMission')
        if (missionObj !== undefined) {
            existDm = missionObj.findIndex(mission => parseInt((JSON.parse(mission.sport?.value === undefined ? mission.sport : mission.sport?.value))[0]) === sportId) > -1
        }

        if (isSportSelected){
            message.warning(t('Please remove the selected competitions before deselecting the sport'), 5)

            const selectedSportsJson = selectedSports.map((item) => JSON.stringify(item))
            form.setFieldValue('sportRule', selectedSportsJson)
            form.validateFields()
        }
        else if (existDm) {
            message.warning(t('Please remove the related daily missions before deselecting the sport'), 5)

            const selectedSportsJson = selectedSports.map((item) => JSON.stringify(item))
            form.setFieldValue('sportRule', selectedSportsJson)
            form.validateFields()
        }
        else{
            let removeSportDto = JSON.parse(value)
            let newSelectedSports = selectedSports.filter(s => s.id !== removeSportDto.id)
            setSelectedSports(newSelectedSports)

            form.setFieldValue('sportSelection', undefined)
            form.setFieldValue('competitionSelection', undefined)
            setSportCompetitions([])
            setSelectedSport('')

            if (newSelectedSports.length === 0) {
                setCheckCompetitionFilter(false)
            }
        }
    }

    const tagRender = (props) => {
        const { label, value, closable, onClose } = props;
        const showAlertMsg = (removeSportDto, e) => {
            message.warning(`Please remove all selected ${removeSportDto.name} competition before deselecting`, 5);
            e.preventDefault()
        }

        const onCloseCust = (event) => {
            let removeSportDto = JSON.parse(value)
           
            if (competitionData?.length > 0) {
                const i = competitionData?.findIndex(d => d.sportId === parseInt(removeSportDto.id));
                if (i > -1) {
                    // Show message
                    return showAlertMsg(removeSportDto, event)
                } else {
                    return onClose()
                }
            } else {
                return onClose()
            }
        }

        return (
            <Tag closable={closable} onClose={onCloseCust}
                style={{
                    marginRight: 3,
                }}
            >
                {label}
            </Tag>
        );
    };

    async function handleSportRuleSelectionChange(value) {
        let sportDto = JSON.parse(value)
        const sportId = parseInt(sportDto.id)

        let sportCompetitions = await PageOptionsService.getPageOptionsCompetitionsBySport(sportId, i18n.language)
        setSportCompetitions(sportCompetitions ?? [])
        setSelectedSport(value)
        form.setFieldValue('competitionSelection', '')
    }

    const handleCompetitionSelectionChange = (value) => {
        if (competitionData.length < 30) {
            let sportDto = JSON.parse(selectedSport)
            const sportId = parseInt(sportDto.id)
            const sportName = sportDto.name
            let competitionDto = JSON.parse(value)
            const competitionId = parseInt(competitionDto[0])
            const competitionName = competitionDto[1]

            let sportCompetiition = { sport: sportName, competition: competitionName, sportId: sportId, competitionId: competitionId }
            let dict = [...competitionData]
            let idx = dict?.findIndex((a) => parseInt(a.competitionId) === competitionId && parseInt(a.sportId) === sportId)

            if (idx < 0) {
                setCompetitionData([...competitionData, sportCompetiition])
            }
        } else {
            message.error(t(`Please select a maximum of 30 competitions only`), 5);
        }
    }

    const onCheckCompetitionFilterChange = (e) => {
        var isCheckboxCheck = e.target.checked
        setCheckCompetitionFilter(isCheckboxCheck)
        if(isCheckboxCheck){
            setCompetitionData([])
            form.setFieldValue('sportSelection', undefined)
            form.setFieldValue('competitionSelection', undefined)
            setSportCompetitions([])
            setSelectedSport('')
        }
    }

    // PromoInfo
    // Banner
    const beforeUpload = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                //const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                //const isLt2M = file.size / 1024 / 1024 < 2;

                //if (!isJpgOrPng) {
                //    message.error(t('Upload only JPG/PNG'));
                //    return true
                //}

                //if (!isLt2M) {
                //    message.error(t('Image must smaller than 2MB!'));
                //    return true
                //}

                const img = document.createElement('img')
                img.src = reader.result;
                img.onload = () => {
                    const { width, height } = img
                    if (width !== 1200 || height !== 450) {
                        message.error(t('Image size must be 1200px X 450px'))
                        return true
                    }

                    resolve(false)
                    return false
                };
            };
        });
    }
    const getBase64 = (img, callback) => {
        let reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const onUploadChange = (info) => {
        if (info.file.status === 'uploading') {
            //setLoading(true);
            return;
        }
        else if (info.file.status !== 'removed') {
            getBase64(info.file, (url) => {
                //setLoading(false);
                setImage(info);
            });
            message.success(`${info.file.name} ` + t('uploaded successfully'));
        }
        //else if (info.file.status === 'error') {
        //    setLoading(false);

        //    message.error(`${info.file.name} file upload failed.`);
        //}

    };

    const onPreview = (file) => {
        var image = new Image();
        image.src = file.thumbUrl;
        var w = window.open("");
        w.document.write(image.outerHTML);
    }

    const uploadProps = {
        maxCount: 1,
        listType: 'picture',
        accept: 'image/png, image/jpeg',
        action: '//jsonplaceholder.typicode.com/posts/',
        //headers: {
        //    authorization: 'authorization-text',
        //},
        showUploadList: {
            showRemoveIcon: true,
            showPreviewIcon: true
        },
        onPreview: onPreview,
        onChange: onUploadChange,
        beforeUpload: beforeUpload,
        disabled: isPromotionStarted
    }

    // DailyMissionRules
    var minHkOdds = 0.50
    var defaultData = [
        {
            key: '1',
            oddsKind: t('EU'),
            odds: calculateOdds('1', minHkOdds)
        },
        {
            key: '2',
            oddsKind: t('MY'),
            odds: calculateOdds('2', minHkOdds)
        },
        {
            key: '3',
            oddsKind: t('INDO'),
            odds: calculateOdds('3', minHkOdds)
        },
        {
            key: '4',
            oddsKind: t('AMERICAN'),
            odds: calculateOdds('4', minHkOdds)
        }
    ]

    function onOddsChange() {
        var odds = parseFloat(form.getFieldValue('minimalOdds'))
        setOddsTableData((prevState) => {
            const orderItems = prevState.map((item) => {
                return { ...item, odds: calculateOdds(item.key, odds) }
            })

            return orderItems
        })
    }

    function calculateOdds(oddsKey, odds) {
        if (odds) {
            odds = parseFloat(odds)
            var convertedOdds = odds
            if (oddsKey === '1') convertedOdds = odds + 1
            else if (oddsKey === '2') {
                if (odds > 1) convertedOdds = (1 / odds) * -1
            } else if (oddsKey === '3') {
                if (odds < 1) convertedOdds = (1 / odds) * -1
            } else if (oddsKey === '4') {
                if (odds < 1) convertedOdds = (1 / odds) * -100
                else convertedOdds = odds * 100
                return parseInt(convertedOdds)
            }

            return convertedOdds.toFixed(2)
        }
    }

    function getMinBetAmountFromCurrencyTypesSelection() {
        var currencyType = form.getFieldValue('currencyTypes')
        var minBetAmount = 100
        if (currencyType === 'thb') {
            minBetAmount = 500
        }
        else if (currencyType === 'vnd') {
            minBetAmount = 350000
        }

        return minBetAmount
    }

    const handleCurrencyTypesSelectionChange = () => {
        var minBetAmount = getMinBetAmountFromCurrencyTypesSelection()
        form.setFieldValue('minBetAmount', minBetAmount)
        setMinBetAmountByCurrencyType(minBetAmount)
    }

    // Daily Mission
    function onSportChange(index, sportSelected) {
        var data = JSON.parse(sportSelected)
        if (data) {
            if (sportSelected !== undefined) {
                const updatedFormData = { ...form.getFieldsValue() }
                updatedFormData.dailyMission[index].betType = undefined

                form.setFieldsValue({
                    dailyMission: updatedFormData.dailyMission,
                })

                var sportInfo = betTypesBySports[data[0]]
                if (sportInfo !== undefined) {
                    setBetTypes(sportInfo)
                }
            }
        }
    }

    function onWagerTypeChange(wagerTypeSelected) {
    }

    function onBetTypeChange(betTypeSelected) {
    }

    function setBetTypesBySportsAsync(sports) {
         Object.entries(sports).forEach(async ([key, value]) => {
             setBetTypesBySports(prevBetTypes => ({ ...prevBetTypes, [key]: value }))
        });
    }

    let rulesProps = {
        checkCompetitionFilter: checkCompetitionFilter,
        setCheckCompetitionFilter: setCheckCompetitionFilter,
        sportCompetitions: sportCompetitions,
        setSportCompetitions: setSportCompetitions,
        competitionData: competitionData,
        setCompetitionData: setCompetitionData,
        selectedSports: selectedSports,
        selectedSport: selectedSport,
        setSelectedSports: setSelectedSports,
        setSelectedSport: setSelectedSport,
        handleSportChange: handleSportChange,
        handleSportSelectionChange: handleSportSelectionChange,
        handleSportDeselectionChange: handleSportDeselectionChange,
        handleSportRuleSelectionChange: handleSportRuleSelectionChange,
        handleCompetitionSelectionChange: handleCompetitionSelectionChange,
        handleCurrencyTypesSelectionChange: handleCurrencyTypesSelectionChange,
        onCheckCompetitionFilterChange: onCheckCompetitionFilterChange,
        currencies: currencies,
        freeBetValidity: freeBetValidity,
        onOddsChange: onOddsChange,
        minimalOdds: 0.5,
        oddsTableData: oddsTableData,
        sports: Object.entries(sports).map(([key, val]) => ({ id: key, name: val })),
        tagRender: tagRender,
        minBetAmountByCurrencyType: minBetAmountByCurrencyType
    }

    // Step
    const steps = [
        {
            index: 0,
            title: t('Promotion Information'),
            content: <PromoInfoForm form={form} companies={companies} user={props.user} isPromotionStarted={isPromotionStarted} isUpdateMode={isUpdateMode} isViewOnly={PermissionsService.canViewPromoOnly()} uploadProps={uploadProps} timezones={timezones}
                enComponentDisabled={enComponentDisabled} cnComponentDisabled={cnComponentDisabled} thComponentDisabled={thComponentDisabled} vnComponentDisabled={vnComponentDisabled}
                setEnComponentDisabled={setEnComponentDisabled} setCnComponentDisabled={setCnComponentDisabled} setThComponentDisabled={setThComponentDisabled} setVnComponentDisabled={setVnComponentDisabled}
            ></PromoInfoForm >,
        },
        {
            index: 1,
            title: t('Rules'),
            content: <RulesForm form={form} isPromotionStarted={isPromotionStarted || PermissionsService.canViewPromoOnly()} props={rulesProps} ></RulesForm>,
        },
        {
            index: 2,
            title: t('Daily Missions'),
            content: <DailyMissionsForm form={form} isPromotionStarted={isPromotionStarted || PermissionsService.canViewPromoOnly()} wagerTypes={wagerTypes} wagerType={wagerType} sports={Object.fromEntries(selectedSports.map(item => [item.id, item.name]))} betTypes={betTypesBySports} mCount={existingMissionCount} onWagerTypeChange={onWagerTypeChange} onSportChange={onSportChange} onBetTypeChange={onBetTypeChange} currencyCode={currencyCode}></DailyMissionsForm>,
        },
        {
            index: 3,
            title: t('Summary'),
            content: <DailyMissionSummaryForm promoDailyMission={promotionDailyMission} companies={companies} sportCompetitionList={competitionData}></DailyMissionSummaryForm>,
        }
    ]
    const [current, setCurrent] = useState(0)

    const onChange = (value) => {
        //setCurrent(value)
    }

    const next = () => {
        form.validateFields().then((values, err) => {
            if (values) {
                setPromotionDailyMission(prevInfo => ({ ...prevInfo, [current]: values }))
                setCurrent(current + 1)
            }
        }).catch(e => {
        });
    }

    const prev = () => {
        setCurrent(current - 1)
    }

    const confirm = (e) => {
        history.push('/dailyMissions')
    };

    const cancel = (e) => {
    };

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }))

    useEffect(() => {
        const getDailyMission = async () => {
            var dailyMission = await getPromotionDailyMission()
            await getPageOptionsByCompany(dailyMission)
            var isPromoStarted = false
            if (dailyMission !== null) {
                setFooterInfo(dailyMission)
                var today = new Date()
                var startDate = new Date(dailyMission.promotionStart)
                isPromoStarted = today >= startDate
            } else {
                setFooterInfo(null)
            }
            setPromotionStarted(isPromoStarted)
        }

        getDailyMission()
        setOddsTableData(defaultData)

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <PageContent>
            <Spin tip={t("Please wait")} size="large" spinning={loading} delay={500} className="spin">
                <Form {...formItemLayout} form={form} onFinishFailed={onFinishFailed} onFieldsChange={onValuesChange} initialValues={pageInitialValues} className="promotionInfoForm">
                    <Steps current={current} items={items} onChange={onChange} />
                    <div className="steps-content">{steps[current].content}</div>
                    <Row justify="end">
                        {footerInfo ? (
                            <Col flex="0 1 auto" style={{ textAlign: 'right', marginTop: '10px' }}>
                                {' '}
                                {t('Created by')} &nbsp;
                                <Tag color="processing" style={fontStyle}>
                                    {footerInfo.createdBy}
                                </Tag>
                                {t('at')} &nbsp;
                                <Tag color="default" style={fontStyle}>
                                    {TimeConverter.UtctoGmtMinus4(footerInfo.createdDateTime)}
                                </Tag>
                                <br />
                                {t('Last updated by')} &nbsp;
                                <Tag color="processing" style={fontStyle}>
                                    {footerInfo.updatedBy}
                                </Tag>
                                {t('atUpdate')} &nbsp;
                                <Tag color="default" style={fontStyle}>
                                    {TimeConverter.UtctoGmtMinus4(footerInfo.updatedDateTime)}
                                </Tag>
                            </Col>) : (
                            <Col flex="auto"></Col>
                        )}
                        <Col flex="auto"></Col>
                        <Col>
                            <div className="steps-action">
                                {(PermissionsService.checkPermission(Constants.pageActions.createDailyMission) || PermissionsService.checkPermission(Constants.pageActions.updateDailyMission) ?
                                    <Popconfirm
                                        title={<pre>{t("Are you sure want to cancel?")}</pre>}
                                        onConfirm={() => confirm()}
                                        onCancel={cancel}
                                        okText={t("Yes")}
                                        cancelText={t("No")}
                                       >
                                        <Button type="danger" style={{ margin: '0 8px 0 0' }}>
                                            {t('Cancel')}
                                        </Button>
                                    </Popconfirm>
                                    : <Button onClick={() => confirm()} type="danger" style={{ margin: '0 8px 0 0' }}>
                                        {t('Cancel')}
                                    </Button>
                                )}
                                {current > 0 && (
                                    <Button onClick={() => prev()} style={{ margin: '0 8px 0 0' }}>
                                        {t('Previous')}
                                    </Button>
                                )}
                                {current < steps.length - 1 && (
                                    <Button type="primary" onClick={() => next()}>
                                        {t('Next')}
                                    </Button>
                                )}
                                {PermissionsService.checkPermission(Constants.pageActions.createDailyMission) || PermissionsService.checkPermission(Constants.pageActions.updateDailyMission) ?
                                    current === steps.length - 1 && (
                                        <Button type="primary"
                                            onClick={() => onFinish()}>
                                            {t('Save')}
                                        </Button>
                                    ) : <></>
                                }
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </PageContent >
    )
}