import './LoginPage.css'

import React from 'react'

import { LoginForm } from '../components'
import { AuthService } from '../services'
import { Dropdown, Button } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import logo from '../logo.svg'

export const LoginPage = (props) => {

  const { i18n } = useTranslation()
  const languages = {
    en: 'ENG',
    ch: '中文',
    th: 'ไทย',
  }

  const onLogin = async (values) => {
    const result = await AuthService.login(values.username, values.password, i18n.language)
    if (result.succeeded) {
      props.history.push('/')
    }
    return result.succeeded
  }

  function changeLanguage(lang) {
    i18n.changeLanguage(lang)
  }

  const handleButtonClick = (e) => {
    changeLanguage(e.key)
  }

  const items = Object.keys(languages).map(key => ({ label: languages[key], key: key}))

  const menuProps = {
    items,
    onClick: handleButtonClick,
  }

  return (
    <div className="login-form-container">
      <img src={logo} className="app-logo" alt="logo" />
      <Dropdown menu={menuProps}>
        <Button>
          {languages[i18n.language]} <DownOutlined />
        </Button>
      </Dropdown>
      <LoginForm onSubmit={onLogin} />
    </div>
  )
}
