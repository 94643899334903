const statuses = {
  enabled: 'Enabled',
  expired: 'Expired',
  disabled: 'Disabled'
}

const categories = {
  luckydraw: 'Lucky Draw',
  dailymission: 'Daily Mission'
}

const prizeItemStatuses = {
  enabled: 'Enabled',
  disabled: 'Disabled'
}

const pageActions = {
    adminUtils: 'AdminUtils',
    qa: 'Qa',
    viewDailyMission: "ViewPromo",
    createDailyMission: "CreatePromo",
    updateDailyMission: "UpdatePromo",
    viewFreebetQuery: "ViewFreebetQuery"
}

const languages = {
  en: 'en',
  ch: 'ch',
  th: 'th',
  vn: 'vn'
}

const currencies = {
    rmb: 'RMB',
    thb: 'THB',
    vnd: 'VND',
    cny: 'CNY'
}

export const Constants = {
  statuses,
  categories,
  pageActions,
  languages,
  currencies
}

export const PrizeItemConstants = {
  prizeItemStatuses
}