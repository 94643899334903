import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col, Input, Select, Button, Spin } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

export const PrizeItemSearchForm = ({ onSearch, statuses, selectedStatuses, companies, user }) => {
  const { Option } = Select
  const { t } = useTranslation()
  const [selectedCompanies, setSelectedCompanies] = useState(user && user.companyId ? [] : ['0'])
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const colProps = { flex: '0 1 460px' }
  const formItemLayout = {
    labelCol: { flex: '130px' },
    wrapperCol: { flex: 'auto' },
    labelAlign: 'left'
  }

  const initialValues = {
    status: selectedStatuses || [],
    company: user.companyId || '0' // Default
  }

  function onSelect(selectedValue, field) {
    if (selectedValue === 'All') {
      form.setFieldsValue(field)
    }
  }

  function onCompanyChanged(selectedCompanies) {
    if (selectedCompanies && selectedCompanies.includes('0')) {
      var allOption = ['0']
      setSelectedCompanies(allOption)
      form.setFieldsValue({ company: allOption })
    } else setSelectedCompanies(selectedCompanies)
  }

  function tagRender(props) {
    const { label, value, closable, onClose } = props

    if (selectedCompanies && selectedCompanies[0] === value && selectedCompanies[0] !== '0') {
      return <span>{`${selectedCompanies.length} of ${Object.keys(companies).length} selected`}</span>
    }
    return ''
  }

  const handleOnFinish = async (values) => {
    setLoading(true)
    if (onSearch) await onSearch(values)
    setLoading(false)
  }

  return (
    <Form {...formItemLayout} form={form} onFinish={handleOnFinish} initialValues={initialValues}>
      <Row gutter={[8, 4]}>
        <Col {...colProps}>
          <Form.Item name="id" label={t('Prize Item ID')}>
            <Input placeholder={t('Prize Item ID')} />
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item name="name" label={t('Prize Item Name')}>
            <Input placeholder={t('Prize Item Name')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 4]}>
        <Col {...colProps}>
          <Form.Item name="status" label={t('Prize Item Status')}>
            <Select placeholder={t('Select a status')} mode="multiple" onSelect={(x) => onSelect(x, { status: Object.entries(statuses).map(([key, value]) => key) })} allowClear>
              <Option value="All">{t('All')}</Option>
              {Object.entries(statuses).map(([key, value]) => (
                <Option value={key} key={key}>
                  {t(value)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item name="company" label={t('Company')}>
            {user.isBusinessUnit ? (
              <Select
                placeholder={t('Select a Company')}
                mode="multiple"
                tagRender={selectedCompanies && selectedCompanies.includes('0') ? '' : tagRender}
                allowClear
                disabled={!user.isBusinessUnit}
                onChange={onCompanyChanged}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value="0" key="0" style={{ visibility: Object.keys(companies).length > 0 ? '' : 'hidden' }}>
                  {t('All')}
                </Option>
                {Object.entries(companies).map(([key, value]) => (
                  <Option value={key} key={key} disabled={selectedCompanies.includes('0')}>
                    {t(value)}
                  </Option>
                ))}
                {Object.keys(companies).length <= 0 ? (
                  <Option disabled={true}>
                    <Spin />
                  </Option>
                ) : (
                  ''
                )}
              </Select>
            ) : (
              <Select disabled={!user.isBusinessUnit}>
                {Object.entries(companies).map(([key, value]) => (
                  <Option value={key} key={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col flex={'0 1 100px'}>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} icon={<SearchOutlined />}>
              {t('Search')}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

PrizeItemSearchForm.propTypes = {
  onSearch: PropTypes.func,
  form: PropTypes.object,
  statuses: PropTypes.object,
  selectedStatuses: PropTypes.arrayOf(PropTypes.string),
  companies: PropTypes.object,
  user: PropTypes.object
}
