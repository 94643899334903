import React from 'react'

export const LoadingPage = (props) => {
  return (
    <div className="center">
      <div className="loading-logo">
        <div className="loader"></div>
      </div>
    </div>
  )
}
