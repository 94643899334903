import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Breadcrumb, Row, Col } from 'antd'

export const PageContent = ({ actionButtons, children }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const pathName = location.pathname

  const breadcrumbNameMap = {
    '/promotions': t('Promotion Management'),
    '/': t('Promotion Management'),
    '/promotions/create': t('Create Promotion'),
    '/promotions/edit': t('Update Promotion'),
    '/luckyDraw': t('Lucky Draw Participant Management'),
    '/prizeItems': t('Prize Item Management'),
    '/dailyMissions': t('Daily Missions'),
    '/dailyMissions/create': t('Create Daily Mission'),
    '/dailyMissions/edit': t('Update Daily Mission'),
    '/walletManagement': t('Wallet Management'),
    '/freebetReport': t('Freebet Query'),
    '/windowServiceStatusSetting': t("Window Service Status Setting")
  }
  const pathSnippets = pathName === '/' ? [pathName] : pathName.split('/').filter((i) => i)
  const breadcrumbItems = pathSnippets.map((_, index) => {
    const url = pathSnippets.length > 1 ? `/${pathSnippets.slice(0, index + 1).join('/')}` : pathName
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbNameMap[url]}</Link>
      </Breadcrumb.Item>
    )
  })

  return (
    <>
      <Row className="page-actions-container" align="middle">
        <Col flex="auto">
          <Breadcrumb className="breadcrumbStyle">{breadcrumbItems}</Breadcrumb>
        </Col>
        <Col flex="100px">{actionButtons}</Col>
      </Row>

      {children}
    </>
  )
}
