import React from 'react'
import { Row, Col, Button, Tag } from 'antd'
import { useTranslation } from 'react-i18next'

export const TabFooterLayout = ({ onCancel, footerInfo, form, shouldShowSaveButton, customizeSaveBtnName }) => {
  const { t } = useTranslation()
  const fontStyle = { fontSize: '11px' }
  return (
    <div style={{ marginTop: '10px', fontSize: '11px' }}>
      <Row justify="end">
        {footerInfo ? (
          <Col flex="0 1 auto" style={{ textAlign: 'right' }}>
            {' '}
            {t('Created by')} &nbsp;
            <Tag color="processing" style={fontStyle}>
              {footerInfo.createdBy}
            </Tag>
            {t('at')} &nbsp;
            <Tag color="default" style={fontStyle}>
              {footerInfo.createdDate}
            </Tag>
            <br />
            {t('Last updated by')} &nbsp;
            <Tag color="processing" style={fontStyle}>
              {footerInfo.updatedBy}
            </Tag>
            {t('atUpdate')} &nbsp;
            <Tag color="default" style={fontStyle}>
              {footerInfo.updatedDate}
            </Tag>
          </Col>
        ) : (
          <Col flex="auto"></Col>
        )}
        <Col flex="auto"></Col>
        <Col flex="110px">
          <Button type="primary" htmlType="submit" style={{ width: 100, marginRight: '8px', visibility: shouldShowSaveButton ? '' : 'hidden' }} form={form}>
            {t(customizeSaveBtnName === undefined ? 'Save' : customizeSaveBtnName)}
          </Button>
        </Col>
        <Col flex="100px">
          <Button onClick={onCancel} style={{ width: 100 }}>
            {t('Cancel')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}
