// TODO: Convert Utc to GMT-4 - Gilbert [Done]
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col, Select, Button, DatePicker, Spin } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import moment from 'moment'

export const LuckyDrawSearchForm = ({ onSearch, onCompanySelect, companies, promotions, user }) => {
  const { Option } = Select
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const formItemLayout = {
    labelCol: { flex: '140px' },
    labelAlign: 'left'
  }

  function PageForm() {
    if (Object.keys(companies).length > 0 && (Object.keys(promotions).length > 0 || user.isBusinessUnit)) {
      var sortedPromotions = Object.keys(promotions)
        .map((key) => {
          return [key, promotions[key]]
        })
        .sort((first, second) => {
          return first[1].promoName.localeCompare(second[1].promoName)
        })

      var initialValues = {
        company: user.companyId,
        promoName: sortedPromotions.length === 0 ? null : sortedPromotions[0][0],
        settlement: moment(new Date(), 'YYYY/MM/DD')
      }

      return (
        <Form {...formItemLayout} form={form} initialValues={initialValues}>
          <Row gutter={[8, 4]}>
            <Col flex="0 1 360px">
              <Form.Item name="company" label={t('Company')} rules={[{ required: true, message: t('This is a mandatory field') }]}>
                {user.isBusinessUnit ? (
                  <Select
                    placeholder={t('Select a Company')}
                    showSearch
                    optionFilterProp="children"
                    onSelect={(x) => onCompanySelect(x, form)}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    allowClear
                  >
                    {Object.entries(companies).map(([key, value]) => (
                      <Option value={key} key={key}>
                        {t(value)}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Select disabled={!user.isBusinessUnit}>
                    {Object.entries(companies).map(([key, value]) => (
                      <Option value={key} key={key}>
                        {t(value)}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col sm={24} md={0} />
            <Col flex="1 1 400px">
              <Form.Item name="promoName" label={t('Promo Name')} rules={[{ required: true, message: t('This is a mandatory field') }]}>
                <Select placeholder={t('Select a Promotion')} allowClear>
                  {sortedPromotions.map((x) => (
                    <Option value={x[0]} key={x[0]}>
                      {t(x[1].promoName)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col flex="0 1 360px">
              <Form.Item name="settlement" label={t('Settlement')} rules={[{ required: true, message: t('This is a mandatory field') }]}>
                <DatePicker />
              </Form.Item>
            </Col>
            <Col xs={24} sm={0} />
            <Col flex="1 1 100px" style={{ textAlign: 'right' }}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  onClick={async () => {
                    setLoading(true)
                    await onSearch(form.getFieldsValue())
                    setLoading(false)
                  }}
                  icon={<SearchOutlined />}
                >
                  {t('Search')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )
    }

    return <Spin tip={'Loading...'} size="large"></Spin>
  }

  return PageForm()
}

LuckyDrawSearchForm.propTypes = {
  onSearch: PropTypes.func
}
