import './Sidebar.css'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Layout, Menu } from 'antd'
import { LogoutOutlined, ProfileOutlined, BarChartOutlined, SettingOutlined, MonitorOutlined, ToolOutlined, FileTextOutlined } from '@ant-design/icons'
import logo from '../../logo.svg'
import { PermissionsService } from '../../services'
import { Constants } from './'

const { Sider } = Layout

export const Sidebar = ({ collapsed, logout }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const selectedRoute = location.pathname || '/'
  const initialSelectedRoute = location.pathname === '/' ? '/dailyMissions' : location.pathname
  

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    }
  }

  const nonAdminMenuItems = [
      PermissionsService.checkPermission(Constants.pageActions.viewDailyMission) || PermissionsService.checkPermission(Constants.pageActions.createDailyMission) || PermissionsService.checkPermission(Constants.pageActions.updateDailyMission) ? getItem(<Link to="/dailyMissions">{t('Daily Missions')}</Link>, '/dailyMissions', <ProfileOutlined />) : null,
      PermissionsService.checkPermission(Constants.pageActions.viewFreebetQuery)? getItem(<>{t('Reports')}</>, '/reports', <BarChartOutlined />, [
      getItem(<Link to="/freebetReport">{t('Freebet Query')}</Link>, '/freebetReport')
    ]) : null,
  ]

  const qAMenuItems = [
    ...nonAdminMenuItems,
    getItem(<Link to="/systemLog">{t('System Logs')}</Link>, '/systemLog', <FileTextOutlined />)
  ]

  const adminMenuItems = [...qAMenuItems]
  adminMenuItems.push(
    getItem(<>{t('Admin Utils')}</>, '/utils', <ToolOutlined />, [
      getItem(<Link to="/windowServiceStatusSetting">{t('Window Service')}</Link>, '/windowServiceStatusSetting', <SettingOutlined />),
      getItem(<Link to="/dataMonitoring">{t('Data Monitoring')}</Link>, '/dataMonitoring', <MonitorOutlined />),
    ])
  )

  const logoutMenuItem = [
    getItem(<>{t('Logout')}</>, '99', <LogoutOutlined />),
  ]

  return (
    <Sider collapsible collapsedWidth={60} trigger={null} collapsed={collapsed}>
      <div className="logo">
        <img src={logo} alt="Promo" />
      </div>
      <Menu
        theme="dark" 
        selectedKeys={[initialSelectedRoute]}
        defaultOpenKeys={['/dailyMissions']}
        mode="inline"
        items={ PermissionsService.checkPermission(Constants.pageActions.adminUtils) ? adminMenuItems :
                PermissionsService.checkPermission(Constants.pageActions.qa) ? qAMenuItems : nonAdminMenuItems}
      />

      <Menu theme="dark" mode="inline" className="logout-menu" selectable={false} onClick={logout} items={logoutMenuItem} />

    </Sider>
  )
}
