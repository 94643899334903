import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col, Input, DatePicker, Select, Button, Spin } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { PermissionsService } from '../services'
import { Constants } from './core'

const { Option } = Select

export const PromoSearchForm = ({ statuses, categories, currencies, companies, initialSearchCriteria, onSearch, user, hidePromoCategory = false }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [selectedCompanies, setSelectedCompanies] = useState(user && user.companyId ? [] : ['0'])
  const [selectedCategories, setSelectedCategories] = useState(['0'])
  const [selectedCurrencies, setSelectedCurrencies] = useState(['0'])
  const [loading, setLoading] = useState(false)

    const colProps = { xs: { span: 24 }, md: { span: 12 }, lg: { span: 10 }, xl: { span: 6 } }
    const labelCol = { flex: '150px' }
    const formItemLayout = {
        labelCol: {
            xs: { span: 16 },
            sm: { span: 14 },
            md: { span: 12 },
            lg: { span: 10 },
            xxl: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
            md: { span: 18 },
            lg: { span: 16 },
            xxl: { span: 14 }
        },
        labelAlign: 'left'
    }

    function onSelect(selectedValue, field) {
        if (selectedValue === 'All') {
            form.setFieldsValue(field)
        }
    }

    function onCompanyChanged(selectedCompanies) {
        if (selectedCompanies && selectedCompanies.includes('0')) {
            var allOption = ['0']
            setSelectedCompanies(allOption)
            form.setFieldsValue({ company: allOption })
        } else {
            setSelectedCompanies(selectedCompanies)
        }
    }

    function onCategoryChanged(selectedCategories) {
        if (selectedCategories && selectedCategories.includes('0')) {
            var allOption = ['0']
            setSelectedCategories(allOption)
            form.setFieldsValue({ category: allOption })
        } else {
            setSelectedCategories(selectedCategories)
        }
    }

    function onCurrenciesChanged(selectedCurrencies) {
        if (selectedCurrencies && selectedCurrencies.includes('0')) {
            var allOption = ['0']
            setSelectedCurrencies(allOption)
            form.setFieldsValue({ currency: allOption })
        } else {
            setSelectedCurrencies(selectedCurrencies)
        }
    }

    function tagRender(props) {
        const { value } = props

        if (selectedCompanies && selectedCompanies[0] === value && selectedCompanies[0] !== '0') {
            return <span>{`${selectedCompanies.length} of ${Object.keys(companies).length} selected`}</span>
        }
        return ''
    }

    return (
        <Form {...formItemLayout} form={form} initialValues={initialSearchCriteria}>
          <Row gutter={(8, 8)}>
            <Col {...colProps}>
                <Form.Item name="promoID" label={t('Promo ID')} labelCol={labelCol}>
                    <Input placeholder={t('Promotion ID')} />
                </Form.Item>
            </Col>
            <Col {...colProps}>
                <Form.Item name="promoName" label={t('Promo Name')} labelCol={labelCol}>
                    <Input placeholder={t('Promotion Name')} />
                </Form.Item>
            </Col>
            {!hidePromoCategory ? (
              <Col {...colProps}>
                <Form.Item name="category" label={t('Promo Category')} labelCol={labelCol}>
                  <Select placeholder={t('Select a category')}  mode="multiple" onChange={onCategoryChanged} allowClear>
                    <Option value="0" key="0" style={{ visibility: Object.keys(categories).length > 0 ? '' : 'hidden' }}>
                      {t('All')}
                    </Option>
                    {Object.entries(categories).map(([key, value]) => (
                    <Option value={value} key={key} disabled={selectedCategories.includes('0')}>
                      {t(value)}
                    </Option>
                     ))}
                    </Select>
                </Form.Item> 
              </Col>) : (<Col {...colProps}></Col>)}

            <Col {...colProps}>
              <Form.Item name="currency" label={t('Promo Currency')} labelCol={labelCol}>
                <Select placeholder={t('Select a currency')}  mode="multiple" onChange={onCurrenciesChanged} allowClear>
                    <Option value="0" key="0" style={{ visibility: Object.keys(currencies).length > 0 ? '' : 'hidden' }}>
                      {t('All')}
                    </Option>
                    {Object.entries(currencies).map(([key, value]) => (
                    <Option value={value} key={key} disabled={selectedCurrencies.includes('0')}>
                      {t(value)}
                    </Option>
                     ))}
                    </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 0 }} md={{ span: 0 }} lg={{ span: 0 }} xl={{ span: 0 }}></Col>

            <Col {...colProps}>
              <Form.Item name="status" label={t('Promo Status')} labelCol={labelCol}>
                <Select placeholder={t('Select a status')} mode="multiple" onSelect={(x) => onSelect(x, { status: Object.entries(statuses).map(([key, value]) => value) })} allowClear>
                  <Option value="All">{t('All')}</Option>
                  {Object.entries(statuses).map(([key, value]) => (
                    <Option value={value} key={key}>
                      {t(value)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col {...colProps}>
                <Form.Item name="promoPeriod" label={t('Promo Period')} labelCol={labelCol}>
                    <DatePicker picker="month" />
                </Form.Item>
            </Col>
        
            <Col {...colProps}>
              <Form.Item name="company" label={t('Company')} labelCol={labelCol}>
                {user.isBusinessUnit ? (
                  <Select
                    placeholder={t('Select a Company')}
                    mode="multiple"
                    tagRender={selectedCompanies && selectedCompanies.includes('0') ? '' : tagRender}
                    onChange={onCompanyChanged}
                    disabled={!user.isBusinessUnit}
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) => option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value="0" key="0" style={{ visibility: Object.keys(companies).length > 0 ? '' : 'hidden' }}>
                      {t('All')}
                    </Option>
                    {Object.entries(companies).map(([key, value]) => (
                      <Option value={value} key={key} disabled={selectedCompanies.includes('0')}>
                        {t(key)}
                      </Option>
                    ))}
                    <Option value="-1" key="-1" disabled={true} style={{ visibility: Object.keys(companies).length <= 0 ? '' : 'hidden' }}>
                        {<Spin />}
                    </Option>
                  </Select>
                ) : (
                  <Select disabled={!user.isBusinessUnit}>
                    {Object.entries(companies).map(([key, value]) => (
                      <Option value={value} key={key}>
                          {t(key)}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col flex={'auto'} xl={{ span: 0 }}></Col>
            <Col flex={'none'} xl={{ span: 6 }}>
                <Form.Item>
                    <Button
                        style={{ visibility: PermissionsService.checkPermission(Constants.pageActions.viewDailyMission) ? '' : 'hidden' }}
                        type="primary"
                        loading={loading}
                        onClick={async () => {
                            setLoading(true)
                            await onSearch(form.getFieldsValue())
                            setLoading(false)
                        }}
                        icon={<SearchOutlined />}
                    >
                        {t('Search')}
                    </Button>
                </Form.Item>
            </Col>
          </Row>
        </Form>
    )
}

PromoSearchForm.propTypes = {
    statuses: PropTypes.object,
    categories: PropTypes.object,
    companies: PropTypes.object,
    initialSearchCriteria: PropTypes.object,
    onSearch: PropTypes.func.isRequired
}
