import axios from 'axios'

const search = async (searchCriteria) => {
  var config = {
    params: {
      id: searchCriteria.id,
      name: searchCriteria.name,
      companies: JSON.stringify(searchCriteria.company),
      statuses: JSON.stringify(searchCriteria.status),
      language: searchCriteria.language
    }
  }
  var response = await axios.get('prizeItems', config).catch(console.log)
  if (response) {
    return response.data.result
  }
  return null
}

const create = async (prizeItem) => {
  return axios.post('prizeItems', prizeItem)
}

const update = async (prizeItem) => {
  return axios.put('prizeItems/' + prizeItem.id, prizeItem)
}

const updateStatus = async (id, status) => {
  return axios.put('prizeItems/updateStatus', { id, status })
}

export const PriceItemService = {
  search,
  create,
  update,
  updateStatus
}
