import React, { useState, useEffect } from 'react'
import { Select, Form, Button, Space, InputNumber, Input, Tag } from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { PermissionsService, DailyMissionService, UserService } from '../../services'
import { Constants } from '../../components/core'

import './DailyMissionsForm.css'

export const DailyMissionsForm = ({ form, isPromotionStarted, wagerTypes, wagerType, sports, betTypes, mCount, onWagerTypeChange, onSportChange, onBetTypeChange }) => {
    const { t, i18n } = useTranslation()
    const { Option } = Select
    const [showMissionTitle, setShowMissionTitle] = useState(form.getFieldValue('dailyMission') && form.getFieldValue('dailyMission').length > 0)
    const [missionCount, setMissionCount] = useState(mCount)
    
    function integerChecker(intVal) {
        if (intVal) {
            var parsedInt = Math.trunc(intVal)
            if (Number.isInteger(parsedInt) && parsedInt > 0) {
                return parsedInt
            }
        }
        return ''
    }

    function getBetType(index) {
        var missionObj = form.getFieldValue('dailyMission')
        var sport = missionObj[index]?.sport ?? null
        if (sport) {
            var sportId = parseInt((JSON.parse(sport?.value === undefined ? sport : sport?.value))[0])
            var sportBetTypes = betTypes[sportId] ?? []
            // MACRQ-107 - Remove OU BetType in DailyMission setup
            var filteredBetTypes = Object.fromEntries(Object.entries(sportBetTypes).filter(([key, value]) => key !== "2"))
            return filteredBetTypes
        }
        return []
    }

    function duplicateDailyMissionChecker(missionObj, currentIndex) {
        if (missionObj.length > 1) {
            let emptyStr = ''
            var noWager = missionObj[currentIndex].noWager ?? emptyStr
            var sport = missionObj[currentIndex].sport === undefined ? emptyStr : missionObj[currentIndex].sport.value !== undefined ? missionObj[currentIndex].sport.value : missionObj[currentIndex].sport
            var betType = missionObj[currentIndex].betType === undefined ? emptyStr : missionObj[currentIndex]?.betType.value !== undefined ? missionObj[currentIndex].betType.value : missionObj[currentIndex].betType
            let currentDmStr = `${noWager}_${sport}_${betType}`
            var count = missionObj.length

            for (var i = 0; i < count; i++) {
                if (i !== currentIndex) {
                    var otherWager = missionObj[i].noWager ?? emptyStr
                    var otherSport = missionObj[i].sport === undefined ? emptyStr : missionObj[i].sport.value !== undefined ? missionObj[i].sport.value : missionObj[i].sport
                    var otherBetType = missionObj[i].betType === undefined ? emptyStr : missionObj[i]?.betType.value !== undefined ? missionObj[i].betType.value : missionObj[i].betType
                    var otherDmStr = `${otherWager}_${otherSport}_${otherBetType}`
                    if (currentDmStr === otherDmStr) {
                        return Promise.reject(t("Duplicate daily mission"));
                    }
                    //else {
                    //    //form.setFieldValue(['dailyMission', i, 'noWager'], missionObj[i].noWager)
                    //    form.validateFields([
                    //        ['dailyMission', i, 'noWager']
                    //    ]);
                    //}
                }
            }
        }

        return Promise.resolve()
    }

    async function addDefaultDailyMissions(form) {
        var currency = form.getFieldValue('currencyTypes')
        var defaultDmList = await DailyMissionService.getDefaultDailyMissionList(UserService.getUserLoginLanguage(), JSON.stringify(sports), currency)
        form.setFieldsValue({
            ['dailyMission']: defaultDmList
        })
        setMissionCount(form.getFieldValue('dailyMission').length)
    }

    function MissionTemplate(key, field, add, remove, index) {
        return (
            <div className="space-align-container">
                <div className="space-align-block">
                    <Space key={key} style={{ display: 'flex' }} align="center">
                        <span>{t('Place')}</span>
                        <Form.Item name={[index, "id"]} hidden>
                            <Input hidden disabled />
                        </Form.Item>
                        <Form.Item name={[index, "noWager"]} style={{ margin: 0 }}
                            rules={
                                [
                                    { required: true, message: t('This is a mandatory field') },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            var missionObj = getFieldValue('dailyMission')
                                            return duplicateDailyMissionChecker(missionObj, index)
                                        }
                                    })
                                ]}>
                            <InputNumber placeholder={t('No Of Wagers')} min={1} parser={integerChecker} style={{ width: '100%' }} disabled={isPromotionStarted} />
                        </Form.Item>
                        <Form.Item name={[index, "wagerType"]} style={{ margin: 0 }} initialValue={t(wagerType)}>
                            <Select disabled={true} style={{ minWidth: '120px' }} onChange={onWagerTypeChange}>
                                {Object.entries(wagerTypes)
                                    .sort(([, a], [, b]) => a.localeCompare(b))
                                    .map(([key, value]) => (
                                        <Option value={key} key={key}>
                                            {t(value)}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                        <span> {t('wager in')}</span>
                        <Form.Item name={[index, "sport"]} style={{ margin: 0 }} initialValue={Object.keys(sports).length === 1 ? `["${Object.keys(sports)[0]}","${Object.values(sports)[0]}"]` : ''}
                            rules={
                                [
                                    { required: true, message: t('This is a mandatory field') },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            var missionObj = getFieldValue('dailyMission')
                                            return duplicateDailyMissionChecker(missionObj, index)
                                        }
                                    })
                                ]}>
                            <Select placeholder={t('Sport')} onChange={x => onSportChange(index, x)} style={{ minWidth: '170px' }} disabled={isPromotionStarted || Object.keys(sports).length === 1}>
                                {Object.entries(sports)
                                    .sort(([, a], [, b]) => a.localeCompare(b))
                                    .map(([key, value]) => (
                                        <Option value={JSON.stringify(([key, value]))} key={key}>
                                            {t(value)}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name={[index, "betType"]} style={{ margin: 0 }}
                            rules={
                                [
                                    { required: true, message: t('This is a mandatory field') },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            var missionObj = getFieldValue('dailyMission')
                                            return duplicateDailyMissionChecker(missionObj, index)
                                        }
                                    })
                                ]}>
                            <Select placeholder={t('Bet Type')} onChange={onBetTypeChange} style={{ minWidth: '210px' }} disabled={isPromotionStarted}>
                                {Object.entries(getBetType(index))
                                    .sort(([, a], [, b]) => a.localeCompare(b))
                                    .map(([key, value]) => (
                                        <Option value={JSON.stringify(([key, value]))} key={key}>
                                            {t(value)}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name={[index, "freebetReward"]} style={{ margin: 0 }}
                            rules={
                                [
                                    { required: true, message: t('This is a mandatory field') },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            var missionObj = getFieldValue('dailyMission')
                                            var budget = missionObj[index].freebetBudget
                                            if (budget !== null && value > budget) {
                                                return Promise.reject(t("Freebet budget must be greater than Freebet reward"));
                                            } 
                                            else {
                                                return Promise.resolve();
                                            }
                                        }
                                    })
                                ]}
                        >
                            <InputNumber placeholder={t('Freebet Reward')} min={1} style={{ width: '100%' }} prefix={form.getFieldValue('currencyTypes')?.toUpperCase()} disabled={isPromotionStarted} />
                        </Form.Item>
                        <Form.Item name={[index, "freebetBudget"]} style={{ margin: 0 }}
                            rules={
                                [
                                    { required: true, message: t('This is a mandatory field') },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            var missionObj = getFieldValue('dailyMission')
                                            var reward = missionObj[index].freebetReward
                                            if (reward !== null && value < reward) {
                                                return Promise.reject(t("Freebet budget must be greater than Freebet reward"));
                                            } 
                                            else {
                                                return Promise.resolve();
                                            }
                                        }
                                    })
                                ]}
                        >
                            <InputNumber placeholder={t('Freebet Budget')} min={1} style={{ width: '100%' }} prefix={form.getFieldValue('currencyTypes')?.toUpperCase()} disabled={isPromotionStarted} />
                        </Form.Item>
                        {
                            < Button
                                type="link"
                                disabled={isPromotionStarted}
                                icon={<MinusCircleOutlined style={{ color: 'red' }} />}
                                onClick={() => {
                                    remove(field.name)
                                    setShowMissionTitle(false)
                                    setMissionCount(form.getFieldValue('dailyMission').length)
                                }}
                            />
                        }
                        {
                            < Button
                                type="link"
                                disabled={isPromotionStarted}
                                icon={<PlusCircleOutlined style={{ color: '#26a69a' }} />}
                                onClick={() => {
                                    add('', index + 1)
                                    UpdateMissionCount()
                                }}
                            />
                        }
                    </Space>
                </div>
            </div>

        )
    }

    function UpdateMissionCount(){
        setMissionCount(form?.getFieldValue('dailyMission')?.length ?? 0)
    }

    function DailyMissionTask() {
        return (
            <Form form={form}>
                <>
                    {(form.getFieldValue('dailyMission') && form.getFieldValue('dailyMission').length > 0) || showMissionTitle ? (
                        <Space style={{ display: 'flex', paddingLeft: '25px' }}>
                            <span style={{ width: '100%', marginLeft: '50px' }}>{t('No Of Wagers')}</span>
                            <span style={{ minWidth: '150px', marginLeft: '80px' }}>{t('Wager Type')}</span>
                            <span style={{ minWidth: '200px', marginLeft: '135px' }}>{t('Sport')}</span>
                            <span style={{ minWidth: '150px', marginLeft: '167px' }}>{t('Bet Type')}</span>
                            <span style={{ width: '100%', marginLeft: '100px' }}>{t('Freebet Reward')}</span>
                            <span style={{ width: '100%', marginLeft: '55px' }}>{t('Freebet Budget')}</span>
                        </Space>
                    ) : (
                        ''
                    )}
                    <Form.List
                        name="dailyMission"
                        rules={[
                            {
                                validator: async (_, mission) => {
                                    setMissionCount(mission.length)
                                    if (!mission || mission.length <= 0) {
                                        return Promise.reject(new Error(t('Must add at least 1 daily mission')))
                                    }
                                    
                                        
                                }
                            }
                        ]}
                    >
                        {(fields, { add, remove }, { errors }) => {
                            return (
                                <div style={{ height: '90%', overflow: 'auto' }}>
                                    {fields.map((field, index) => (
                                        <Space key={field.key} style={{ display: 'flex' }} align="baseline">
                                            <Form.Item {...field} name={[field.name, 'mission']}>
                                                {MissionTemplate(field.key, field, add, remove, index)}
                                            </Form.Item>
                                        </Space>
                                    ))}
                                    {
                                        missionCount > 0 ? (<div style={{marginBottom: '20px'}}><span style={{fontSize: '14px'}}>{t('Total')} <Tag style={{fontSize: '14px'}}>{missionCount}</Tag>{t('Daily Missions added')}</span></div>) : <></>
                                    }
                                    {
                                        missionCount <= 0 ? (
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                style={{ width: '510px', visibility: PermissionsService.checkPermission(Constants.pageActions.createDailyMission) || PermissionsService.checkPermission(Constants.pageActions.updateDailyMission) ? '' : 'hidden' }}
                                                disabled={isPromotionStarted}
                                                onClick={() => {
                                                    setShowMissionTitle(true)
                                                    addDefaultDailyMissions(form)
                                                }}
                                                >
                                                <PlusOutlined /> {t('Add Default Daily Missions')}
                                            </Button>
                                        </Form.Item>) : <></>
                                    }
                                    {
                                        missionCount <= 0 ? (
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                style={{ width: '510px', visibility: PermissionsService.checkPermission(Constants.pageActions.createDailyMission) || PermissionsService.checkPermission(Constants.pageActions.updateDailyMission) ? '' : 'hidden' }}
                                                disabled={isPromotionStarted}
                                                onClick={() => {
                                                    add()
                                                    setShowMissionTitle(true)
                                                    UpdateMissionCount()
                                                }}>
                                                <PlusOutlined /> {t('Add Daily Mission')}
                                            </Button>
                                        </Form.Item>) : <></>
                                    }
                                    <Form.ErrorList errors={errors}/>
                                </div>
                            )
                        }}
                    </Form.List>
                </>
            </Form>
        )
    }

    useEffect(() => {
        UpdateMissionCount()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>{DailyMissionTask(form)}</>
    )
}