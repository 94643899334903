import axios from 'axios'

const error = async (callerName, message) => {
    var logInfo = {
        callerName: callerName,
        message: message
    }
    return axios.post('Log/error', logInfo).catch(console.log)
}

export const LogService = {
    error
}