import './App.less'

import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { skip } from 'rxjs/operators'

import { Layout } from 'antd'
import { ConfigProvider } from 'antd'
import enUs from 'antd/es/locale/en_US'
import thTh from 'antd/es/locale/th_TH'
import zhCn from 'antd/es/locale/zh_CN'
import qs from 'qs'

import { AuthService } from './services'
import { useHistory } from 'react-router-dom'
import { PrivateRoute } from './components/core'
import { LoginPage, LoadingPage, PromotionsPage, PromoInfo, PrizeItemsPage, LuckyDrawsPage, UnauthorizedAccessPage, LoggedOutPage } from './pages'
import { DailyMissionsPage, DailyMissionDetailsPage } from './pages/DailyMissions'
import { WalletManagementPage } from './pages/WalletManagement'
import { FreebetQueryReportPage } from './pages/Reports'
import { WindowServiceStatusSettingListPage } from './pages/Settings'
import { DataMonitoringPage, LogsPage } from './pages/Utilities'

import './axios'

function App() {
  const { i18n, t } = useTranslation()
  const history = useHistory()

  if (window.location.pathname === '/authenticate') {
    const token = qs.parse(window.location.search, { ignoreQueryPrefix: true }).token
    const lang = qs.parse(window.location.search, { ignoreQueryPrefix: true }).lang || 'en'

    if (token) {
      const decodedToken = decodeURIComponent(token)
      AuthService.loginWithToken(decodedToken, lang).catch((_) => {
        setTimeout(() => {
          history.push('/unauthorized')
        }, 1000)
      })
    } else {
      setTimeout(() => {
        history.push('/unauthorized')
      }, 1500)
    }
  }

  AuthService.currentUser.pipe(skip(1)).subscribe((user) => {
    const currentPath = history.location.pathname
    if (!user && currentPath !== '/login' && currentPath !== '/authenticate') {
      history.push('/logout')
    } else if (user && (currentPath === '/login' || currentPath === '/authenticate')) {
      history.push('/')
    }

    if (user && i18n.language !== user.lang) {
      i18n.changeLanguage(user.lang)
    }
  })

  function getLocal() {
    switch (i18n.language) {
      case 'en':
        return enUs
      case 'th':
        return thTh
      case 'ch':
        return zhCn
      default:
        return enUs
    }
  }

  return (
    <ConfigProvider locale={getLocal()}>
      <Layout className="app">
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/unauthorized" component={UnauthorizedAccessPage} />
          <Route exact path="/logout" component={LoggedOutPage} />
          <Route exact path="/authenticate" component={LoadingPage} />
          
          <PrivateRoute exact path="/promotions" component={PromotionsPage} title={t('Promotions')} />
          <PrivateRoute path="/promotions/create" component={PromoInfo} title={t('Create Promotion')} />
          <PrivateRoute path="/promotions/edit" component={PromoInfo} title={t('Update Promotion')} />

          <PrivateRoute path="/luckyDraw" component={LuckyDrawsPage} title={t('Lucky Draws')} />

          <PrivateRoute path="/prizeItems" component={PrizeItemsPage} title={t('Prize Items')} />

          <PrivateRoute exact path="/dailyMissions" component={DailyMissionsPage} title={t('Daily Missions')} />
          <PrivateRoute path="/dailyMissions/create" component={DailyMissionDetailsPage} title={t('Create Daily Mission')} />
          <PrivateRoute path="/dailyMissions/edit" component={DailyMissionDetailsPage} title={t('Update Daily Mission')} />

          <PrivateRoute path="/walletManagement" component={WalletManagementPage} title={t('Wallet Management')} />

          <PrivateRoute path="/freebetReport" component={FreebetQueryReportPage} title={t('Freebet Query')} />

          <PrivateRoute path="/windowServiceStatusSetting" component={WindowServiceStatusSettingListPage} title={t('Window Service Status Setting')} />

          <PrivateRoute path="/dataMonitoring" component={DataMonitoringPage} title={t('Data Monitoring')} />

          <PrivateRoute path="/systemLog" component={LogsPage} title={t('System Logs')} />

          <PrivateRoute exact path="/" component={DailyMissionsPage} title={t('Daily Missions')} />
        </Switch>
      </Layout>
    </ConfigProvider>
  )
}

export default App
