import { AuthService } from './' 
import { Constants } from '../components/core'

function checkPermission(action){
    if(action === Constants.pageActions.adminUtils)
        return AuthService.currentUserValue.permissions.canAdmin
    if (action === Constants.pageActions.qa)
        return AuthService.currentUserValue.permissions.canQa
    if(action === Constants.pageActions.viewDailyMission)
        return AuthService.currentUserValue.permissions.canViewPromo
    if(action === Constants.pageActions.createDailyMission)
        return AuthService.currentUserValue.permissions.canCreatePromo
    if(action === Constants.pageActions.updateDailyMission)
        return AuthService.currentUserValue.permissions.canUpdatePromo
    if(action === Constants.pageActions.viewFreebetQuery)
        return AuthService.currentUserValue.permissions.canViewFreebetQuery

    return false
}

function canViewPromoOnly(){
    return AuthService.currentUserValue.permissions.canViewPromo && !AuthService.currentUserValue.permissions.canCreatePromo && !AuthService.currentUserValue.permissions.canUpdatePromo
}

function isBusinessUnit(){
    return AuthService.currentUserValue.isBusinessUnit
}

export const PermissionsService = {
    checkPermission,
    isBusinessUnit,
    canViewPromoOnly
}