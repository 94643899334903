import React, { useState } from 'react'
import { Form, Row, Col, Input, DatePicker, Select, Upload, Button, Checkbox } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Constants } from '../../components/core'
import { UserService } from '../../services'

export const PromoInfoForm = ({ form, companies, user, isPromotionStarted, isUpdateMode, isViewOnly, uploadProps, timezones, enComponentDisabled, cnComponentDisabled, thComponentDisabled, vnComponentDisabled, setEnComponentDisabled, setCnComponentDisabled, setThComponentDisabled, setVnComponentDisabled }) => {

    const { i18n, t } = useTranslation()
    const { Option } = Select
    const { TextArea } = Input
    const { RangePicker } = DatePicker
    const labelCol = { flex: '175px' }
    const labelInnerCol = { flex: '95px' }
    const wrapperCol = { flex: '0 1 400px' }
    const nameWrapperCol = { flex: '0 1 458px' }
    const disableDateRangePicker = isPromotionStarted ? [true, true] : [false, false]
    const [dates, setDates] = useState(null)
    const [value, setValue] = useState(null)
    const isLanguageSelected = enComponentDisabled || cnComponentDisabled || thComponentDisabled || vnComponentDisabled
    const shouldDisableControlWoLanguage = isViewOnly || isPromotionStarted
    const shouldDisableControl = isViewOnly || isPromotionStarted || !isLanguageSelected

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    // Date Range Picker
    const disabledDate = (current) => {
        if (!dates) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
        const customDate = new Date()
        customDate.setDate(customDate.getDate())

        return current < moment(customDate, 'YYYY-MM-DD') || !!tooEarly || !!tooLate;
    };

    const range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };

    const disabledRangeTime = (_, type) => {
        if (type === 'start') {
            return {
                disabledHours: () => range(0, 60).splice(4, 20),
                disabledMinutes: () => range(30, 60),
                disabledSeconds: () => [55, 56],
            };
        }
        return {
            disabledHours: () => range(0, 60).splice(20, 4),
            disabledMinutes: () => range(0, 31),
            disabledSeconds: () => [55, 56],
        };
    };

    const onOpenChange = (open) => {
        if (open) {
            setDates([null, null]);
        } else {
            setDates(null);
        }
    };

    return (
        <Form form={form} name='promoInfo'>
            <Row gutter={[8, 0]} className={`form-${i18n.language}`}>
                <Col span={24}>
                    <Form.Item label={t('Language')} name="language" labelCol={{ flex: '275px' }} wrapperCol={wrapperCol} rules={[{ required: true, message: t('This is a mandatory field') }]}>
                        <Checkbox.Group disabled={shouldDisableControlWoLanguage} style={{ lineHeight: '32px' }}>
                            <Checkbox value={Constants.languages.en} onChange={(e) => setEnComponentDisabled(e.target.checked)} >{t("EN")}</Checkbox>
                            <Checkbox value={Constants.languages.ch} onChange={(e) => setCnComponentDisabled(e.target.checked)} >{t("CN")}</Checkbox>
                            <Checkbox value={Constants.languages.th} onChange={(e) => setThComponentDisabled(e.target.checked)} >{t("TH")}</Checkbox>
                            <Checkbox value={Constants.languages.vn} onChange={(e) => setVnComponentDisabled(e.target.checked)} >{t("VN")}</Checkbox>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label={t('Promo Name')} labelCol={labelCol} wrapperCol={{ flex: '1 1 450px' }} className="mb-0" style={{ maxWidth: '2000px' }}>
                        <Row gutter={[8, 0]}>
                            <Col {...nameWrapperCol}>
                                <Form.Item name="nameEn" label={t('EN')} labelCol={labelInnerCol} rules={[{ required: enComponentDisabled, message: t('This is a mandatory field') }]}>
                                    <Input placeholder={t('Promotion Name EN')} disabled={shouldDisableControl} />
                                </Form.Item>
                            </Col>
                            <Col {...nameWrapperCol}>
                                <Form.Item name="nameCn" label={t('CN')} labelCol={labelInnerCol} rules={[{ required: cnComponentDisabled, message: t('This is a mandatory field') }]}>
                                    <Input placeholder={t('Promotion Name CN')} disabled={shouldDisableControl} />
                                </Form.Item>
                            </Col>
                            <Col {...nameWrapperCol}>
                                <Form.Item name="nameTh" label={t('TH')} labelCol={labelInnerCol} rules={[{ required: thComponentDisabled, message: t('This is a mandatory field') }]}>
                                    <Input placeholder={t('Promotion Name TH')} disabled={shouldDisableControl} />
                                </Form.Item>
                            </Col>
                            <Col {...nameWrapperCol}>
                                <Form.Item name="nameVn" label={t('VN')} labelCol={labelInnerCol} rules={[{ required: vnComponentDisabled, message: t('This is a mandatory field') }]}>
                                    <Input placeholder={t('Promotion Name VN')} disabled={shouldDisableControl} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label={t('Promo Tagline')} labelCol={labelCol} wrapperCol={{ flex: '1 1 450px' }} className="mb-0" style={{ maxWidth: '2000px' }}>
                        <Row gutter={[8, 0]}>
                            <Col {...nameWrapperCol}>
                                <Form.Item name="taglineEn" label={t('EN')} labelCol={labelInnerCol} rules={[{ required: enComponentDisabled, message: t('This is a mandatory field') }]}>
                                    <Input placeholder={t('Promotion Tagline EN')} disabled={shouldDisableControl} />
                                </Form.Item>
                            </Col>
                            <Col {...nameWrapperCol}>
                                <Form.Item name="taglineCn" label={t('CN')} labelCol={labelInnerCol} rules={[{ required: cnComponentDisabled, message: t('This is a mandatory field') }]}>
                                    <Input placeholder={t('Promotion Tagline CN')} disabled={shouldDisableControl} />
                                </Form.Item>
                            </Col>
                            <Col {...nameWrapperCol}>
                                <Form.Item name="taglineTh" label={t('TH')} labelCol={labelInnerCol} rules={[{ required: thComponentDisabled, message: t('This is a mandatory field') }]}>
                                    <Input placeholder={t('Promotion Tagline TH')} disabled={shouldDisableControl} />
                                </Form.Item>
                            </Col>
                            <Col {...nameWrapperCol}>
                                <Form.Item name="taglineVn" label={t('VN')} labelCol={labelInnerCol} rules={[{ required: vnComponentDisabled, message: t('This is a mandatory field') }]}>
                                    <Input placeholder={t('Promotion Tagline VN')} disabled={shouldDisableControl} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label={t('Promo Description')} labelCol={labelCol} wrapperCol={{ flex: '1 1 450px' }} style={{ maxWidth: '2000px' }}>
                        <Row gutter={[8, 0]}>
                            <Col {...nameWrapperCol}>
                                <Form.Item name="descriptionEn" label={t('EN')} labelCol={labelInnerCol} rules={[{ required: enComponentDisabled, message: t('This is a mandatory field') }]}>
                                    <TextArea showCount maxLength={2000} placeholder={t('Promotion Description EN')} disabled={shouldDisableControl} autoSize={{
                                        minRows: 4,
                                        maxRows: 4,
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col {...nameWrapperCol}>
                                <Form.Item name="descriptionCn" label={t('CN')} labelCol={labelInnerCol} rules={[{ required: cnComponentDisabled, message: t('This is a mandatory field') }]}>
                                    <TextArea showCount maxLength={2000} placeholder={t('Promotion Description CN')} disabled={shouldDisableControl} autoSize={{
                                        minRows: 4,
                                        maxRows: 4,
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col {...nameWrapperCol}>
                                <Form.Item name="descriptionTh" label={t('TH')} labelCol={labelInnerCol} rules={[{ required: thComponentDisabled, message: t('This is a mandatory field') }]}>
                                    <TextArea showCount maxLength={2000} placeholder={t('Promotion Description TH')} disabled={shouldDisableControl} autoSize={{
                                        minRows: 4,
                                        maxRows: 4,
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col {...nameWrapperCol}>
                                <Form.Item name="descriptionVn" label={t('VN')} labelCol={labelInnerCol} rules={[{ required: vnComponentDisabled, message: t('This is a mandatory field') }]}>
                                    <TextArea showCount maxLength={2000} placeholder={t('Promotion Description VN')} disabled={shouldDisableControl} autoSize={{
                                        minRows: 4,
                                        maxRows: 4,
                                    }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label={t('Promo Banner')} labelCol={labelCol} wrapperCol={{ flex: '1 1 450px' }} style={{ maxWidth: '2000px' }}>
                        <Row gutter={[8, 0]}>
                            <Col {...nameWrapperCol}>
                                <Form.Item name="promoBannerEn" label={t('EN')} labelCol={labelInnerCol} rules={[{ required: enComponentDisabled, message: t('This is a mandatory field') }]} valuePropName="fileList" getValueFromEvent={normFile}>
                                    <Upload {...uploadProps}>
                                        <Button icon={<UploadOutlined />} disabled={shouldDisableControl}>{t('Click to Upload')}</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col {...nameWrapperCol}>
                                <Form.Item name="promoBannerCn" label={t('CN')} labelCol={labelInnerCol} rules={[{ required: cnComponentDisabled, message: t('This is a mandatory field') }]} valuePropName="fileList" getValueFromEvent={normFile}>
                                    <Upload {...uploadProps}>
                                        <Button icon={<UploadOutlined />} disabled={shouldDisableControl}>{t('Click to Upload')}</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col {...nameWrapperCol}>
                                <Form.Item name="promoBannerTh" label={t('TH')} labelCol={labelInnerCol} rules={[{ required: thComponentDisabled, message: t('This is a mandatory field') }]} valuePropName="fileList" getValueFromEvent={normFile}>
                                    <Upload {...uploadProps}>
                                        <Button icon={<UploadOutlined />} disabled={shouldDisableControl}>{t('Click to Upload')}</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col {...nameWrapperCol}>
                                <Form.Item name="promoBannerVn" label={t('VN')} labelCol={labelInnerCol} rules={[{ required: vnComponentDisabled, message: t('This is a mandatory field') }]} valuePropName="fileList" getValueFromEvent={normFile}>
                                    <Upload {...uploadProps}>
                                        <Button icon={<UploadOutlined />} disabled={shouldDisableControl}>{t('Click to Upload')}</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>

                <Col span={24}>

                </Col>

                <Col span={24}>
                    <Form.Item label={t('Mission Banner')} labelCol={labelCol} wrapperCol={{ flex: '1 1 450px' }} style={{ maxWidth: '2000px' }}>
                        <Row gutter={[8, 0]}>
                            <Col {...nameWrapperCol}>
                                <Form.Item name="missionBannerEn" label={t('EN')} labelCol={labelInnerCol} rules={[{ required: enComponentDisabled, message: t('This is a mandatory field') }]} valuePropName="fileList" getValueFromEvent={normFile}>
                                    <Upload {...uploadProps}>
                                        <Button icon={<UploadOutlined />} disabled={shouldDisableControl}>{t('Click to Upload')}</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col {...nameWrapperCol}>
                                <Form.Item name="missionBannerCn" label={t('CN')} labelCol={labelInnerCol} rules={[{ required: cnComponentDisabled, message: t('This is a mandatory field') }]} valuePropName="fileList" getValueFromEvent={normFile}>
                                    <Upload {...uploadProps}>
                                        <Button icon={<UploadOutlined />} disabled={shouldDisableControl}>{t('Click to Upload')}</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col {...nameWrapperCol}>
                                <Form.Item name="missionBannerTh" label={t('TH')} labelCol={labelInnerCol} rules={[{ required: thComponentDisabled, message: t('This is a mandatory field') }]} valuePropName="fileList" getValueFromEvent={normFile}>
                                    <Upload {...uploadProps}>
                                        <Button icon={<UploadOutlined />} disabled={shouldDisableControl}>{t('Click to Upload')}</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col {...nameWrapperCol}>
                                <Form.Item name="missionBannerVn" label={t('VN')} labelCol={labelInnerCol} rules={[{ required: vnComponentDisabled, message: t('This is a mandatory field') }]} valuePropName="fileList" getValueFromEvent={normFile}>
                                    <Upload {...uploadProps}>
                                        <Button icon={<UploadOutlined />} disabled={shouldDisableControl}>{t('Click to Upload')}</Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name="company" label={t('Company')} labelCol={{ flex: '265px' }} wrapperCol={wrapperCol} rules={[{
                        required: true, message: t('This is a mandatory field')
                    }]}>
                        <Select placeholder={t('Select a Company')} showSearch optionFilterProp="key" disabled={shouldDisableControl || isUpdateMode || !user.isBusinessUnit}>
                            {Object.entries(companies).map(([key, value]) => (
                                <Option value={value} key={key}>
                                    {t(key)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name="status" label={t('Promo Status')} labelCol={{ flex: '265px' }} wrapperCol={wrapperCol} rules={[{ required: true, message: t('This is a mandatory field') }]}>
                        <Select placeholder={t('Select a status')} disabled={isViewOnly || !isLanguageSelected}>
                            {Object.entries(Constants.statuses).map(([key, value]) => (
                                <Option value={value} key={key}>
                                    {t(value)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>


                <Col span={24}>
                    <Form.Item label={t('Promo')} labelCol={labelCol} wrapperCol={{ flex: '1 1 440px' }} style={{ maxWidth: '1200px' }}>
                        <Row gutter={[8, 0]}>
                            <Col {...nameWrapperCol}>
                                <Form.Item name="promoDateRange" label={t('Date')} labelCol={labelInnerCol} className="my-custom-label" rules={[{ required: true, message: t('This is a mandatory field') }]}>
                                    <RangePicker value={dates || value}
                                        style={{ width: "395px" }}
                                        disabledDate={disabledDate}
                                        disabledTime={disabledRangeTime}
                                        onCalendarChange={(val) => setDates(val)}
                                        onChange={(val) => setValue(val)}
                                        onOpenChange={onOpenChange}
                                        disabled={shouldDisableControl} />
                                    {/*timezone="UTC+4"*/}
                                </Form.Item>
                            </Col>
                            <Col {...nameWrapperCol}>
                                <Form.Item name="promoTimeZone" label={t('Timezone')} labelCol={labelInnerCol} rules={[{ required: true, message: t('This is a mandatory field') }]}>
                                    <Select
                                        showSearch
                                        placeholder={t("Select Timezone")}
                                        optionFilterProp="fullName"
                                        //defaultValue={null}
                                        disabled={true}
                                        filterOption={(input, option) =>
                                            option.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {timezones.map((tz) => (
                                            <Select.Option key={tz.id} value={tz.zone}>
                                                {" "}
                                                {tz.gmt}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label={t('Remarks')} name="remarks" labelCol={{ flex: '265px' }} wrapperCol={wrapperCol}>
                        <TextArea showCount maxLength={500} placeholder={t('Remarks')} disabled={isViewOnly || !isLanguageSelected} autoSize={{
                            minRows: 8,
                            maxRows: 8,
                        }} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}
