import { AuthService } from '.' 
import { Constants } from '../components/core'

function isEnMandatory(){
    return AuthService.currentUserValue.lang !== Constants.languages.ch
}

function isChMandatory(){
    return AuthService.currentUserValue.lang === Constants.languages.ch
}

function isThMandatory() {
    return AuthService.currentUserValue.lang === Constants.languages.th
}

function getUserLoginLanguage(){
    return AuthService.currentUserValue.lang
}

export const UserService = {
    isEnMandatory,
    isChMandatory,
    isThMandatory,
    getUserLoginLanguage
}