import React, { useState, useEffect } from 'react'
import { Layout, Menu } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { AuthService } from '../../services'
import { Sidebar } from './Sidebar'

const { Header, Content, Footer } = Layout

export const AppLayout = ({ title, userName, children }) => {
  const { i18n } = useTranslation()
  const [collapsed, setCollapsed] = useState(localStorage.getItem('collapsed') !== 'false')
  const languages = {
    en: 'ENG',
    ch: '中文',
    th: 'Tภาษาไทย'
  }

  useEffect(() => {
    localStorage.setItem('collapsed', collapsed)
  })

  function toggle() {
    setCollapsed(!collapsed)
  }

  function logout() {
    AuthService.logout()
  }

  function changeLanguage(lang) {
    i18n.changeLanguage(lang)
  }

  const langMenu = (
    <Menu>
      {Object.entries(languages).map(([key, value]) => (
        <Menu.Item isSelected={i18n.language === key} key={key} onClick={() => changeLanguage(key)}>
          {value}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <>
      <Sidebar collapsed={collapsed} logout={logout} />
      <Layout className="site-layout">
              <Header className="site-layout-header" style={{ padding: 0, height: 60 }}>
          <MenuOutlined className="trigger" onClick={toggle} />
          <span className="page-title" title="Title">
            {title}
          </span>
          <div className="header-menu">
            {/* <Dropdown overlay={langMenu} size="small">
              <Button size="small">
                {languages[i18n.language]} <DownOutlined />
              </Button>
            </Dropdown> */}
            <div className="user-info">{userName}</div>
          </div>
        </Header>
              <Content className="site-content" style={{ overflow: "auto" }}>{children}</Content>
        <Footer style={{ textAlign: 'center' }}></Footer>
      </Layout>
    </>
  )
}
