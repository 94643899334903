import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PlusOutlined } from '@ant-design/icons'
import { PageContent } from '../../components/core'
import { PromoSearchForm, PromoTable } from '../../components'
import { Constants } from '../../components/core'
import moment from 'moment'
import { PageOptionsService, DailyMissionService, PermissionsService } from '../../services'

export const DailyMissionsPage = (props) => {
    const { t, i18n } = useTranslation()
    const [promotions, setPromotions] = useState(null)
    const [pageOptions, setPageOptions] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    var initialSearchCriteria = {}
    if (props.user.companyId) {
        initialSearchCriteria = { status: ['Enabled'], category: ['0'], company: props.user.companyId, currency: ['0'] }
    } else {
        initialSearchCriteria = { status: ['Enabled'], category: ['0'], company: ['0'], currency: ['0'] }
    }
    const categories = {
        dailymission: 'Daily Mission'
    }
    const statuses = Constants.statuses
    const currencies = Constants.currencies // TODO: Remark - Temporarily hardcode to achive supported currencies for search form

    async function onSearch(searchCriteria) {
        var promos = await getPromotionDailyMission(searchCriteria)
        if (promos) {
            var promotions = constructPromotion(promos)
            setPromotions(promotions)
        } else {
            setPromotions(null)
        }
    }

    function objectToQueryString(obj) {
        var str = []
        for (var p in obj) {
            if (obj[p]) {
                if (obj.hasOwnProperty(p)) {
                    if (Array.isArray(obj[p])) {
                        for (var arr in obj[p]) {
                            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p][arr]))
                        }
                    } else {
                        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
                    }
                }
            }
        }

        return str.join('&')
    }

    async function getPromotionDailyMission(searchCriteria) {
        var criteria = constructSearchCriteria(searchCriteria)
        var queryString = objectToQueryString(criteria)
        setIsLoading(true)
        var response = await DailyMissionService.getDailyMissions(queryString)
        setIsLoading(false)
        if (response) {
            return response
        }
        return null
    }

    function constructSearchCriteria(searchCriteria) {
        return {
            promotionDailyMissionId: searchCriteria.promoID,
            promotionDailyMissionName: searchCriteria.promoName,
            //category: searchCriteria.category,
            statuses: searchCriteria.status,
            currencies: searchCriteria.currency,
            companies: searchCriteria.company,
            language: i18n.language,
            period: searchCriteria.promoPeriod ? moment(searchCriteria.promoPeriod).format('yyyy-MM-DD') : ''
        }
    }

    function constructPromotion(promos) {
        var promotions = []
        promos.forEach((promo, index) => {
            promotions.push(
                {
                    id: promo.promotionDailyMissionId,
                    company: promo.companyName,
                    status: promo.status.toLowerCase(),
                    currency: promo.currency,
                    category: 'dailymission',
                    nameEn: promo.promotionNameEn,
                    nameCn: promo.promotionNameCn,
                    nameTh: promo.promotionNameTh,
                    nameVn: promo.promotionNameVn,
                    promoStart: promo.promotionStart,
                    promoEnd: promo.promotionEnd,
                    updatedBy: promo.updatedBy,
                    updatedDate: promo.updatedDateTime,
                    remarks: promo.remarks
                }
            );
        });

        return promotions
    }

    async function getPageOptions() {
        const pageOptions = await PageOptionsService.getPageOptionsCompanies(i18n.language)
        return pageOptions
    }

    useEffect(() => {
        const getEnabledPromotions = async () => {
            var options = await getPageOptions()
            if (options) {
                setPageOptions(options)
            }

            if (PermissionsService.checkPermission(Constants.pageActions.viewDailyMission) || PermissionsService.checkPermission(Constants.pageActions.updateDailyMission)){
                var promos = await getPromotionDailyMission(initialSearchCriteria)
                if (promos) {
                    var promotions = constructPromotion(promos)
                    setPromotions(promotions)
                }
                else {
                    setPromotions(null)
                }
            }
            else{
                setIsLoading(false)
            }
        }
        getEnabledPromotions()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const actionButtons = (
        <Link to="/dailyMissions/create">
            <Button type="primary" className="btn-darkblue" icon={<PlusOutlined />} style={{ display: PermissionsService.checkPermission(Constants.pageActions.createDailyMission) ? '' : 'none' }}>
                {t('Create')}
            </Button>
        </Link>
    )

    return (
        <PageContent actionButtons={actionButtons}>
            <PromoSearchForm onSearch={onSearch} statuses={statuses ?? []} categories={categories ?? []} currencies={currencies ?? []} companies={pageOptions ? pageOptions.companies : {}} initialSearchCriteria={initialSearchCriteria} user={props.user} hidePromoCategory={false} />
            <PromoTable dataSource={promotions} isLoading={isLoading} pageSize={50} onRowClick={(data) => props.history.push(`/dailyMissions/edit?id=${data.id}`)} hidePromoCategory={false} />
        </PageContent>
    )
}
