// TODO: Convert Utc to GMT-4 - Gilbert [Done]
import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PlusOutlined } from '@ant-design/icons'
import { PageContent } from '../components/core'
import { PromoSearchForm, PromoTable } from '../components'
import { Constants } from '../components/core'
import moment from 'moment'
import axios from 'axios'
import './PromotionsPage.css'

export const PromotionsPage = (props) => {
  const { t, i18n } = useTranslation()
  const [promotions, setPromotions] = useState(null)
  const [pageOptions, setPageOptions] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  var initialSearchCriteria = {}
  if (props.user.companyId) {
    initialSearchCriteria = { status: ['enabled'], company: props.user.companyId }
  } else {
    initialSearchCriteria = { status: ['enabled'], company: ['0'] }
  }
  const categories = Constants.categories
  const statuses = Constants.statuses

  async function onSearch(searchCriteria) {
    var promos = await GetPromotions(searchCriteria)
    setPromotions(promos)
  }

  function objectToQueryString(obj) {
    var str = []
    for (var p in obj) {
      if (obj[p]) {
        if (obj.hasOwnProperty(p)) {
          if (Array.isArray(obj[p])) {
            for (var arr in obj[p]) {
              str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p][arr]))
            }
          } else {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
          }
        }
      }
    }

    return str.join('&')
  }

  async function GetPromotions(searchCriteria) {
    var criteria = {
      promoId: searchCriteria.promoID,
      promoName: searchCriteria.promoName,
      category: searchCriteria.category,
      status: searchCriteria.status,
      company: searchCriteria.company,
      language: i18n.language,
      promoDate: searchCriteria.promoPeriod ? moment(searchCriteria.promoPeriod).format('yyyy-MM-DD') : ''
    }

    var queryString = objectToQueryString(criteria)
    setIsLoading(true)
    var response = await axios.get(`Promotions?${queryString}`).catch(console.log)
    setIsLoading(false)
    if (response) {
      return response.data.result
    }
    return null
  }

  async function GetPageOptions() {
    var response = await axios.get(`PageOptions/companies?&Language=${i18n.language}`).catch(console.log)
    if (response) {
      return response.data.result
    }
    return null
  }

  useEffect(() => {
    const getEnabledPromotions = async () => {
      var options = await GetPageOptions()
      setPageOptions(options)

      var promos = await GetPromotions(initialSearchCriteria)
      setPromotions(promos)
    }
    getEnabledPromotions()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const actionButtons = (
    <Link to="/promotions/create">
      <Button type="primary" className="btn-darkblue" icon={<PlusOutlined />} style={{ display: props.user.permissions.canCreatePromo ? '' : 'none' }}>
        {t('Create Promotion')}
      </Button>
    </Link>
  )

  return (
    <PageContent>
      <PromoSearchForm onSearch={onSearch} statuses={statuses ?? []} categories={categories ?? []} companies={pageOptions ? pageOptions.companies : {}} initialSearchCriteria={initialSearchCriteria} user={props.user} />
      <PromoTable dataSource={promotions} isLoading={isLoading} pageSize={50} onRowClick={(data) => props.history.push(`/promotions/edit?promoId=${data.id}`)} />
    </PageContent>
  )
}
