import React, { useState } from 'react'
import { Tabs, Select, Table, Form, Button, Space, InputNumber, Row, Col, Checkbox } from 'antd'
import { MinusCircleOutlined, PlusOutlined, WarningTwoTone } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import './rulesForm.css'

export const RulesForm = ({
  oddTypes,
  memberCategories,
  markets,
  periods,
  betTypes,
  wagerTypes,
  wagerSources,
  currencies,
  onSelectAll,
  form,
  currencyHasError,
  oddsHasError,
  betHasError,
  isPromotionStarted,
  rewardPointHasError,
  oddsTable,
  onMinOddsChange,
  onMaxOddsChange,
  onMinimalOddsChanged,
  minimalOddsCheck
}) => {
  const { t } = useTranslation()
  const { TabPane } = Tabs
  const { Option } = Select
  const [showCurrencyTitle, setShowCurrencyTitle] = useState(form.getFieldValue('currencies') && form.getFieldValue('currencies').length > 0 ? true : false)

  function IsCurrencyFullySelected() {
    var selectedCurrencies = form.getFieldValue('currencies')
    if (selectedCurrencies) return selectedCurrencies.length === Object.keys(currencies).length

    return false
  }

  function CurrencySettings() {
    return (
      <>
        {(form.getFieldValue('currencies') && form.getFieldValue('currencies').length > 0) || showCurrencyTitle ? (
          <Space size={220}>
            <span>{t('Currency')}</span>
            <span>{t('Valid Turnover per draw chance')}</span>
          </Space>
        ) : (
          ''
        )}
        <Form.List
          name="currencies"
          rules={[
            {
              validator: async (_, currency) => {
                if (!currency || currency.length <= 0) {
                  return Promise.reject(new Error(t('Must add at least 1 currency')))
                }
              }
            }
          ]}
        >
          {(fields, { add, remove }, { errors }) => {
            return (
              <div style={{ height: '90%', overflow: 'auto' }}>
                {fields.map((field, index) => (
                  <Space key={field.key} style={{ display: 'flex' }} align="start">
                    <Form.Item {...field} name={[field.name, 'currency']} fieldKey={[field.fieldKey, 'currency']} 
                      rules={[
                        {
                          required: true,
                          validator: async (_, currency) => {
                            if (!currency) {
                              return Promise.reject(new Error(t('Currency is required')))
                            }
                            var selectedCurrencies = form.getFieldValue('currencies')
                            var hasSelectedBefore = selectedCurrencies
                            .filter(x => x !== undefined)
                            .map(x => x.currency)
                            .filter(x => { return x.toLowerCase() === currency.toLowerCase()}).length > 1
                            if(hasSelectedBefore){
                              return Promise.reject(new Error(t('Currency must be unique')))
                            }
                          }
                        }
                      ]}
                    >
                      <Select placeholder={t('Select Currency')} style={{ width: '250px' }} disabled={isPromotionStarted}>
                        {Object.entries(currencies).map(([key, value]) => (
                          <Option value={key} key={key}>
                            {t(value)}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'stake']}
                      fieldKey={[field.fieldKey, 'stake']}
                      rules={[
                        {
                          required: true,
                          validator: async (_, stake) => {
                            if (!stake) {
                              return Promise.reject(new Error(t('Stake cannot be empty')))
                            }
                            if (stake < 0) {
                              return Promise.reject(new Error(t('Valid Turn Over must be more than 0')))
                            }
                          }
                        }
                      ]}
                    >
                      <InputNumber placeholder={t('Stake Per Draw Chance')} style={{ width: '250px' }} disabled={isPromotionStarted} />
                    </Form.Item>
                    <Button
                      type="link"
                      disabled={isPromotionStarted}
                      icon={<MinusCircleOutlined style={{ color: 'red' }} />}
                      onClick={() => {
                        remove(field.name)
                        setShowCurrencyTitle(false)
                      }}
                    ></Button>
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    disabled={isPromotionStarted || IsCurrencyFullySelected()}
                    onClick={() => {
                      add()
                      setShowCurrencyTitle(true)
                    }}
                    style={{ width: '510px' }}
                  >
                    <PlusOutlined /> {t('Add Currency')}
                  </Button>
                </Form.Item>
                <Form.ErrorList errors={errors} />
              </div>
            )
          }}
        </Form.List>
      </>
    )
  }

  const columns = [
    {
      title: t('Odds Type'),
      dataIndex: 'oddsKind',
      key: 'oddsKind',
      render: (oddsKind) => <>{t(oddsKind)}</>
    },
    {
      title: t('Minimum'),
      dataIndex: 'min',
      key: 'min'
    },
    {
      title: t('Maximum'),
      dataIndex: 'max',
      key: 'max'
    }
  ]

  function OddsSettings() {
    const labelCol = { flex: '130px' }
    const wrapperCol = { flex: '0 1 400px' }
    return (
      <>
        <Form.Item name="supportedOddTypes" label={t('Odds Type')} rules={[{ required: true, message: t('Must select at least one item') }]} labelCol={labelCol} wrapperCol={wrapperCol}>
          {/* <Select placeholder={t('Select Odds Type')} mode="multiple" disabled={isPromotionStarted} onSelect={(selectedVal) => onSelectAll(selectedVal, { supportedOddTypes: Object.entries(oddTypes).map(([key, value]) => key) })} allowClear>
              <Option value="All">{t('All')}</Option>
              {Object.entries(oddTypes).map(([key, value]) => (
                <Option value={key} key={key}>
                  {t(value)}
                </Option>
              ))}
            </Select> */}
          <Select disabled={true}>
            <Option value="0" key="0">
              {t('All')}
            </Option>
          </Select>
        </Form.Item>
        <Form.Item label={t('Odds Range')} labelCol={labelCol} wrapperCol={wrapperCol}>
          <Form.Item
            name="minHkOdds"
            style={{ display: 'inline-block' }}
            rules={[
              {
                required: true,
                validator: async (_, minOdds) => {
                  if (!minOdds && minimalOddsCheck) {
                    return Promise.reject(new Error(t('This is a mandatory field')))
                  }
                  var maxOdds = form.getFieldValue('maxHkOdds')
                  if (minOdds > maxOdds) {
                    return Promise.reject(new Error(t('Min odds must be lesser than or equal to Max odds')))
                  }
                }
              }
            ]}
          >
            <InputNumber addonBefore="HK" placeholder={t('Minimum')} onChange={onMinOddsChange} min={0} disabled={isPromotionStarted || !(minimalOddsCheck || form.getFieldValue('minHkOdds') || form.getFieldValue('maxHkOdds'))} />
          </Form.Item>
          <label style={{ display: 'inline-block', textAlign: 'center', padding: '0 16px' }}>~</label>
          <Form.Item
            name="maxHkOdds"
            style={{ display: 'inline-block', paddingRight: '32px' }}
            rules={[
              {
                required: true,
                validator: async (_, maxOdds) => {
                  if (!maxOdds && minimalOddsCheck) {
                    return Promise.reject(new Error(t('This is a mandatory field')))
                  }
                  var minOdds = form.getFieldValue('minHkOdds')
                  if (minOdds > maxOdds) {
                    return Promise.reject(new Error(t('Max odds must be greater than or equal to Min odds')))
                  }
                }
              }
            ]}
          >
            <InputNumber placeholder={t('Maximum')} onChange={onMaxOddsChange} min={0} disabled={isPromotionStarted || !(minimalOddsCheck || form.getFieldValue('maxHkOdds') || form.getFieldValue('mixHkOdds'))} />
          </Form.Item>
          <Form.Item name="isMinimalOddsAllow" style={{ display: 'inline-block' }}>
            <Checkbox onChange={onMinimalOddsChanged} checked={minimalOddsCheck || form.getFieldValue('minHkOdds') || form.getFieldValue('maxHkOdds')} disabled={isPromotionStarted}>
              {t('Minimal Odds')}
            </Checkbox>
          </Form.Item>
        </Form.Item>
        <Row>
          <Col flex="0 1 530px">
            <Table columns={columns} dataSource={oddsTable} size="small" pagination={false} />
          </Col>
        </Row>
      </>
    )
  }

  function BetSettings() {
    const labelCol = { flex: '160px' }
    const wrapperCol = { flex: '0 1 400px' }
    return (
      <>
        <Form.Item name="memberCategories" label={t('Member Categories')} rules={[{ required: true, message: t('Must select at least one item') }]} labelCol={labelCol} wrapperCol={wrapperCol}>
          <Select
            placeholder={t('Select Member Categories')}
            mode="multiple"
            disabled={isPromotionStarted}
            onSelect={(selectedVal) => onSelectAll(selectedVal, { memberCategories: Object.entries(memberCategories).map(([key, value]) => key) })}
            allowClear
          >
            <Option value="All">{t('All')}</Option>
            {Object.entries(memberCategories)
              .sort(([, a], [, b]) => a.localeCompare(b))
              .map(([key, value]) => (
                <Option value={key} key={key}>
                  {t(value)}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item name="markets" label={t('Markets')} rules={[{ required: true, message: t('Must select at least one item') }]} labelCol={labelCol} wrapperCol={wrapperCol}>
          {/* Phase2 */}
          {/* <Select placeholder={t('Markets')} mode="multiple" disabled={isPromotionStarted} 
            onSelect={(selectedVal) => onSelectAll(selectedVal, { markets: Object.entries(markets).map(([key, value]) => (key)) })} allowClear>
              <Option value="All">{t('All')}</Option>
              {
                  Object.entries(markets).map(([key, value]) => (
                  <Option value={key} key={key}>
                      {t(value)}
                  </Option>))
              }
            </Select> */}
          <Select disabled={true}>
            <Option value="0" key="0">
              {t('All')}
            </Option>
          </Select>
        </Form.Item>

        <Form.Item name="periods" label={t('Periods')} rules={[{ required: true, message: t('Must select at least one item') }]} labelCol={labelCol} wrapperCol={wrapperCol}>
          {/* <Select placeholder={t('Select Periods')} mode="multiple" disabled={isPromotionStarted} 
            onSelect={(selectedVal) => onSelectAll(selectedVal, { periods: Object.entries(periods).map(([key, value]) => (key)) })} allowClear>
              <Option value="All">{t('All')}</Option>
              {
                  Object.entries(periods).map(([key, value]) => (
                  <Option value={key} key={key}>
                      {t(value)}
                  </Option>))
              }
            </Select> */}
          <Select disabled={true}>
            <Option value="0" key="0">
              {t('All')}
            </Option>
          </Select>
        </Form.Item>

        <Form.Item name="betTypes" label={t('Bet Types')} rules={[{ required: true, message: t('Must select at least one item') }]} labelCol={labelCol} wrapperCol={wrapperCol}>
          {/* <Select placeholder={t('Select Bet Types')} mode="multiple" disabled={isPromotionStarted} 
            onSelect={(selectedVal) => onSelectAll(selectedVal, { betTypes: Object.entries(betTypes).map(([key, value]) => (key)) })} allowClear>
              <Option value="All">{t('All')}</Option>
              {
                  Object.entries(betTypes).map(([key, value]) => (
                  <Option value={key} key={key}>
                      {t(value)}
                  </Option>))
              }
            </Select> */}
          <Select disabled={true}>
            <Option value="0" key="0">
              {t('All')}
            </Option>
          </Select>
        </Form.Item>

        <Form.Item name="wagerTypes" label={t('Wager Types')} rules={[{ required: true, message: t('Must select at least one item') }]} labelCol={labelCol} wrapperCol={wrapperCol}>
          {/* <Select placeholder={t('Select Wager Types')} mode="multiple" disabled={isPromotionStarted} 
            onSelect={(selectedVal) => onSelectAll(selectedVal, { wagerTypes: Object.entries(wagerTypes).map(([key, value]) => (key)) })} allowClear>
              <Option value="All">{t('All')}</Option>
              {
                  Object.entries(wagerTypes).map(([key, value]) => (
                  <Option value={key} key={key}>
                      {t(value)}
                  </Option>))
              }
            </Select> */}
          <Select disabled={true}>
            {Object.entries(wagerTypes).map(([key, value]) => (
              <Option value={key} key={key}>
                {t(value)}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="wagerSources" label={t('Wager Sources')} rules={[{ required: true, message: t('Must select at least one item') }]} labelCol={labelCol} wrapperCol={wrapperCol}>
          {/* <Select placeholder={t('Select Wager Sources')} mode="multiple" disabled={isPromotionStarted} onSelect={(selectedVal) => onSelectAll(selectedVal, { wagerSources: Object.entries(wagerSources).map(([key, value]) => key) })} allowClear>
              <Option value="All">{t('All')}</Option>
              {Object.entries(wagerSources).map(([key, value]) => (
                <Option value={key} key={key}>
                  {t(value)}
                </Option>
              ))}
            </Select> */}
          <Select disabled={true}>
            <Option value="0" key="0">
              {t('All')}
            </Option>
          </Select>
        </Form.Item>
      </>
    )
  }

  function IntegerChecker(intVal) {
    if (intVal) {
      var parsedInt = parseInt(intVal)
      if (Number.isInteger(parsedInt)) {
        return parsedInt
      }
    }
    return ''
  }

  function RewardPoint() {
    const labelCol = { flex: '100px' }
    const wrapperCol = { flex: '0 1 120px' }
    const colFlex = '0 1 220px'
    return (
      <>
        <Row>
          <Col flex={colFlex}>
            <Form.Item
              name="firstPrizeRewardPoint"
              label={t('1st Prize')}
              labelCol={labelCol}
              wrapperCol={wrapperCol}
              rules={[
                {
                  required: true,
                  validator: async (_, firstPrize) => {
                    if (!firstPrize) {
                      return Promise.reject(new Error(t('This is a mandatory field')))
                    }
                    var values = form.getFieldsValue()
                    if (values.secondPrizeRewardPoint >= firstPrize || values.thirdPrizeRewardPoint >= firstPrize || values.specialPrizeRewardPoint >= firstPrize || values.consolationPrizeRewardPoint >= firstPrize) {
                      return Promise.reject(new Error(t('1st Prize must be greater than 2nd, 3rd, Special & Consolation')))
                    }
                  }
                }
              ]}
            >
              <InputNumber min={0} parser={(value) => IntegerChecker(value)} disabled={isPromotionStarted}></InputNumber>
            </Form.Item>
          </Col>
          <Col flex={colFlex}>
            <Form.Item
              name="secondPrizeRewardPoint"
              label={t('2nd Prize')}
              labelCol={labelCol}
              wrapperCol={wrapperCol}
              rules={[
                {
                  required: true,
                  validator: async (_, secondPrize) => {
                    if (!secondPrize) {
                      return Promise.reject(new Error(t('This is a mandatory field')))
                    }
                    var values = form.getFieldsValue()
                    if (secondPrize >= values.firstPrizeRewardPoint) {
                      return Promise.reject(new Error(t('2nd Prize must be lesser than 1st Prize')))
                    }
                    if (values.thirdPrizeRewardPoint >= secondPrize || values.specialPrizeRewardPoint >= secondPrize || values.consolationPrizeRewardPoint >= secondPrize) {
                      return Promise.reject(new Error(t('2nd Prize must be greater than 3rd, Special & Consolation')))
                    }
                  }
                }
              ]}
            >
              <InputNumber min={0} parser={(value) => IntegerChecker(value)} disabled={isPromotionStarted}></InputNumber>
            </Form.Item>
          </Col>
          <Col flex={colFlex}>
            <Form.Item
              name="thirdPrizeRewardPoint"
              label={t('3rd Prize')}
              labelCol={labelCol}
              wrapperCol={wrapperCol}
              rules={[
                {
                  required: true,
                  validator: async (_, thirdPrize) => {
                    if (!thirdPrize) {
                      return Promise.reject(new Error(t('This is a mandatory field')))
                    }
                    var values = form.getFieldsValue()
                    if (thirdPrize >= values.firstPrizeRewardPoint || thirdPrize >= values.secondPrizeRewardPoint) {
                      return Promise.reject(new Error(t('3rd Prize must be lesser than 1st Prize & 2nd Prize')))
                    }
                    if (values.specialPrizeRewardPoint >= thirdPrize || values.consolationPrizeRewardPoint >= thirdPrize) {
                      return Promise.reject(new Error(t('3rd Prize must be greater than Special & Consolation')))
                    }
                  }
                }
              ]}
            >
              <InputNumber min={0} parser={(value) => IntegerChecker(value)} disabled={isPromotionStarted}></InputNumber>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col flex={colFlex}>
            <Form.Item name="specialPrizeRewardPoint" label={t('Special')} labelCol={labelCol} wrapperCol={wrapperCol}>
              <InputNumber min={0} parser={(value) => IntegerChecker(value)} disabled={isPromotionStarted}></InputNumber>
            </Form.Item>
          </Col>{' '}
          <Col flex={colFlex}>
            <Form.Item name="consolationPrizeRewardPoint" label={t('Consolation')} labelCol={labelCol} wrapperCol={wrapperCol}>
              <InputNumber min={0} parser={(value) => IntegerChecker(value)} disabled={isPromotionStarted}></InputNumber>
            </Form.Item>
          </Col>{' '}
        </Row>
      </>
    )
  }

  return (
    <div className="rule-container">
      <Tabs type="card">
        <TabPane
          tab={
            <span>
              {' '}
              {currencyHasError ? <WarningTwoTone twoToneColor="red" /> : ''} {t('Stake Settings')}{' '}
            </span>
          }
          key="currency"
        >
          {CurrencySettings()}
        </TabPane>
        <TabPane
          tab={
            <span>
              {' '}
              {oddsHasError ? <WarningTwoTone twoToneColor="red" /> : ''} {t('Odds Settings')}{' '}
            </span>
          }
          key="odds"
          forceRender="true"
        >
          {OddsSettings()}
        </TabPane>
        <TabPane
          tab={
            <span>
              {' '}
              {betHasError ? <WarningTwoTone twoToneColor="red" /> : ''} {t('Bet Settings')}{' '}
            </span>
          }
          key="additional"
          forceRender="true"
        >
          {BetSettings()}
        </TabPane>
        <TabPane
          tab={
            <span>
              {' '}
              {rewardPointHasError ? <WarningTwoTone twoToneColor="red" /> : ''} {t('Reward Point')}{' '}
            </span>
          }
          key="rewardPoint"
          forceRender="true"
        >
          {RewardPoint()}
        </TabPane>
      </Tabs>
    </div>
  )
}
