import React, { useState, useEffect } from 'react'
import { Descriptions, Image, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import './DailyMissionSummaryForm.css'
import { DailyMissionService, PageOptionsService } from '../../services'

export const DailyMissionSummaryForm = ({ promoDailyMission, companies, sportCompetitionList }) => {
    const { t, i18n } = useTranslation()
    const fontStyle = { fontSize: '13px' }
    const [enTnc, setEnTnc] = useState(null)
    const [cnTnc, setCnTnc] = useState(null)
    const [thTnc, setThTnc] = useState(null)
    const [vnTnc, setVnTnc] = useState(null)

    const companyId = Array.isArray(promoDailyMission[0].company) ? promoDailyMission[0].company[0] : promoDailyMission[0].company
    const company = Object.keys(companies).find(key => companies[key] === companyId);
    const startDate = new Date(promoDailyMission[0].promoDateRange[0])
    const endDate = new Date(promoDailyMission[0].promoDateRange[1])
    const timeZone = promoDailyMission[0].promoTimeZone?.value === undefined ? promoDailyMission[0].promoTimeZone : promoDailyMission[0].promoTimeZone?.value
    const gmt = timeZone === '+00:00' ? 'UTC' : `GMT${timeZone}`;

    async function mapCompetitionByLanguage(language) {
        const names = []
        const groupedCompetitions = Object.groupBy(sportCompetitionList, ({ sportId }) => sportId);
        for (const [sId, competitions] of Object.entries(groupedCompetitions)) {
            let sportCompetitionByLang = await PageOptionsService.getPageOptionsCompetitionsBySport(sId, language)
            Object.entries(competitions).forEach(([key, obj]) => {
                Object.entries(sportCompetitionByLang).forEach(([cId, cName]) => {
                    if (parseInt(cId) === obj.competitionId) {
                        names.push(cName)
                    }
                });
            });
        }
        return names
    }

    async function constructDailyMissionTnC(language) {
        var competitionNames = await mapCompetitionByLanguage(language)
        return {
            currency: promoDailyMission[1].currencyTypes,
            minBetAmount: promoDailyMission[1].minBetAmount,
            minimalHkOdds: promoDailyMission[1].minimalOdds,
            language: language,
            competitions: JSON.stringify(competitionNames)
        }
    }

    useEffect(() => {
        const getDailyMission = async () => {
            if (promoDailyMission[0].language.includes('en')) {
                var enTncParam = await constructDailyMissionTnC('en')
                var enTnc = await DailyMissionService.getDailyMissionTermsNConditions(enTncParam).then((data) => { return data })
                setEnTnc(enTnc)
            }

            if (promoDailyMission[0].language.includes('ch')) {
                var cnTncParam = await constructDailyMissionTnC('ch')
                var cnTnc = await DailyMissionService.getDailyMissionTermsNConditions(cnTncParam).then((data) => { return data })
                setCnTnc(cnTnc)
            }

            if(promoDailyMission[0].language.includes('th')) {
                var thTncParam = await constructDailyMissionTnC('th')
                var thTnc = await DailyMissionService.getDailyMissionTermsNConditions(thTncParam).then((data) => { return data })
                setThTnc(thTnc)
            }

            if (promoDailyMission[0].language.includes('vn')) {
                var vnTncParam = await constructDailyMissionTnC('vn')
                var vnTnc = await DailyMissionService.getDailyMissionTermsNConditions(vnTncParam).then((data) => { return data })
                setVnTnc(vnTnc)
            }
        }

        getDailyMission()

    }, []) 

    return (
        <div>
            <Descriptions
                bordered
                title={t("General Information")}
                size="small"
                column={1} style={{ margin: 8 }}
                labelStyle={{ width: 250 }}>
                <Descriptions.Item label={t("Promotion Name EN")}>{promoDailyMission[0].nameEn ?? ''}</Descriptions.Item>
                <Descriptions.Item label={t("Promotion Name CN")}>{promoDailyMission[0].nameCn ?? ''}</Descriptions.Item>
                <Descriptions.Item label={t("Promotion Name TH")}>{promoDailyMission[0].nameTh ?? ''}</Descriptions.Item>
                <Descriptions.Item label={t("Promotion Name VN")}>{promoDailyMission[0].nameVn ?? ''}</Descriptions.Item>
                <Descriptions.Item label={t("Promotion Tagline EN")}>{promoDailyMission[0].taglineEn ?? ''}</Descriptions.Item>
                <Descriptions.Item label={t("Promotion Tagline CN")}>{promoDailyMission[0].taglineCn ?? ''}</Descriptions.Item>
                <Descriptions.Item label={t("Promotion Tagline TH")}>{promoDailyMission[0].taglineTh ?? ''}</Descriptions.Item>
                <Descriptions.Item label={t("Promotion Tagline VN")}>{promoDailyMission[0].taglineVn ?? ''}</Descriptions.Item>
                <Descriptions.Item label={t("Promotion Description EN")}><pre>{promoDailyMission[0].descriptionEn ?? ''}</pre></Descriptions.Item>
                <Descriptions.Item label={t("Promotion Description CN")}><pre>{promoDailyMission[0].descriptionCn ?? ''}</pre></Descriptions.Item>
                <Descriptions.Item label={t("Promotion Description TH")}><pre>{promoDailyMission[0].descriptionTh ?? ''}</pre></Descriptions.Item>
                <Descriptions.Item label={t("Promotion Description VN")}><pre>{promoDailyMission[0].descriptionVn ?? ''}</pre></Descriptions.Item>
                <Descriptions.Item label={t("Promotion Banner EN")}>
                    <Image
                        width={200}
                        src={promoDailyMission[0].promoBannerEn === undefined ? '' : promoDailyMission[0].promoBannerEn[0]?.thumbUrl ?? ''}
                    />
                </Descriptions.Item>
                <Descriptions.Item label={t("Promotion Banner CN")}>
                    <Image
                        width={200}
                        src={promoDailyMission[0].promoBannerCn === undefined ? '' : promoDailyMission[0].promoBannerCn[0]?.thumbUrl ?? ''}
                    />
                </Descriptions.Item>
                <Descriptions.Item label={t("Promotion Banner TH")}>
                    <Image
                        width={200}
                        src={promoDailyMission[0].promoBannerTh === undefined ? '' : promoDailyMission[0].promoBannerTh[0]?.thumbUrl ?? ''}
                    />
                </Descriptions.Item>
                <Descriptions.Item label={t("Promotion Banner VN")}>
                    <Image
                        width={200}
                        src={promoDailyMission[0].promoBannerVn === undefined ? '' : promoDailyMission[0].promoBannerVn[0]?.thumbUrl ?? ''}
                    />
                </Descriptions.Item>
                <Descriptions.Item label={t("Mission Banner EN")}>
                    <Image
                        width={200}
                        src={promoDailyMission[0].missionBannerEn === undefined ? '' : promoDailyMission[0].missionBannerEn[0]?.thumbUrl ?? ''}
                    />
                </Descriptions.Item>
                <Descriptions.Item label={t("Mission Banner CN")}>
                    <Image
                        width={200}
                        src={promoDailyMission[0].missionBannerCn === undefined ? '' : promoDailyMission[0].missionBannerCn[0]?.thumbUrl ?? ''}
                    />
                </Descriptions.Item>
                <Descriptions.Item label={t("Mission Banner TH")}>
                    <Image
                        width={200}
                        src={promoDailyMission[0].missionBannerTh === undefined ? '' : promoDailyMission[0].missionBannerTh[0]?.thumbUrl ?? ''}
                    />
                </Descriptions.Item>
                <Descriptions.Item label={t("Mission Banner VN")}>
                    <Image
                        width={200}
                        src={promoDailyMission[0].missionBannerVn === undefined ? '' : promoDailyMission[0].missionBannerVn[0]?.thumbUrl ?? ''}
                    />
                </Descriptions.Item>
                <Descriptions.Item label={t("Company")}>{company ?? ''}</Descriptions.Item>
                <Descriptions.Item label={t("Promotion Status")}>{t(promoDailyMission[0].status) ?? ''}</Descriptions.Item>
                <Descriptions.Item label={t("Promotion Start")}>{startDate.toLocaleDateString()}</Descriptions.Item>
                <Descriptions.Item label={t("Promotion End")}>{endDate.toLocaleDateString()}</Descriptions.Item>
                <Descriptions.Item label={t("Timezone")}>{gmt}</Descriptions.Item>
                <Descriptions.Item label={t("Terms n Conditions EN")}><pre style={{textWrap: 'wrap'}}>{enTnc}</pre></Descriptions.Item>
                <Descriptions.Item label={t("Terms n Conditions CN")}><pre style={{ textWrap: 'wrap' }}>{cnTnc}</pre></Descriptions.Item>
                <Descriptions.Item label={t("Terms n Conditions TH")}><pre style={{ textWrap: 'wrap' }}>{thTnc}</pre></Descriptions.Item>
                <Descriptions.Item label={t("Terms n Conditions VN")}><pre style={{ textWrap: 'wrap' }}>{vnTnc}</pre></Descriptions.Item>
                <Descriptions.Item label={t("Remarks")}><pre>{promoDailyMission[0].remarks ?? ''}</pre></Descriptions.Item>
            </Descriptions>
            <br />
            <Descriptions
                bordered
                title={t("Rules")}
                size="small"
                column={2} style={{ margin: 8 }}
                labelStyle={{ width: 250 }}>
                <Descriptions.Item label={t("Minimum Bet Amount")} column={2}>{promoDailyMission[1].minBetAmount ?? ''}</Descriptions.Item>
                <Descriptions.Item label={t("Free Bet Validity")} column={2}>{promoDailyMission[1].freeBetValidity ?? ''}</Descriptions.Item>
                <Descriptions.Item label={t("Currency")} column={2}>{promoDailyMission[1].currencyTypes?.toUpperCase() ?? ''}</Descriptions.Item>
                <Descriptions.Item label={t("Minimal Odds")} column={2}>{promoDailyMission[1].minimalOdds ?? ''}</Descriptions.Item>
            </Descriptions>
            <br />
            <Descriptions
                bordered
                title={t("Daily Mission")}
                size="small"
                column={1} style={{ margin: 8, fontStyle }}
                labelStyle={{ width: 250 }}>
                <Descriptions.Item label={t("Config Info")}>
                    {t('Total number of mission')}: <Tag color="default">{promoDailyMission[2].dailyMission.length}</Tag>
                    <br />
                    <br />
                    {
                        Array.prototype.map.call(promoDailyMission[2].dailyMission, (item, index) => (
                            < div >
                                {t('Mission')}
                                <Tag color="default">{index + 1}</Tag>: {t('Place')}
                                <Tag color="default">{item.noWager}</Tag>
                                <Tag color="default">{t(item.wagerType)}</Tag> {t('wager')}
                                <Tag color="default">{t((JSON.parse(item.sport?.value === undefined ? item.sport : item.sport?.value))[1])}</Tag>
                                <Tag color="default">{t((JSON.parse(item.betType?.value === undefined ? item.betType : item.betType?.value))[1])}</Tag>
                                <br />
                                {t('Freebet Reward')}:  <Tag color="default">{item.freebetReward ?? ''}</Tag>
                                <br />
                                {t('Freebet Budget')}: <Tag color="default">{item.freebetBudget ?? ''}</Tag>
                                <br />
                                <br />

                            </div>
                        ))
                    }
                </Descriptions.Item>
            </Descriptions>
        </div>
    )
}

