import React, { useState, useEffect } from 'react'
import { Button, notification } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { PageContent } from '../components/core'
import { PrizeItemSearchForm, PrizeItemTable } from '../components'
import { PrizeItemConstants } from '../components/core'
import { PrizeItemInfo } from '.'
import { PriceItemService } from '../services'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

export const PrizeItemsPage = (props) => {
  const { t, i18n } = useTranslation()
  const [tableResult, setTableResult] = useState(null)
  const [pageOptions, setPageOptions] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  var initialSearchCriteria = props.user.companyId ? { status: ['enabled'], company: [props.user.companyId] } : { status: ['enabled'], company: ['0'] }
  const [searchCriteria, setSearchCriteria] = useState(initialSearchCriteria)

  const companies = pageOptions ? pageOptions.companies : {}
  const [updateCompany, setUpdateCompany] = useState(null) // get better approach
  const statuses = PrizeItemConstants.prizeItemStatuses
  const [modalState, setModalState] = useState({ visible: false })

  const onSearch = async (value) => {
    const criteria = {
      id: value.id,
      name: value.name,
      company: Array.isArray(value.company) ? value.company : [value.company],
      status: value.status,
      language: i18n.language
    }
    setSearchCriteria(criteria)
    setIsLoading(true)
    const prizeItems = await PriceItemService.search(criteria)
    setIsLoading(false)
    setTableResult(prizeItems)
  }

  const showModal = (action, prizeItem) => {
    if (action === 'Update') {
      setUpdateCompany(prizeItem.company)
    }
    setModalState({
      visible: true,
      action: action,
      prizeItem: prizeItem
    })
  }

  const handleOk = async (values) => {
    setModalState({
      visible: false,
      action: modalState.action,
      prizeItem: {
        values,
        updatedBy: modalState.prizeItem.updatedBy,
        createdBy: modalState.prizeItem.createdBy
      }
    })

    if (values === undefined) {
      openNotificationWithIcon('error', 'Failed', 'Values are empty.')
    } else {
      if (modalState.action === 'Update') {
        function getKeyByValue(object, value) {
          return Object.keys(object).find((key) => object[key] === value)
        }
        values.companyId = parseInt(getKeyByValue(companies, updateCompany))
        values.company = updateCompany
      } else {
        var companyId = props.user.isBusinessUnit ? values.company : props.user.companyId
        values.companyId = parseInt(companyId)
        values.company = companies[companyId]
      }

      let response
      if (modalState.action === 'Create') {
        response = await PriceItemService.create(values)
      } else {
        if (values.hasPublished) {
          response = await PriceItemService.updateStatus(values.id, values.status)
        } else {
          response = await PriceItemService.update(values)
        }
      }

      if (response?.status === 200) {
        openNotificationWithIcon('success', t('Successful'), '')
        onSearch(searchCriteria)
      } else {
        let message = t(`${modalState.action} fail`)
        openNotificationWithIcon('error', t('Failed'), message)
      }
    }
  }

  const handleCancel = (e) => {
    let action = modalState.action
    let prizeItem = modalState.prizeItem
    setModalState({
      visible: false,
      action: action,
      prizeItem: prizeItem
    })
  }

  const openNotificationWithIcon = (type, title, description) => {
    notification.destroy()
    notification[type]({
      message: title,
      description: description
    })
  }

  useEffect(() => {
    const getPageOptions = async () => {
      var response = await axios.get(`PageOptions/companies?&Language=${i18n.language}`).catch(console.log)
      if (response) {
        return response.data.result
      }
      return null
    }
    const getPrizeItems = async () => {
      setIsLoading(true)
      var prizeItem = await PriceItemService.search(initialSearchCriteria)
      setIsLoading(false)
      setTableResult(prizeItem)

      var options = await getPageOptions()
      setPageOptions(options)
    }

    getPrizeItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const actionButtons = (
    <Button
      type="primary"
      className="btn-darkblue"
      onClick={() => showModal('Create', { status: 'enabled', company: props.user.isBusinessUnit ? '' : companies[props.user.companyId] })}
      icon={<PlusOutlined />}
      style={{ visibility: props.user.permissions.canCreatePrizeItem ? '' : 'hidden' }}
    >
      {t('Create Prize Item')}
    </Button>
  )

  return (
    <PageContent actionButtons={actionButtons}>
      <PrizeItemSearchForm onSearch={onSearch} statuses={statuses ?? []} selectedStatuses={['enabled']} companies={companies} initialSearchCriteria={initialSearchCriteria} user={props.user} />
      <PrizeItemTable dataSource={tableResult} isLoading={isLoading} onRowClick={(data) => showModal('Update', data)} />
      <PrizeItemInfo action={modalState.action} prizeItem={modalState.prizeItem} visible={modalState.visible} handleCancel={handleCancel} handleOk={handleOk} companies={companies} user={props.user} />
    </PageContent>
  )
}
