import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col, Input, DatePicker, Select, Button, Spin } from 'antd'
import { SearchOutlined, ExportOutlined } from '@ant-design/icons'
import { PermissionsService } from '../../services'
import { Constants } from '../../components/core'

const { Option } = Select

export const FreebetQueryForm = ({ form, companies, statuses, initialSearchCriteria, onSearch, onExport, user }) => {
    const { t } = useTranslation()
    const [selectedCompanies, setSelectedCompanies] = useState(user && user.companyId ? [] : ['0'])
    const [loading, setLoading] = useState(false)

    const colProps = { xs: { span: 24 }, md: { span: 12 }, lg: { span: 6 }, xl: { span: 5 } }
    const labelCol = { flex: '130px' }
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
            md: { span: 9 },
            lg: { span: 10 },
            xxl: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
            md: { span: 15 },
            lg: { span: 14 },
            xxl: { span: 16 }
        },
        labelAlign: 'left'
    }

    function onSelect(selectedValue, field) {
        if (selectedValue === 'All') {
            form.setFieldsValue(field)
        }
    }

    function onCompanyChanged(selectedCompanies) {
        if (selectedCompanies && selectedCompanies.includes('0')) {
            var allOption = ['0']
            setSelectedCompanies(allOption)
            form.setFieldsValue({ company: allOption })
        } else {
            setSelectedCompanies(selectedCompanies)
        }
    }

    function tagRender(props) {
        const { label, value, closable, onClose } = props

        if (selectedCompanies && selectedCompanies[0] === value && selectedCompanies[0] !== '0') {
            return <span>{`${selectedCompanies.length} of ${Object.keys(companies).length} selected`}</span>
        }
        return ''
    }

    return (
        <Form {...formItemLayout} form={form} initialValues={initialSearchCriteria}>
            <Row gutter={(8, 8)}>

                <Col {...colProps}>
                    <Form.Item name="promoID" label={t('Promo ID')} labelCol={labelCol}>
                        <Input placeholder={t('Promotion ID')} />
                    </Form.Item>
                </Col>

                <Col {...colProps}>
                    <Form.Item name="promoName" label={t('Promo Name')} labelCol={labelCol}>
                        <Input placeholder={t('Promotion Name')} />
                    </Form.Item>
                </Col>

                <Col {...colProps}>
                    <Form.Item name="company" label={t('Company')} labelCol={labelCol}>
                        {user.isBusinessUnit ? (
                            <Select
                                placeholder={t('Select a Company')}
                                mode="multiple"
                                tagRender={selectedCompanies && selectedCompanies.includes('0') ? '' : tagRender}
                                onChange={onCompanyChanged}
                                disabled={!user.isBusinessUnit}
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input, option) => option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                <Option value="0" key="0" style={{ visibility: Object.keys(companies).length > 0 ? '' : 'hidden' }}>
                                    {t('All')}
                                </Option>
                                {Object.entries(companies).map(([key, value]) => (
                                    <Option value={value} key={key} disabled={selectedCompanies.includes('0')}>
                                       {t(key)}
                                    </Option>
                                ))}
                                <Option value="-1" key="-1" disabled={true} style={{ visibility: Object.keys(companies).length <= 0 ? '' : 'hidden' }}>
                                    {<Spin />}
                                </Option>
                            </Select>
                        ) : (
                            <Select disabled={!user.isBusinessUnit}>
                                {Object.entries(companies).map(([key, value]) => (
                                    <Option value={value} key={key}>
                                        {t(key)}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col {...colProps}>
                    <Form.Item name="membeCode" label={t('Member Code')} labelCol={labelCol}>
                        <Input placeholder={t('Member Code')} />
                    </Form.Item>
                </Col>
              
                <Col flex={'auto'} xl={{ span: 0 }}></Col>
                <Col flex={'none'} xl={{ span: 6 }}>
                    <Form.Item>
                        <Button
                            type="primary"
                            style={{ visibility: PermissionsService.checkPermission(Constants.pageActions.viewFreebetQuery) ? '' : 'hidden' }}
                            loading={loading}
                            onClick={() => {
                                setLoading(true)
                                onSearch()
                                setLoading(false)
                            }}
                            icon={<SearchOutlined />}
                        >
                            {t('Search')}
                        </Button>
                    </Form.Item>
                </Col>

                {/*<Col xs={{ span: 0 }} md={{ span: 0 }} lg={{ span: 0 }} xl={{ span: 6 }}></Col>*/}


                <Col {...colProps}>
                    <Form.Item name="freebetId" label={t('Freebet ID')} labelCol={labelCol}>
                        <Input placeholder={t('Freebet ID')} />
                    </Form.Item>
                </Col>

                <Col {...colProps}>
                    <Form.Item name="status" label={t('Status')} labelCol={labelCol}>
                        <Select placeholder={t('Select a status')} mode="multiple" onSelect={(x) => onSelect(x, { status: Object.entries(statuses).map(([key, value]) => key) })} allowClear>
                            <Option value="All">{t('All')}</Option>
                            {Object.entries(statuses).map(([key, value]) => (
                                <Option value={key} key={key}>
                                    {t(value)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col {...colProps}>
                    <Form.Item name="startDate" label={t('Date From')} labelCol={labelCol} rules={[{ required: true, message: t('This is a mandatory field') }]}>
                        <DatePicker picker="month" />
                    </Form.Item>
                </Col>

                <Col {...colProps}>
                    <Form.Item name="endDate" label={t('Date To')} labelCol={labelCol} rules={[{ required: true, message: t('This is a mandatory field') }]}>
                        <DatePicker picker="month" />
                    </Form.Item>
                </Col>

                <Col flex={'auto'} xl={{ span: 0 }}></Col>
                <Col flex={'none'} xl={{ span: 6 }}>
                    <Form.Item>
                        <Button
                            type="primary"
                            style={{ visibility: PermissionsService.checkPermission(Constants.pageActions.viewFreebetQuery) ? '' : 'hidden' }}
                            loading={loading}
                            onClick={() => {
                                setLoading(true)
                                onExport()
                                setLoading(false)
                            }}
                            icon={<ExportOutlined />}
                        >
                            {t('Export')}
                        </Button>
                    </Form.Item>
                </Col>

            </Row>
        </Form>
    )
}

FreebetQueryForm.propTypes = {
    statuses: PropTypes.object,
    companies: PropTypes.object,
    initialSearchCriteria: PropTypes.object,
    onSearch: PropTypes.func.isRequired
}