// TODO: Convert Utc to GMT-4 - Gilbert [Done]
import axios from 'axios'
import moment from 'moment'

const CancelToken = axios.CancelToken
let canceler

const GetCompanyOptions = async (lang) => {
  var response = await axios.get(`PageOptions/companies?&Language=${lang}`).catch(console.log)
  if (response) {
    return response.data.result
  }
  return null
}

const DownloadTemplate = async (lang, t) => {
  const config = {
    params: {
      Language: lang
    },
    responseType: 'blob'
  }

  await axios
    .get(`LuckyDrawsManagement/DownloadWinnersTemplate`, config)
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      console.log(response)
      link.href = url
      link.setAttribute('download', `${t('Winner Template')}.xlsx`)
      document.body.appendChild(link)
      link.click()
    })
    .catch((response) => {
      console.error('Could not downalod the Excel from the backend.', response)
    })
}

const GetActivePromotions = async (companyId, lang) => {
  var config = {
    params: {
      CompanyId: companyId,
      Language: lang
    }
  }
  var response = await axios.get(`LuckyDrawsManagement/ActivePromotions`, config)
  if (response) {
    return response.data.result
  }
  return null
}

const GetParticipantList = async (searchCriteria) => {
  var config = {
    params: {
      Company: searchCriteria.company,
      PromotionId: searchCriteria.promoName,
      SettlementDate: moment(searchCriteria.settlement).format('yyyy-MM-DD')
    }
  }

  var resp = await axios.get(`LuckyDrawsManagement/ListParticipants`, config)

  if (resp) {
    return resp.data.result
  }
  return null
}

const GetParticipantHistory = async (searchCriteria) => {
  if (searchCriteria && searchCriteria.settlement && searchCriteria.promoName) {
    var config = {
      params: {
        Company: searchCriteria.company,
        PromotionId: searchCriteria.promoName,
        SettlementDateFrom: moment(searchCriteria.settlement[0]).format('yyyy-MM-DD'),
        SettlementDate: moment(searchCriteria.settlement[1]).format('yyyy-MM-DD')
      }
    }

    var resp = await axios.get(`LuckyDrawsManagement/ListParticipants`, config)

    if (resp) {
      return resp.data.result
    }
    return null
  }
}

const GetWinnerList = async (searchCriteria, winnerType, lang) => {
  var config = {
    params: {
      PromotionId: searchCriteria.promoName,
      SettlementDate: moment(searchCriteria.settlement).format('yyyy-MM-DD'),
      WinnerType: winnerType,
      Language: lang
    }
  }

  var resp = await axios.get(`LuckyDrawsManagement/ListWinners`, config)

  if (resp) {
    return resp.data.result
  }
  return null
}

const DownloadWinners = async (promotionId, settlementDate, winnerType, lang, t) => {
  const config = {
    params: {
      PromotionId: promotionId,
      SettlementDate: moment(settlementDate).format('yyyy-MM-DD'),
      WinnerType: winnerType,
      Language: lang
    },
    responseType: 'blob'
  }
  await axios
    .get(`LuckyDrawsManagement/ExportWinners`, config)
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      console.log(response)
      link.href = url
      link.setAttribute('download', `${moment(settlementDate).format('yyyyMMDD')}_${t(winnerType)}_${t('Winner List')}.xlsx`)
      document.body.appendChild(link)
      link.click()
    })
    .catch(console.log)
}

const ExportWinnersReport = async (promotionId, promoName, startDate, endDate, lang, t, winnerType) => {
  const config = {
    params: {
      PromotionId: promotionId,
      SettlementDateFrom: moment(startDate).format('yyyy-MM-DD'),
      SettlementDate: moment(endDate).format('yyyy-MM-DD'),
      Language: lang,
      WinnerType: winnerType
    },
    responseType: 'blob',
    cancelToken: new CancelToken((c) => (canceler = c))
  }

  await axios
    .get(`LuckyDrawsManagement/ExportWinnersReport`, config)
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      console.log(response)
      link.href = url
      link.setAttribute('download', `${promoName}_${t('Winners')}_${moment(startDate).format('yyyyMMDD')}_${moment(endDate).format('yyyyMMDD')}.xlsx`)
      document.body.appendChild(link)
      link.click()
    })
    .catch(console.log)
}

const ExportParticipantsReport = async (companyId, promotionId, promoName, startDate, endDate, lang, t) => {
  const config = {
    params: {
      CompanyId: companyId,
      PromotionId: promotionId,
      SettlementDateFrom: startDate ? moment(startDate).format('yyyy-MM-DD') : null,
      SettlementDate: moment(endDate).format('yyyy-MM-DD'),
      Language: lang
    },
    responseType: 'blob',
    cancelToken: new CancelToken((c) => (canceler = c))
  }

  await axios
    .get(`LuckyDrawsManagement/ExportParticipantsReport`, config)
    .then((response) => {
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        console.log(response)
        link.href = url
        link.setAttribute('download', `${promoName}_${t('Participants')}_${moment(startDate || endDate).format('yyyyMMDD')}_${moment(endDate).format('yyyyMMDD')}.xlsx`)
        document.body.appendChild(link)
        link.click()
      }
    })
    .catch(console.log)
}

const UpdateLuckyDrawStatus = async (promotionId, settlementDate, winnerType, status) => {
  const config = {
    params: {
      PromotionId: promotionId,
      SettlementDate: moment(settlementDate).format('yyyy-MM-DD'),
      WinnerType: winnerType,
      UpdateStatus: status
    }
  }

  var resp = await axios.put(`LuckyDrawsManagement/UpdateLuckyDrawsStatus`, null, config)

  if (resp) {
    return resp.data.result
  }
  return null
}

const UpdatePrizeItemToPublished = async (promotionId, settlementDate, winnerType) => {
  const config = {
    params: {
      PromotionId: promotionId,
      SettlementDate: moment(settlementDate).format('yyyy-MM-DD'),
      WinnerType: winnerType
    }
  }

  var resp = await axios.put(`LuckyDrawsManagement/UpdatePrizeItemToPublished`, null, config)

  if (resp) {
    return resp.data.result
  }
  return null
}

export const LuckyDrawService = {
  GetCompanyOptions,
  DownloadTemplate,
  GetActivePromotions,
  GetParticipantList,
  GetParticipantHistory,
  GetWinnerList,
  UpdateLuckyDrawStatus,
  UpdatePrizeItemToPublished,
  DownloadWinners,
  ExportWinnersReport,
  ExportParticipantsReport,
  cancel: () => canceler()
}
