import axios from 'axios'

const getMonitoringData = async () => {
    const response = await axios.get(`DataMonitoring/monitoringData`).catch(console.log)
    return response?.data?.result
}

const getMonitoringDataByType = async (dataType, param) => {
    const response = await axios.get(`DataMonitoring/queryData?dataType=${dataType}&param=${param}`).catch(console.log)
    return response?.data?.result
}

export const UtilityService = {
    getMonitoringData,
    getMonitoringDataByType,
}