import './LoginForm.css'

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Button, Alert } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

export const LoginForm = ({ onSubmit, ...rest }) => {
    const { t, i18n } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const onFinish = async (values) => {
    setLoading(true)
    var succeeded = await onSubmit(values)
    if (!succeeded) {
      setLoading(false)
      setError(true)
    }
  }

  const handleClose = () => {
    setError(false)
  }

  return (
    <Form {...rest} name="normal_login" className="login-form" initialValues={{ remember: true }} onFinish={onFinish}>
      <Form.Item name="username" rules={[{ required: true, message: 'Please input your User Name!' }]}>
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="User Name" />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
        <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" autoComplete="off" />
      </Form.Item>
      {error && (
        <Form.Item>
          <Alert message="Invalid user name or password" type="error" banner closable afterClose={handleClose} />
        </Form.Item>
      )}
      <Form.Item>
        <Button type="primary" loading={loading} htmlType="submit" className="login-form-button">
          {t('Login')}
        </Button>
      </Form.Item>
    </Form>
  )
}

LoginForm.propTypes = {
  /** Event on Log in button click */
  onSubmit: PropTypes.func
}
