// TODO: Convert Utc to GMT-4 - Gilbert [Done]
import React from 'react'
import { Form, Row, Col, Input, DatePicker, Select, Radio } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import './generalInfoForm.css'
import { Constants } from './core'

export const GeneralInfoForm = ({ companies, isUpdateMode, isPromotionStarted, user }) => {
  const { i18n, t } = useTranslation()
  const { Option } = Select
  const { TextArea } = Input
  const { RangePicker } = DatePicker

  const labelCol = { flex: '130px' }
  const wrapperCol = { flex: '0 1 450px' }
  const nameWrapperCol = { flex: '0 1 458px' }

  function disabledDate(current) {
    let customDate = new Date()
    customDate.setDate(customDate.getDate() - 1)
    return current < moment(customDate, 'YYYY-MM-DD')
  }

  let pickerStart = new Date()
  pickerStart.setDate(pickerStart.getDate() + 1)
  let pickerEnd = new Date()
  pickerEnd.setDate(pickerEnd.getDate() + 30)

  return (
    <Row gutter={[8, 0]} className={`form-${i18n.language}`}>
      <Col span={24}>
        <Form.Item label={t('Promo Name')} labelCol={labelCol} wrapperCol={{ flex: '1 1 450px' }} className="mb-0" style={{ maxWidth: '1200px' }}>
          <Row gutter={[8, 0]}>
            <Col {...nameWrapperCol}>
              <Form.Item name="nameEn" rules={[{ required: true, message: t('This is a mandatory field') }]}>
                <Input addonBefore={t('EN')} placeholder={t('Promotion name EN')} disabled={isPromotionStarted} />
              </Form.Item>
            </Col>
            <Col {...nameWrapperCol}>
              <Form.Item name="nameCn" rules={[{ required: true, message: t('This is a mandatory field') }]}>
                <Input addonBefore={t('CN')} placeholder={t('Promotion name CN')} disabled={isPromotionStarted} />
              </Form.Item>
            </Col>
            <Col {...nameWrapperCol}>
              <Form.Item name="nameTh" rules={[{ required: true, message: t('This is a mandatory field') }]}>
                <Input addonBefore={t('TH')} placeholder={t('Promotion name TH')} disabled={isPromotionStarted} />
              </Form.Item>
            </Col>
            <Col {...nameWrapperCol}>
              <Form.Item name="nameVi" rules={[{ required: true, message: t('This is a mandatory field') }]}>
                <Input addonBefore={t('VN')} placeholder={t('Promotion name VN')} disabled={isPromotionStarted} />
              </Form.Item>
            </Col>
            <Col {...nameWrapperCol}>
              <Form.Item name="nameId" rules={[{ required: true, message: t('This is a mandatory field') }]}>
                <Input addonBefore={t('IDN')} placeholder={t('Promotion name ID')} disabled={isPromotionStarted} />
              </Form.Item>
            </Col>
            <Col {...nameWrapperCol}>
              <Form.Item name="nameEs" rules={[{ required: true, message: t('This is a mandatory field') }]}>
                <Input addonBefore={t('ES')} placeholder={t('Promotion name ES')} disabled={isPromotionStarted} />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item name="category" label={t('Promo Category')} labelCol={labelCol} wrapperCol={wrapperCol}>
          <Select placeholder={t('Select a category')} disabled={isUpdateMode}>
            {Object.entries(Constants.categories).map(([key, value]) => (
              <Option value={key} key={key}>
                {t(value)}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item name="company" label={t('Company')} labelCol={labelCol} wrapperCol={wrapperCol}>
          <Select placeholder={t('Select a company')} disabled={isUpdateMode || !user.isBusinessUnit}>
            {Object.entries(companies).map(([key, value]) => (
              <Option value={key} key={key}>
                {t(value)}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item name="status" label={t('Promo Status')} labelCol={labelCol} wrapperCol={wrapperCol}>
          <Select placeholder={t('Select a status')}>
            {Object.entries(Constants.statuses).map(([key, value]) => (
              <Option value={key} key={key}>
                {t(value)}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item name="promoDateRange" label={t('Promo Date')} labelCol={labelCol} wrapperCol={wrapperCol}>
          <RangePicker disabledDate={disabledDate} disabled={isPromotionStarted} />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item name="allowGrandPrize" label={t('Allow Grand Prize')} labelCol={labelCol} wrapperCol={wrapperCol}>
          <Radio.Group disabled={isPromotionStarted}>
            <Radio value="no">{t('No')}</Radio>
            <Radio value="yes">{t('Yes')}</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="remarks" label={t('Remarks')} labelCol={labelCol} wrapperCol={wrapperCol}>
          <TextArea rows={4} />
        </Form.Item>
      </Col>
    </Row>
  )
}
