import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col, Select, Button, DatePicker, Spin, Modal } from 'antd'
import { SearchOutlined, ExportOutlined, LoadingOutlined } from '@ant-design/icons'
import { LuckyDrawService } from '../services'

export const LuckyDrawHistorySearchForm = ({ onSearch, onCompanySelect, companies, promotions, user, allowExport }) => {
  const { Option } = Select
  const { t, i18n } = useTranslation()
  const [form] = Form.useForm()
  const [loadingStates, setLoadingStates] = useState([false, false])
  const [loading, setLoading] = useState(false)
  const { RangePicker } = DatePicker
  const { info } = Modal

  var initialValues = {
    company: user.companyId
  }

  const formItemLayout = {
    labelCol: { flex: '140px' },
    labelAlign: 'left'
  }

  const downoadingModalConfig = {
    icon: <LoadingOutlined />,
    title: 'Downloading...',
    okText: 'Cancel',
    onOk: () => LuckyDrawService.cancel()
  }

  function onExportWinners() {
    ;(async () => {
      let statesBefore = [...loadingStates]
      statesBefore[0] = true
      setLoadingStates(statesBefore)
      const modal = info(downoadingModalConfig)

      var promoId = form.getFieldValue('promoName')
      await LuckyDrawService.ExportWinnersReport(promoId, promotions[promoId].promoName, form.getFieldValue('settlement')[0], form.getFieldValue('settlement')[1], i18n.language, t)

      modal.destroy()

      let statesAfter = [...loadingStates]
      statesAfter[0] = false
      setLoadingStates(statesAfter)
    })()
  }

  function onExportParticipants() {
    ;(async () => {
      let statesBefore = [...loadingStates]
      statesBefore[1] = true
      setLoadingStates(statesBefore)
      const modal = info(downoadingModalConfig)

      var promoId = form.getFieldValue('promoName')
      var companyId = form.getFieldValue('company')
      await LuckyDrawService.ExportParticipantsReport(companyId, promoId, promotions[promoId].promoName, form.getFieldValue('settlement')[0], form.getFieldValue('settlement')[1], i18n.language, t)

      modal.destroy()

      let statesAfter = [...loadingStates]
      statesAfter[1] = false
      setLoadingStates(statesAfter)
    })()
  }

  return (
    <Form {...formItemLayout} form={form} initialValues={initialValues}>
      <Row gutter={[8, 4]}>
        <Col flex="0 1 1000px">
          <Row gutter={[8, 4]}>
            <Col flex="0 1 500px">
              <Form.Item name="company" label={t('Company')} rules={[{ required: true, message: t('This is a mandatory field') }]}>
                {user.isBusinessUnit ? (
                  <Select placeholder={t('Select a Company')} showSearch optionFilterProp="children" onSelect={onCompanySelect} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} allowClear>
                    {Object.entries(companies).map(([key, value]) => (
                      <Option value={key} key={key}>
                        {t(value)}
                      </Option>
                    ))}
                    {!companies || Object.keys(companies).length <= 0 ? (
                      <Option disabled={true}>
                        <Spin />
                      </Option>
                    ) : (
                      ''
                    )}
                  </Select>
                ) : (
                  <Select disabled={!user.isBusinessUnit}>
                    {Object.entries(companies).map(([key, value]) => (
                      <Option value={key} key={key}>
                        {t(value)}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col flex="0 1 500px">
              <Form.Item name="promoName" label={t('Promo Name')} rules={[{ required: true, message: t('This is a mandatory field') }]}>
                <Select placeholder={t('Select a Promotion')} allowClear>
                  {Object.keys(promotions)
                    .map((key) => {
                      return [key, promotions[key]]
                    })
                    .sort((first, second) => {
                      return first[1].promoName.localeCompare(second[1].promoName)
                    })
                    .map((x) => (
                      <Option value={x[0]} key={x[0]}>
                        {t(x[1].promoName)}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 4]}>
            <Col flex="0 1 500px">
              <Form.Item name="settlement" label={t('Settlement')} rules={[{ required: true, message: t('This is a mandatory field') }]}>
                <RangePicker />
              </Form.Item>
            </Col>
            <Col flex="0 1 500px" style={{ textAlign: 'right' }}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  onClick={async () => {
                    setLoading(true)
                    await onSearch(form.getFieldsValue())
                    setLoading(false)
                  }}
                  icon={<SearchOutlined />}
                >
                  {t('Search')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col flex="auto" />
        <Col flex="1 1 175px">
          <Row gutter={[8, 4]}>
            {allowExport && (
              <Form.Item>
                <Row gutter={[8, 4]}>
                  <Col flex="auto">
                    <Button type="primary" className="btn-darkblue" icon={<ExportOutlined />} onClick={onExportWinners} loading={loadingStates[0]} style={{ width: '170px' }}>
                      {t('Export Winners')}
                    </Button>
                  </Col>
                  <Col flex="auto">
                    <Button type="primary" className="btn-darkblue" icon={<ExportOutlined />} onClick={onExportParticipants} loading={loadingStates[1]} style={{ width: '170px' }}>
                      {t('Export Participants')}
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            )}
          </Row>
        </Col>
      </Row>
    </Form>
  )
}

LuckyDrawHistorySearchForm.propTypes = {
  onSearch: PropTypes.func
}
