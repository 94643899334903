// TODO: Convert Utc to GMT-4 - Gilbert [Done]
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Button, Tag, Collapse, Descriptions, Tabs, notification, Space, Popconfirm, Modal } from 'antd'
import { DownloadOutlined, StopOutlined, SafetyOutlined, SendOutlined, UploadOutlined, ExportOutlined, LoadingOutlined } from '@ant-design/icons'
import { PageContent } from '../components/core'
import { TimeConverter } from '../components/core'
import { LuckyDrawParticipantsTable, LuckyDrawSearchForm, LuckyDrawWinnersTable, LuckyDrawFileManage, LuckyDrawHistorySearchForm } from '../components'
import './LuckyDrawPage.css'
import { LuckyDrawService } from '../services'
import moment from 'moment'
import axios from 'axios'

export const LuckyDrawsPage = (props) => {
  const { i18n, t } = useTranslation()
  const { Panel } = Collapse
  const { TabPane } = Tabs
  const { info } = Modal

  const [isGrandWinnerAllow, setIsGrandWinnerAllow] = useState(false)
  const [isDailyActionAllow, setIsDailyActionAllow] = useState(false)
  const [isGrandActionAllow, setIsGrandActionAllow] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [promotionList, setPromotionList] = useState([])
  const [participantList, setParticipantList] = useState([])
  const [dailyWinnerList, setDailyWinnerList] = useState(null)
  const [grandWinnerList, setGrandWinnerList] = useState(null)
  const [dailyActionControl, setDailyActionControl] = useState([true, true, true, true, true, true])
  const [grandActionControl, setGrandActionControl] = useState([true, true, true, true, true, true])
  const [searchCriteria, setSearchCriteria] = useState({})
  const [participantHistory, setParticipantHistory] = useState([])
  const [allowExport, setAllowExport] = useState(false)
  const [loadingStates, setLoadingStates] = useState([false, false, false, false])

  const downoadingModalConfig = {
    icon: <LoadingOutlined />,
    title: 'Downloading...',
    okText: 'Cancel',
    onOk: () => LuckyDrawService.cancel()
  }

  const openNotificationWithIcon = (type, title, description, duration) => {
    notification.destroy()
    notification[type]({
      message: title,
      description: description,
      duration: duration
    })
  }

  function DownloadWinners(winnerType) {
    ;(async () => {
      await LuckyDrawService.DownloadWinners(searchCriteria.promoName, searchCriteria.settlement, winnerType, i18n.language, t)
    })()
  }

  function onUploadFile(data, winnerType) {
    if (!data.file.name.endsWith('.xlsx')) {
      openNotificationWithIcon('error', t('File Format Error'), '', 0)
    } else {
      var file = data.file.originFileObj
      const formData = new FormData()
      formData.append('files', file)
      formData.append('promotionId', searchCriteria.promoName)
      formData.append('settlementDate', moment(searchCriteria.settlement).format('yyyy-MM-DD'))
      formData.append('winnerType', winnerType)
      formData.append('language', i18n.language)

      let statesBefore = [...loadingStates]
      statesBefore[2] = true
      setLoadingStates(statesBefore)
      axios
        .post(`LuckyDrawsManagement/ImportWinners`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(
          (response) => {
            if (response) {
              onSearch(searchCriteria)
              if (response.data === undefined || response.data.result.length === 0) {
                openNotificationWithIcon('success', t('Successful'), '', 5)
                return
              }

              console.log(response.data.result)
              const messages = response.data.result.map((i) => <li>{i}</li>)
              openNotificationWithIcon('error', t('Invalid winner list uploaded'), messages, 0)
            }
          },
          (error) => {
            console.log(error)
          }
        )
      let statesAfter = [...loadingStates]
      statesAfter[2] = false
      setLoadingStates(statesAfter)
    }
  }

  function onActionButtonClick(action, winnerType) {
    if (action) {
      if (action === 'Download Template') {
        ;(async () => {
          let statesBefore = [...loadingStates]
          statesBefore[0] = true
          setLoadingStates(statesBefore)
          await LuckyDrawService.DownloadTemplate(i18n.language, t)
          let statesAfter = [...loadingStates]
          statesAfter[0] = false
          setLoadingStates(statesAfter)
        })()
      } else if (action === 'Export Participants') {
        ;(async () => {
          let statesBefore = [...loadingStates]
          statesBefore[1] = true
          setLoadingStates(statesBefore)
          const modal = info(downoadingModalConfig)

          var promoId = searchCriteria.promoName
          var companyId = searchCriteria.company

          await LuckyDrawService.ExportParticipantsReport(companyId, promoId, promotionList[promoId].promoName, null, searchCriteria.settlement, i18n.language, t)

          modal.destroy()

          let statesAfter = [...loadingStates]
          statesAfter[1] = false
          setLoadingStates(statesAfter)
        })()
      } else if (action === 'Export Winners') {
        ;(async () => {
          let statesBefore = [...loadingStates]
          statesBefore[3] = true
          setLoadingStates(statesBefore)
          const modal = info(downoadingModalConfig)

          var promoId = searchCriteria.promoName
          await LuckyDrawService.ExportWinnersReport(promoId, promotionList[promoId].promoName, searchCriteria.settlement, searchCriteria.settlement, i18n.language, t, winnerType)

          modal.destroy()
          let statesAfter = [...loadingStates]
          statesAfter[3] = false
          setLoadingStates(statesAfter)
        })()
      } else {
        var status = 0
        if (action === 'Import') status = 2
        else if (action === 'Reject') status = 3
        else if (action === 'Verify') status = 4
        else if (action === 'Publish') status = 5

        if (status > 0 && status !== 2) {
          ;(async () => {
            await LuckyDrawService.UpdateLuckyDrawStatus(searchCriteria.promoName, searchCriteria.settlement, winnerType, status)
            if (status === 5) {
              await LuckyDrawService.UpdatePrizeItemToPublished(searchCriteria.promoName, searchCriteria.settlement, winnerType)
            }
            onSearch(searchCriteria)
          })()
        }
      }
    }
  }

  function ActionButtons(disableControl, winnerType) {
    return (
      <Space size="small">
        <Button
          icon={<DownloadOutlined />}
          disabled={disableControl[0]}
          onClick={() => {
            onActionButtonClick('Download Template', winnerType)
          }}
          loading={loadingStates[0]}
        >
          {t('Download Template')}
        </Button>
        {props.user.permissions.canImportLuckyDraw ? (
          <Button disabled={disableControl[1]} loading={loadingStates[2]}>
            {
              <>
                <UploadOutlined /> {t('Import')}
                <LuckyDrawFileManage onUploadFile={onUploadFile} winnerType={winnerType} disabled={disableControl[1]} />
              </>
            }
          </Button>
        ) : (
          ''
        )}
        <Button
          icon={<ExportOutlined />}
          disabled={disableControl[0]}
          onClick={() => {
            onActionButtonClick('Export Participants', winnerType)
          }}
          loading={loadingStates[1]}
        >
          {t('Export Participants')}
        </Button>
        <Button
          icon={<ExportOutlined />}
          disabled={disableControl[5]}
          onClick={() => {
            onActionButtonClick('Export Winners', winnerType)
          }}
          loading={loadingStates[3]}
        >
          {t('Export Winners')}
        </Button>

        {props.user.permissions.canRejectVerifyPublishLuckyDraw ? (
          <Popconfirm
            disabled={disableControl[2]}
            title={winnerType === 'daily' ? t('Reject Daily Winner') : t('Reject Grand Winner')}
            onConfirm={() => {
              onActionButtonClick('Reject', winnerType)
            }}
            okText={t('Yes')}
            cancelText={t('No')}
          >
            <Button type="primary" icon={<StopOutlined />} disabled={disableControl[2]} danger>
              {t('Reject')}
            </Button>
          </Popconfirm>
        ) : (
          ''
        )}
        {props.user.permissions.canRejectVerifyPublishLuckyDraw ? (
          <Popconfirm
            disabled={disableControl[3]}
            title={winnerType === 'daily' ? t('Verify Daily Winner') : t('Verify Grand Winner')}
            onConfirm={() => {
              onActionButtonClick('Verify', winnerType)
            }}
            okText={t('Yes')}
            cancelText={t('No')}
          >
            <Button type="primary" icon={<SafetyOutlined />} disabled={disableControl[3]} className="btn-blue">
              {t('Verify')}
            </Button>
          </Popconfirm>
        ) : (
          ''
        )}
        {props.user.permissions.canRejectVerifyPublishLuckyDraw ? (
          <Popconfirm
            disabled={disableControl[4]}
            title={winnerType === 'daily' ? t('Publish Daily Winner') : t('Publish Grand Winner')}
            onConfirm={() => {
              onActionButtonClick('Publish', winnerType)
            }}
            okText={t('Yes')}
            cancelText={t('No')}
          >
            <Button type="primary" icon={<SendOutlined />} disabled={disableControl[4]}>
              {t('Publish')}
            </Button>
          </Popconfirm>
        ) : (
          ''
        )}
      </Space>
    )
  }

  function collapse(info, cardTitle, winnerType, isActionAllow) {
    return (
      <Collapse>
        <Panel header={CardTitle(cardTitle, winnerType, isActionAllow)} key="1">
          <Descriptions>
            <Descriptions.Item label={t('File')}>
              {info && info.status >= 2 ? (
                <Button type="link" size="small" onClick={() => DownloadWinners(winnerType)}>
                  {winnerType === 'daily' ? t('Download Daily Winners') : t('Download Grand Winners')}
                </Button>
              ) : (
                ''
              )}
            </Descriptions.Item>
            <Descriptions.Item label={t('Imported')}>{info && info.importedBy ? `${t('By')}` + info.importedBy + `${t('at')}` + TimeConverter.UtctoGmtMinus4(info.importedDate) : ''}</Descriptions.Item>
            <Descriptions.Item label={t('Verified')}>{info && info.verifiedBy ? `${t('By')}` + info.verifiedBy + `${t('at')}` + TimeConverter.UtctoGmtMinus4(info.verifiedDate) : ''}</Descriptions.Item>
            <Descriptions.Item label={t('Published')}>{info && info.publishedBy ? `${t('By')}` + info.publishedBy + `${t('at')}` + TimeConverter.UtctoGmtMinus4(info.publishedDate) : ''}</Descriptions.Item>
            <Descriptions.Item label={t('Rejected')}>{info && info.rejectedBy ? `${t('By')}` + info.rejectedBy + `${t('at')}` + TimeConverter.UtctoGmtMinus4(info.rejectedDate) : ''}</Descriptions.Item>
          </Descriptions>
        </Panel>
      </Collapse>
    )
  }

  function CardTitle(title, winnerType, shouldDisplayStatus) {
    let color = 'processing'
    var winnerStatus = ''
    var winnerList = winnerType === 'daily' ? dailyWinnerList : grandWinnerList

    if (participantList) {
      if (participantList.length > 0 && !winnerList.status) winnerStatus = 'Pending'
    }

    if (winnerList) {
      if (!winnerList || winnerList.status === 0 || winnerList.status === 1) {
        winnerStatus = 'Pending'
      } else if (winnerList.status === 2) {
        winnerStatus = 'Imported'
      } else if (winnerList.status === 3) {
        winnerStatus = 'Rejected'
      } else if (winnerList.status === 4) {
        winnerStatus = 'Verified'
      } else if (winnerList.status === 5) {
        winnerStatus = 'Published'
      }

      if (winnerStatus === 'Rejected') color = 'error'
      else if (winnerStatus === 'Published') color = 'success'
    }

    return (
      <div>
        {title} &nbsp;
        {shouldDisplayStatus ? <Tag color={color}>{t(winnerStatus)}</Tag> : ''}
      </div>
    )
  }

  async function onSearch(searchCriteria) {
    setIsDailyActionAllow(false)
    setIsGrandActionAllow(false)
    setDailyActionControl([true, true, true, true, true, true])
    setGrandActionControl([true, true, true, true, true, true])
    setParticipantList([])
    setDailyWinnerList({})
    setGrandWinnerList({})
    setSearchCriteria(searchCriteria)
    setParticipantHistory([])

    var allowGrandWinner =
      searchCriteria.promoName && promotionList[searchCriteria.promoName].allowGrandPrize && moment(promotionList[searchCriteria.promoName].promotionEndDate).format('yyyy-MM-DD') === moment(searchCriteria.settlement).format('yyyy-MM-DD')

    setIsGrandWinnerAllow(allowGrandWinner)

    const getTableData = async () => {
      var participants = await LuckyDrawService.GetParticipantList(searchCriteria)
      if (participants) {
        setParticipantList(participants)
        if (participants && participants.length > 0) {
          setIsDailyActionAllow(true)
          setDailyActionControl([false, false, true, true, true, true])
        }
      }

      var dailyWinners = await LuckyDrawService.GetWinnerList(searchCriteria, 'daily', i18n.language)
      if (dailyWinners) {
        setDailyWinnerList(dailyWinners)
        var shouldAllowDailyWinners = dailyWinners.status !== 0 && participants && participants.length > 0 ? true : false
        if (shouldAllowDailyWinners) {
          setIsDailyActionAllow(true)
          setDailyActionControl([false, !(dailyWinners.status !== 5), !(dailyWinners.status === 2 || dailyWinners.status === 4), !(dailyWinners.status === 2), !(dailyWinners.status === 4), !(dailyWinners.status === 5)])
        }
      }

      if (allowGrandWinner) {
        var grandWinners = await LuckyDrawService.GetWinnerList(searchCriteria, 'grand', i18n.language)
        if (grandWinners) {
          setGrandWinnerList(grandWinners)
          if (grandWinners.status !== 0) {
            setIsGrandActionAllow(true)
            setGrandActionControl([false, !(grandWinners.status !== 5), !(grandWinners.status === 2 || grandWinners.status === 4), !(grandWinners.status === 2), !(grandWinners.status === 4), !(grandWinners.status === 5)])
          }
        }
      }
    }
    await getTableData()
  }

  function onCompanySelect(selectedCompany, form) {
    setPromotionList([])

    if (selectedCompany) {
      const getEnabledPromotions = async () => {
        var result = await LuckyDrawService.GetActivePromotions(selectedCompany, i18n.language)
        if (result) {
          setPromotionList(result.promotions)
          // form.setFieldsValue({ promoName: Object.keys(result.promotions)[0]})
        }
      }
      getEnabledPromotions()
    }
  }

  function WinnerManagement() {
    return (
      <>
        <LuckyDrawSearchForm onSearch={onSearch} onCompanySelect={onCompanySelect} companies={companyList} promotions={promotionList} user={props.user} />
        <Card size="small" title={t('Participant List')} style={{ marginBottom: '10px' }}>
          <LuckyDrawParticipantsTable dataSource={participantList} pageSize={10} />
        </Card>
        <Tabs type="card">
          <TabPane tab={t('Daily Winner')} key="dailyWinner">
            <Card size="small" title={collapse(dailyWinnerList, t('Daily Winner List'), 'daily', isDailyActionAllow)}>
              <LuckyDrawWinnersTable dataSource={dailyWinnerList ? dailyWinnerList.winnerInfoList : []} pageSize={10} />
              {isDailyActionAllow ? ActionButtons(dailyActionControl, 'daily') : ''}
            </Card>
          </TabPane>
          {isGrandWinnerAllow ? (
            <TabPane tab={t('Grand Winner')} key="grandWinner">
              <Card size="small" title={collapse(grandWinnerList, t('Grand Winner List'), 'grand', isGrandActionAllow)}>
                <LuckyDrawWinnersTable dataSource={grandWinnerList ? grandWinnerList.winnerInfoList : []} pageSize={10} />
                {isGrandActionAllow ? ActionButtons(grandActionControl, 'grand') : ''}
              </Card>
            </TabPane>
          ) : (
            ''
          )}
        </Tabs>
      </>
    )
  }

  async function onParticipantHistorySearch(searchCriteria) {
    setParticipantHistory([])
    setAllowExport(false)

    var history = await LuckyDrawService.GetParticipantHistory(searchCriteria)
    if (history) {
      setParticipantHistory(history)
      if (history.length > 0) {
        setAllowExport(true)
      }
    }
  }

  function onPartcipantHistoryCompanySelect(selectedCompany) {
    setParticipantHistory([])
    if (selectedCompany) {
      const getEnabledPromotions = async () => {
        var result = await LuckyDrawService.GetActivePromotions(selectedCompany, i18n.language)
        if (result) {
          setPromotionList(result.promotions)
        }
      }
      getEnabledPromotions()
    }
  }

  function ParticipantsHistoryDisplay() {
    return (
      <>
        <LuckyDrawHistorySearchForm onSearch={onParticipantHistorySearch} onCompanySelect={onPartcipantHistoryCompanySelect} companies={companyList} promotions={promotionList} user={props.user} allowExport={allowExport} />
        <LuckyDrawParticipantsTable dataSource={participantHistory} pageSize={10} />
      </>
    )
  }

  useEffect(() => {
    const getEnabledPromotions = async () => {
      var options = await LuckyDrawService.GetCompanyOptions(i18n.language)
      setCompanyList(options.companies)
      if (props.user.companyId) {
        onCompanySelect(props.user.companyId)
        onPartcipantHistoryCompanySelect(props.user.companyId)
      }
    }
    getEnabledPromotions()
  }, [i18n.language]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageContent>
      <div className="luckydraw-page-container">
        <Tabs type="card">
          <TabPane tab={t('Winner Management')} key="winnerManagement">
            {WinnerManagement()}
          </TabPane>
          <TabPane tab={t('History')} key="history">
            {ParticipantsHistoryDisplay()}
          </TabPane>
        </Tabs>
      </div>
    </PageContent>
  )
}
