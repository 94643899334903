import axios from 'axios'

const getAll = async () => {
    var response = await axios.get('WindowServiceStatus/listWindowServiceInstances').catch(console.log)
    return response?.data?.result
}

const get = async (instanceName) => {
    var response = await axios.get(`WindowServiceStatus/windowServiceInstance?instanceName=${instanceName}`).catch(console.log)
    return response?.data?.result
}

const update = async (instanceName, status) => {
    var response = await axios.put(`WindowServiceStatus/updateWindowServiceStatus?instanceName=${instanceName}&status=${status}`).catch(console.log)
    return response?.data?.result
}

export const SettingService = {
    getAll,
    get,
    update
}