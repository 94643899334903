// TODO: Convert Utc to GMT-4 - Gilbert [Done]
import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Input, Form, Radio, Modal, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { TabFooterLayout } from '../components'
import { PrizeItemConstants, TimeConverter } from '../components/core'

export const PrizeItemInfo = (props) => {
  const { Option } = Select
  const { i18n, t } = useTranslation()

  const companies = props.user.isBusinessUnit ? props.companies : { ...[props.companies[props.user.companyId]] }
  const formItemLayout = {
    labelCol: { flex: '100px' },
    wrapperCol: { flex: '0 1 320px' },
    labelAlign: 'left'
  }
  const nameWrapperCol = { flex: '0 1 465px' }

  const initialValues = props.prizeItem
  const isUpdate = props.action === 'Update'

  const footerInfo = isUpdate
    ? {
        createdBy: initialValues.createdBy,
        createdDate: TimeConverter.UtctoGmtMinus4(initialValues.createdDate),
        updatedBy: initialValues.updatedBy,
        updatedDate: TimeConverter.UtctoGmtMinus4(initialValues.updatedDate)
      }
    : null

  return (
    <Modal
      title={t(isUpdate ? 'Update Prize Item' : 'Create Prize Item')}
      visible={props.visible}
      onOk={props.handleOk}
      onCancel={props.handleCancel}
      destroyOnClose={true}
      maskClosable={false}
      footer={<TabFooterLayout onCancel={props.handleCancel} footerInfo={footerInfo} form="prizeItemForm" shouldShowSaveButton={props.user.permissions.canUpdatePrizeItem} customizeSaveBtnName={props.action} />}
      width={640}
    >
      <Form {...formItemLayout} onFinish={props.handleOk} id="prizeItemForm" initialValues={initialValues} className={`form-${i18n.language}`}>
        <Form.Item name="id" hidden={true}>
          <Input hidden={true} />
        </Form.Item>
        <Form.Item name="companyId" hidden={true}>
          <Input hidden={true} />
        </Form.Item>
        <Form.Item name="hasPublished" hidden={true}>
          <Input hidden={true} />
        </Form.Item>
        <Row gutter={[8, 4]}>
          <Col span={24}>
            <Form.Item label={t('Name')} wrapperCol={{ flex: '1 1 450px' }} className="mb-0" style={{ maxWidth: '1200px' }}>
              <Row gutter={[8, 0]}>
                <Col {...nameWrapperCol}>
                  <Form.Item name="nameEn" rules={[{ required: true, message: t('This is a mandatory field') }]}>
                    <Input addonBefore={t('EN')} placeholder={t('Prize item name EN')} disabled={initialValues && initialValues.hasPublished} />
                  </Form.Item>
                </Col>
                <Col {...nameWrapperCol}>
                  <Form.Item name="nameCn" rules={[{ required: true, message: t('This is a mandatory field') }]}>
                    <Input addonBefore={t('CN')} placeholder={t('Prize item name CN')} disabled={initialValues && initialValues.hasPublished} />
                  </Form.Item>
                </Col>
                <Col {...nameWrapperCol}>
                  <Form.Item name="nameTh" rules={[{ required: true, message: t('This is a mandatory field') }]}>
                    <Input addonBefore={t('TH')} placeholder={t('Prize item name TH')} disabled={initialValues && initialValues.hasPublished} />
                  </Form.Item>
                </Col>
                <Col {...nameWrapperCol}>
                  <Form.Item name="nameVi" rules={[{ required: true, message: t('This is a mandatory field') }]}>
                    <Input addonBefore={t('VN')} placeholder={t('Prize item name VN')} disabled={initialValues && initialValues.hasPublished} />
                  </Form.Item>
                </Col>
                <Col {...nameWrapperCol}>
                  <Form.Item name="nameId" rules={[{ required: true, message: t('This is a mandatory field') }]}>
                    <Input addonBefore={t('IDN')} placeholder={t('Prize item name ID')} disabled={initialValues && initialValues.hasPublished} />
                  </Form.Item>
                </Col>
                <Col {...nameWrapperCol}>
                  <Form.Item name="nameEs" rules={[{ required: true, message: t('This is a mandatory field') }]}>
                    <Input addonBefore={t('ES')} placeholder={t('Prize item name ES')} disabled={initialValues && initialValues.hasPublished} />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="company" label={t('Company')} rules={[{ required: true, message: t('This is a mandatory field') }]}>
              <Select placeholder={t('Select a Company')} disabled={isUpdate || !props.user.isBusinessUnit}>
                {Object.entries(companies).map(([key, value]) => (
                  <Option value={key} key={key}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="status" label={t('Status')}>
              <Radio.Group>
                {Object.entries(PrizeItemConstants.prizeItemStatuses).map(([key, value]) => (
                  <Radio value={key} key={key}>
                    {t(value)}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

PrizeItemInfo.propTypes = {
  action: PropTypes.string,
  prizeItem: PropTypes.object,
  id: PropTypes.string,
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
  companies: PropTypes.object,
  user: PropTypes.object
}
