import React from 'react'
import PropTypes from 'prop-types'
import { Table, Empty } from 'antd'
import { useTranslation } from 'react-i18next'

export const LuckyDrawWinnersTable = ({ dataSource, pageSize = 50 }) => {
  const { t } = useTranslation()

  const columns = [
    {
      title: t('Prize Band'),
      dataIndex: 'prizeBandString',
      render: (text, record, index) => t(record.prizeBandString),
      sorter: (a, b) => a.prizeBandInt - b.prizeBandInt,
      defaultSortOrder: 'ascend',
      multiple: 2
    },
    {
      title: t('Member Code'),
      dataIndex: 'memberCode',
      sorter: (a, b) => a.memberCode.localeCompare(b.memberCode),
      defaultSortOrder: 'ascend',
      multiple: 1
    },
    {
      title: t('Prize Item Name'),
      dataIndex: 'prizeItemName',
      sorter: (a, b) => a.prizeItemName.localeCompare(b.prizeItemName)
    },
    {
      title: t('Quantity'),
      dataIndex: 'prizeQuantity',
      align: 'right',
      sorter: (a, b) => a.prizeQuantity - b.prizeQuantity
    },
    {
      title: t('Reward Points'),
      dataIndex: 'rewardPoints',
      align: 'right',
      sorter: (a, b) => a.rewardPoints - b.rewardPoints
    }
  ]

  return (
    <Table
      columns={columns}
      scroll={{ x: 'scroll' }}
      pagination={{
        pageSize: pageSize
      }}
      dataSource={dataSource}
      rowKey="memberCode"
      size="small"
      locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('No record found')} /> }}
    />
  )
}

LuckyDrawWinnersTable.propTypes = {
  onRowClick: PropTypes.func,
  pageSize: PropTypes.number,
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      prizeBand: PropTypes.number,
      memberCode: PropTypes.string,
      prizeItemName: PropTypes.string,
      quantity: PropTypes.number,
      rewardPoints: PropTypes.number
    })
  )
}
