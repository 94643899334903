import React, { useState, useEffect } from 'react'
import { PageContent } from '../../components/core'
import { Form } from 'antd'
import { PageOptionsService, ReportService, PermissionsService } from '../../services'
import { useTranslation } from 'react-i18next'
import { FreebetQueryForm, FreebetQueryTable } from './'
import moment from 'moment'

export const FreebetQueryReportPage = (props) => {
    const [form] = Form.useForm()
    const { t, i18n } = useTranslation()
    const [pageOptions, setPageOptions] = useState(null)
    const [queryResult, setQueryResult] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const statuses = {
        DailyMissionClaim: 'Claimed',
        Refund: 'Refunded'
    }

    const dateFormat = 'YYYY-MM'
    var today = new Date()

    var initialSearchCriteria = {}
    if (props.user.companyId) {
        initialSearchCriteria = { status: Object.entries(statuses).map(([key, value]) => key), company: props.user.companyId, startDate: moment(today, dateFormat), endDate: moment(today, dateFormat) }
    } else {
        initialSearchCriteria = { status: Object.entries(statuses).map(([key, value]) => key), company: ['0'], startDate: moment(today, dateFormat), endDate: moment(today, dateFormat) }
    }

    function constructSearchCriteria(searchCriteria) {
        return {
            companies: searchCriteria.company ?? '',
            memberCode: searchCriteria.membeCode ?? '',
            creditTransactionId: searchCriteria.freebetId ?? '',
            freebetStatuses: searchCriteria.status ?? '',
            promotionId: searchCriteria.promoID,
            promotionName: searchCriteria.promoName,
            loginLanguage: i18n.language,
            startQueryDate: searchCriteria.startDate.format('yyyy-MM-DD'),
            endQueryDate: searchCriteria.endDate.format('yyyy-MM-DD'),
            isBusinessUnit: PermissionsService.isBusinessUnit()
        }
    }

    function constructFreeBets(freebets) {
        var list = []
        freebets.forEach((freebet, index) => {
            list.push(
                {
                    id: index,
                    company: freebet.companyName ?? '',
                    memberCode: freebet.memberCode ?? '',
                    freebetId: freebet.creditTransactionId ?? '',
                    status: freebet.status ?? '',
                    currency: freebet.currency ?? '',
                    amount: freebet.claimedAmount ?? '',
                    used: freebet.usedAmount ?? '',
                    balance: freebet.balance ?? '',
                    createdBy: freebet.createdBy ?? '',
                    claimedDate: freebet.claimedDate ?? '',
                    expired: freebet.isExpired ?? '',
                    updatedDate: freebet.lastUpdatedDate ?? '',
                    promoId: freebet.promotionId ?? '',
                    nameEn: freebet.promotionEnName  ?? '',
                    nameCn: freebet.promotionCnName ?? '',
                    nameTh: freebet.promotionThName ?? '',
                    nameVn: freebet.promotionVnName ?? ''
                }
            );
        });

        return list
    }

    async function getFreebetList(searchCriteria) {
        var criteria = constructSearchCriteria(searchCriteria)
        var queryString = objectToQueryString(criteria)
        var response = await ReportService.getFreebetReport(queryString)
         if (response) {
             return response
         }
        return null
    }

    async function exportFreebetList(searchCriteria) {
        var criteria = constructSearchCriteria(searchCriteria)
        var queryString = objectToQueryString(criteria)
        var response = await ReportService.exportFreebetReport(queryString, props.user)
        if (response) {
            return response
        }
        return null
    }

    function objectToQueryString(obj) {
        var str = []
        for (var p in obj) {
            if (obj[p]) {
                if (obj.hasOwnProperty(p)) {
                    if (Array.isArray(obj[p])) {
                        for (var arr in obj[p]) {
                            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p][arr]))
                        }
                    } else {
                        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
                    }
                }
            }
        }

        return str.join('&')
    }

    function onSearch() {
        form.validateFields().then(async (values, err) => {
            if (values) {
                setIsLoading(true)
                var freebets = await getFreebetList(values)
                if (freebets) {
                    setQueryResult(constructFreeBets(freebets))
                } else {
                    setQueryResult(null)
                }
                setIsLoading(false)
            }
        }).catch(e => {
        });
    }

    function onExport() {
        form.validateFields().then(async (values, err) => {
            if (values) {
                setIsLoading(true)
                await exportFreebetList(values)
                setIsLoading(false)
            }
        }).catch(e => {
        });
    }

    async function getPageOptions() {
        const pageOptions = await PageOptionsService.getPageOptionsCompanies(i18n.language)
        return pageOptions
    }

    useEffect(() => {
        const getOptions = async () => {
            var options = await getPageOptions()
            if (options) {
                setPageOptions(options)
            }
        }
        getOptions()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <PageContent>
            <FreebetQueryForm form={form} companies={pageOptions ? pageOptions.companies : {}} statuses={statuses ?? []} initialSearchCriteria={initialSearchCriteria} onSearch={onSearch} onExport={onExport} user={props.user} />
            {/* { dataSource, isLoading, pageSize = 15 } */}
            <FreebetQueryTable dataSource={queryResult} isLoading={isLoading} pageSize={50} />
        </PageContent>
    )
}