// TODO: Convert Utc to GMT-4 - Gilbert [Done]
import './promotionInfoPage.css'
import React, { useState, useEffect } from 'react'
import { Form, Tabs, notification } from 'antd'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { WarningTwoTone } from '@ant-design/icons'
import { PageContent, TimeConverter } from '../components/core'
import { GeneralInfoForm, RulesForm, SportForm, TabFooterLayout } from '../components'
import moment from 'moment'
import axios from 'axios'
import { AuthService, PageOptionsService} from '../services'

export const PromoInfo = (props) => {
  const { t, i18n } = useTranslation()
  const [form] = Form.useForm()
  const { TabPane } = Tabs

  let history = useHistory()
  let query = new URLSearchParams(useLocation().search)

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    },
    labelAlign: 'left'
  }

  const [generalInfoError, setGeneralInfoError] = useState(false)
  const [sportError, setSportError] = useState(false)
  const [rulesError, setRulesError] = useState(false)
  const [currencyError, setCurrencyError] = useState(false)
  const [oddSettingsError, setOddSettingsError] = useState(false)
  const [betSettingsError, setBetSettingsError] = useState(false)
  const [rewardPointError, setRewardPointError] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [isUpdateMode, setIsUpdateMode] = useState(false)
  const [pageOptions, setPageOptions] = useState(null)
  const [periods, setPeriods] = useState({})
  const [betTypes, setBetTypes] = useState({})
  const [competitions, setCompetitions] = useState({})
  const [oddsTableData, setOddsTableData] = useState(null)
  const [minimalOddsCheck, setMinimalOddsCheck] = useState(false)

  const dateFormat = 'YYYY/MM/DD'
  const minOdds = 1.7
  const maxOdds = 100
  const companies = pageOptions ? pageOptions.companies : {}
  const sports = pageOptions ? pageOptions.sports : {}
  const oddTypes = pageOptions ? pageOptions.oddsTypes : {}
  const memberCategories = pageOptions ? pageOptions.memberCategories : {}
  const markets = pageOptions ? pageOptions.markets : {}
  const wagerTypes = pageOptions ? pageOptions.wagerTypes : {}
  const wagerSources = pageOptions ? pageOptions.wagerSources : {}
  const currencies = pageOptions ? pageOptions.currencies : {}

  var pickerStart = new Date()
  pickerStart.setDate(pickerStart.getDate() + 1)
  var pickerEnd = new Date()
  pickerEnd.setDate(pickerEnd.getDate() + 30)

  var pageInitialValues = {
    allowGrandPrize: 'no',
    category: t('luckydraw'),
    status: t('enabled'),
    promoDateRange: [moment(pickerStart, dateFormat), moment(pickerEnd, dateFormat)],
    company: AuthService.currentUserValue.companyId,
    markets: ['0'],
    wagerTypes: ['1'],
    periods: ['0'],
    betTypes: ['0'],
    wagerSources: ['0'],
    supportedOddTypes: ['0']
  }

  const openNotificationWithIcon = (type, title, description, duration) => {
    notification.destroy()
    notification[type]({
      message: title,
      description: description,
      duration: duration
    })
  }

  async function CreatePromotion(searchCriteria) {
    searchCriteria.allowGrandPrize = searchCriteria.allowGrandPrize === 'no' ? false : true
    const startDate = new Date(searchCriteria.promoDateRange[0])
    startDate.setHours(startDate.getHours() + 8)
    searchCriteria.promoStart = startDate

    const endDate = new Date(searchCriteria.promoDateRange[1])
    endDate.setHours(endDate.getHours() + 8)
    searchCriteria.promoEnd = endDate

    var companyId = searchCriteria.company
    searchCriteria.companyId = parseInt(companyId)
    searchCriteria.company = companies[companyId]

    var json = JSON.stringify(searchCriteria)
    var response = await axios.post('Promotions', json).catch(console.log)
    if (response) {
      return response.data.result
    }
    return null
  }

  async function UpdatePromotion(searchCriteria) {
    var promoId = query.get('promoId')
    searchCriteria.allowGrandPrize = searchCriteria.allowGrandPrize === 'no' ? false : true

    const startDate = new Date(searchCriteria.promoDateRange[0])
    startDate.setHours(startDate.getHours() + 8)
    searchCriteria.promoStart = startDate

    const endDate = new Date(searchCriteria.promoDateRange[1])
    endDate.setHours(endDate.getHours() + 8)
    searchCriteria.promoEnd = endDate

    searchCriteria.Id = parseInt(promoId)

    var response = await axios.put(`Promotions/${promoId}`, searchCriteria).catch(console.log)
    if (response) {
      return response.data.result
    }
    return null
  }

  function onFinish(formValues) {
    setGeneralInfoError(false)
    setSportError(false)
    setRulesError(false)
    setCurrencyError(false)
    setOddSettingsError(false)
    setBetSettingsError(false)
    setRewardPointError(false)

    formValues.competitions = formValues.competitions.map((x) => {
      return { competitionId: parseInt(x), competitionName: x === '0' ? x : competitions[x] }
    })
    formValues.currencies = formValues.currencies.map((x) => {
      var currency = x.currency.toString().toUpperCase()
      return { currencyId: currency, currency: currency, stake: x.stake }
    })

    var sportId = formValues.sport
    formValues.sportId = sportId
    formValues.sport = sports[sportId]
    ;(async () => {
      var saveResult = null
      var failMessage = ''

      if (isUpdateMode) {
        saveResult = await UpdatePromotion(formValues)
        failMessage = 'Update fail'
      } else {
        saveResult = await CreatePromotion(formValues)
        failMessage = 'Create fail'
      }

      if (saveResult) {
        openNotificationWithIcon('success', t('Successful'), '', 5)
        history.push('/promotions')
      } else openNotificationWithIcon('error', failMessage, '', 5)
    })()
  }

  function onValuesChange() {
    var errors = form.getFieldsError()
    if (errors && hasErrors(errors, 0, errors.length - 1)) {
      setAlertIcon()
    } else {
      setGeneralInfoError(false)
      setSportError(false)
      setRulesError(false)
      setCurrencyError(false)
      setOddSettingsError(false)
      setBetSettingsError(false)
      setRewardPointError(false)
    }
  }

  function onFinishFailed() {
    setAlertIcon()
  }

  function setAlertIcon() {
    var errors = form.getFieldsError()

    setGeneralInfoError(hasErrors(errors, 0, 11))
    setSportError(hasErrors(errors, 12, 13))
    setRulesError(hasErrors(errors, 14, errors.length - 1))

    var currencyHasError = errors.length > 30 ? hasErrors(errors, 14, 14) || hasErrors(errors, 30, errors.length - 1) : hasErrors(errors, 14, 14)
    setCurrencyError(currencyHasError)

    setOddSettingsError(hasErrors(errors, 15, 18))
    setBetSettingsError(hasErrors(errors, 19, 24))
    setRewardPointError(hasErrors(errors, 25, 29))
  }

  function hasErrors(errors, startIndex, endIndex) {
    return errors.some((err, index) => {
      return index >= startIndex && index <= endIndex && err.errors.length > 0
    })
  }

  function onSelectAll(selectedValue, field) {
    if (selectedValue === 'All') {
      form.setFieldsValue(field)
    }
  }

  function updateSportInfo(selectedSport) {
    if (selectedSport) {
      setCompetitions([])
      ;(async () => {
        var sportInfo = await GetPageOptionsBySport(selectedSport)
        if (sportInfo) {
          setPeriods(sportInfo.periods ?? {})
          setBetTypes(sportInfo.betTypes ?? {})
          setCompetitions(sportInfo.competitions ?? {})

          // Phase2
          // if(!query.get('promoId')){
          //   form.setFieldsValue({periods: []})
          //   form.setFieldsValue({betTypes: []})
          // }
        }
      })()
    }
  }

  function onCancel() {
    history.push('/promotions')
  }

  function onMinOddsChange() {
    var minOdds = parseFloat(form.getFieldValue('minHkOdds'))
    setOddsTableData((prevState) => {
      const orderItems = prevState.map((item) => {
        return { ...item, min: calculateOdds(item.oddsKind, minOdds) }
      })

      return orderItems
    })
  }

  function onMaxOddsChange() {
    var maxOdds = parseFloat(form.getFieldValue('maxHkOdds'))
    setOddsTableData((prevState) => {
      const orderItems = prevState.map((item) => {
        return { ...item, max: calculateOdds(item.oddsKind, maxOdds) }
      })

      return orderItems
    })
  }

  function onMinimalOddsChanged(value) {
    var isChecked = value.target.checked
    setMinimalOddsCheck(isChecked)

    if (!form.getFieldValue('minHkOdds') && !form.getFieldValue('maxHkOdds') && isChecked) {
      form.setFieldsValue({ minHkOdds: minOdds, maxHkOdds: maxOdds })
      onMinOddsChange()
      onMaxOddsChange()
    }
    if (!isChecked) {
      form.setFieldsValue({ minHkOdds: undefined, maxHkOdds: undefined })
      onMinOddsChange()
      onMaxOddsChange()
    }
  }

  function updateOddsTable(minHkOdds, maxHkOdds) {
    var defaultData = [
      {
        key: '1',
        oddsKind: t('EU'),
        min: calculateOdds('EU', minHkOdds),
        max: calculateOdds('EU', maxHkOdds)
      },
      {
        key: '2',
        oddsKind: t('MY'),
        min: calculateOdds('MY', minHkOdds),
        max: calculateOdds('MY', maxHkOdds)
      },
      {
        key: '3',
        oddsKind: t('INDO'),
        min: calculateOdds('INDO', minHkOdds),
        max: calculateOdds('INDO', maxHkOdds)
      }
    ]

    setOddsTableData(defaultData)
    setMinimalOddsCheck(minHkOdds && maxHkOdds)
  }

  function calculateOdds(oddsKind, odds) {
    if (odds) {
      odds = parseFloat(odds)
      var convertedOdds = odds
      if (oddsKind === 'EU') convertedOdds = odds + 1
      else if (oddsKind === 'MY') {
        if (odds > 1) convertedOdds = (1 / odds) * -1
      } else if (oddsKind === 'INDO') {
        if (odds < 1) convertedOdds = (1 / odds) * -1
      }

      return convertedOdds.toFixed(2)
    }
  }

  async function GetPromo() {
    var promoId = query.get('promoId')
    if (promoId) {
      setIsUpdateMode(true)
      const response = await axios.get(`Promotions/${promoId}`).catch(console.log)
      if (response) {
        const data = await response.data.result
        data.promoDateRange = [moment.utc(data.promoStart, dateFormat), moment.utc(data.promoEnd, dateFormat)]
        data.allowGrandPrize = data.allowGrandPrize ? 'yes' : 'no'
        data.competitions = data.competitions.map((x) => x.competitionId.toString())
        data.currencies = data.currencies.map((x) => {
          return { currency: x.currency, stake: x.stake }
        })
        data.sport = data.sportId

        setInitialValues(data)
        form.setFieldsValue(data)
        updateSportInfo(data.sportId)
        updateOddsTable(data.minHkOdds, data.maxHkOdds)

        return data
      }
    }
    return null
  }

    async function GetPageOptionsByCompany(data) {
        var companyId = props.user.companyId ? props.user.companyId : data.companyId
        const result = await PageOptionsService.getPageOptionsByCompany(companyId, i18n.language)
        if (result !== undefined) {
            setPageOptions(result)
            return result
        }
        return null
    }

    async function GetPageOptionsBySport(sportId) {
        const result = await PageOptionsService.getPageOptionsBySport(sportId, i18n.language)
        if (result !== undefined) {
            return result
        }
        return null
    }

  useEffect(() => {
    updateOddsTable(null, null)

    const getPromotions = async () => {
      var data = await GetPromo()
      var options = await GetPageOptionsByCompany(data)

      if (data && options) {
        updateSportInfo(data.sportId)
      }
    }
    getPromotions()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  var isPromotionStarted = isUpdateMode && new Date() >= new Date(initialValues.promoStart)
  var footerInfo = isUpdateMode
    ? {
        createdBy: initialValues.createdBy,
        createdDate: TimeConverter.UtctoGmtMinus4(initialValues.createdDate),
        updatedBy: initialValues.updatedBy,
        updatedDate: TimeConverter.UtctoGmtMinus4(initialValues.updatedDate)
      }
    : null
  return (
    <PageContent>
      <Form {...formItemLayout} form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} onFieldsChange={onValuesChange} initialValues={pageInitialValues} className="promotionInfoForm">
        <div className="card-container">
          <Tabs type="card">
            <TabPane
              tab={
                <span>
                  {' '}
                  {generalInfoError ? <WarningTwoTone twoToneColor="red" /> : ''} {t('General Information')}{' '}
                </span>
              }
              key="generalInfo"
            >
              <GeneralInfoForm companies={companies} isUpdateMode={isUpdateMode} isPromotionStarted={isPromotionStarted} user={props.user} />
            </TabPane>
            <TabPane
              tab={
                <span>
                  {' '}
                  {sportError ? <WarningTwoTone twoToneColor="red" /> : ''} {t('Sport')}{' '}
                </span>
              }
              key="sport"
              forceRender="true"
            >
              <SportForm
                sports={sports}
                competitions={competitions}
                onSelectAll={onSelectAll}
                updateSportInfo={updateSportInfo}
                isUpdateMode={isUpdateMode}
                isPromotionStarted={isPromotionStarted}
                selectedSport={initialValues.sport ?? ''}
                selectedCompetitions={initialValues.competitions ?? []}
              />
            </TabPane>
            <TabPane
              tab={
                <span>
                  {' '}
                  {rulesError ? <WarningTwoTone twoToneColor="red" /> : ''} {t('Rules')}{' '}
                </span>
              }
              key="rules"
              forceRender="true"
            >
              <RulesForm
                oddTypes={oddTypes}
                memberCategories={memberCategories}
                markets={markets}
                periods={periods ?? {}}
                betTypes={betTypes ?? {}}
                wagerTypes={wagerTypes}
                wagerSources={wagerSources}
                currencies={currencies}
                onSelectAll={onSelectAll}
                form={form}
                currencyHasError={currencyError}
                oddsHasError={oddSettingsError}
                betHasError={betSettingsError}
                isPromotionStarted={isPromotionStarted}
                rewardPointHasError={rewardPointError}
                oddsTable={oddsTableData}
                onMinOddsChange={onMinOddsChange}
                onMaxOddsChange={onMaxOddsChange}
                onMinimalOddsChanged={onMinimalOddsChanged}
                minimalOddsCheck={minimalOddsCheck}
              />
            </TabPane>
          </Tabs>
        </div>
        <TabFooterLayout onCancel={onCancel} footerInfo={footerInfo} shouldShowSaveButton={props.user.permissions.canUpdatePromo} />
      </Form>
    </PageContent>
  )
}
