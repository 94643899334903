import React, { useState } from 'react'
import { Table, Empty } from 'antd'
import { useTranslation } from 'react-i18next'
import { TimeConverter } from '../../components/core'
import { PermissionsService } from '../../services'

export const FreebetQueryTable = ({ dataSource, isLoading, pageSize = 15 }) => {
  const { t, i18n } = useTranslation()
  const [page, setPage] = useState(1)

 const getTranslatedPromoName = () => {
    if (i18n.language === 'ch') return 'nameCn'
    if (i18n.language === 'th') return 'nameTh'
    if (i18n.language === 'vn') return 'nameVn'
    return 'nameEn'
    }

 const getAnyPromoName = (record) => {
    if (record.nameCn !== '') return record.nameCn
    if (record.nameTh !== '') return record.nameTh
    if (record.nameVn !== '') return record.nameVn
    return record.nameEn
  }

  const columns = [
    { title: t('Number'), dataIndex: 'id', render: (text, record, index) => index + 1 + pageSize * (page - 1) },
    { title: t('Company'), dataIndex: 'company', isHide: !PermissionsService.isBusinessUnit(), sorter: (a, b) => a.company.localeCompare(b.company) },
    {
      title: t('Promo Name'),
      dataIndex: getTranslatedPromoName(),
      sorter: (a, b) => a.nameEn.localeCompare(b.nameEn),
      render: (text, record, index) => (
          <>
              {text !== '' ? text : getAnyPromoName(record)}
          </>
      )
  },
    { title: t('Promo ID'), dataIndex: 'promoId', sorter: (a, b) => a.promoId - b.promoId },
    { title: t('Member Code'), dataIndex: 'memberCode', sorter: (a, b) => a.memberCode.localeCompare(b.memberCode) },
    { title: t('Freebet ID'), dataIndex: 'freebetId', sorter: (a, b) => a.freebetId - b.freebetId, render: (balance) => (t(balance))},
    { title: t('Status'), dataIndex: 'status', sorter: (a, b) => a.status.localeCompare(b.status), render: (s) => (t(s)) },
    { title: t('Currency'), dataIndex: 'currency', sorter: (a, b) => a.currency.localeCompare(b.currency) },
    { 
      title: t('Amount'), 
      dataIndex: 'amount', 
      sorter: (a, b) => a.amount - b.amount,
      render: (amount) => (
        <>
          <span style={{ color: 'amount' }}>{amount}</span>
        </>
      )
    },
    { 
      title: t('Used'), 
      dataIndex: 'used', 
      sorter: (a, b) => a.used - b.used,
      render: (used) => (
        <>
          <span style={{ color: 'red' }}>{used}</span>
        </>
      )
    },
    { 
      title: t('Balance'), 
      dataIndex: 'balance', 
      sorter: (a, b) => a.balance - b.balance,
      render: (balance) => (
        <>
          <span style={{ color: 'green' }}>{balance}</span>
        </>
      )
    },
    { title: t('Created By'), dataIndex: 'createdBy', sorter: (a, b) => a.createdBy.localeCompare(b.createdBy) },
    { 
      title: t('Expired'), 
      dataIndex: 'expired', 
      sorter: (a, b) => a.expired.localeCompare(b.expired),
      render: (expired) =>
        <>
          <span>{(t(expired))}</span> 
        </>            
    },
    {
      title: t('Received Date'),
      dataIndex: 'claimedDate',
      sorter: (a, b) => new Date(a.claimedDate) - new Date(b.claimedDate),
      render: (claimedDate) => (
        <>
          <span>{TimeConverter.UtctoGmtMinus4(claimedDate)}</span>
        </>
      )
    },
    {
      title: t('Last Updated Date'),
      dataIndex: 'updatedDate',
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.updatedDate) - new Date(b.updatedDate),
      render: (updatedDate) => (
        <>
          <span>{TimeConverter.UtctoGmtMinus4(updatedDate)}</span>
        </>
      )
    },
  ]
  
  return (
    <Table
      columns={columns.filter(column => !column.isHide)}
      dataSource={dataSource}
      loading={isLoading}
      scroll={{ x: 'scroll' }}
      pagination={{
        pageSize: pageSize,
        showSizeChanger: false,
        onChange(current) {
          setPage(current)
        }
      }}
      rowKey="id"
      className={'freebetQueryTable'}
      size="small"
      locale={{ 
        emptyText: <Empty image={isLoading  ? '' : Empty.PRESENTED_IMAGE_SIMPLE} 
        description={isLoading ? '' : t('No record found')} />
      }}
    />
  )
}
