import React from 'react'
import { useTranslation } from 'react-i18next'
import { Upload, notification } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

export const LuckyDrawFileManage = ({ onUploadFile, winnerType, disabled }) => {
  const { t } = useTranslation()

  function onDownload(file) {
    console.log('Download', file)
  }

  const openNotificationWithIcon = (type, title, description, duration) => {
    notification.destroy()
    notification[type]({
      message: title,
      description: description,
      duration: duration
    })
  }

  const uploadProps = {
    name: 'file',
    action: '//jsonplaceholder.typicode.com/posts/',
    listType: 'picture',
    headers: {
      authorization: 'authorization-text'
    },
    disabled: disabled,
    accept: '.xlsx',
    onChange: (info) => {
      if (info.file.status === 'done') {
        onUploadFile(info, winnerType)
      } else if (info.file.status === 'error') {
        openNotificationWithIcon('error', t('Import failed'), '', 0)
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068'
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`
    }
  }

  return (
    <div style={{ opacity: 0, width: '0px', marginTop: '-20px' }}>
      <Upload {...uploadProps} onDownload={onDownload}>
        <span style={{ opacity: disabled ? 0.2 : 1 }}>
          <UploadOutlined /> {t('Import')}
        </span>
      </Upload>
    </div>
  )
}
