import React, { useState, useEffect } from 'react'
import { Col, Row, Statistic, Card, Space, Tooltip, Form, Input, Select, Button, Tag } from 'antd';
import { SearchOutlined } from '@ant-design/icons'
import { UtilityService } from '../../services'

const labelCol = { flex: '80px' }
const colProps = { xs: { span: 24 }, md: { span: 12 }, lg: { span: 8 }, xl: { span: 6 } }

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
        md: { span: 9 },
        lg: { span: 10 },
        xxl: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
        md: { span: 15 },
        lg: { span: 14 },
        xxl: { span: 16 }
    },
    labelAlign: 'left'
}

const dataTypes = [
    {
        value: 'ActivePromo',
        label: 'Active Promo',
    },
    {
        value: 'MemberSnapshot',
        label: 'Member Snapshot',
    },
    {
        value: 'MemberSnapshots',
        label: 'Member Snapshots',
    },
    {
        value: 'DailyMissionSnapshot',
        label: 'Daily Mission Snapshot',
    },
    {
        value: 'DailyMissionSnapshots',
        label: 'Daily Mission Snapshots',
    },
    {
        value: 'MemberWagers',
        label: 'Member Wagers',
    },
    {
        value: 'UnreadFreebet',
        label: 'Unread Freebet',
    },
]

const placeHolders = {
    "ActivePromo": "",
    "MemberSnapshot": "MemberId:PromoId:DailyMissionId",
    "MemberSnapshots": "MemberId:PromoId",
    "DailyMissionSnapshot": "PromoId:DailyMissionId",
    "DailyMissionSnapshots": "PromoId",
    "MemberWagers": "MemberId",
    "UnreadFreebet": "MemberId",
};

export const DataMonitoringPage = () => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [statistic, setStatistic] = useState([])
    const [monitoringData, setMonitoringData] = useState()
    const [hint, setHint] = useState('')

    const getStats = async () => {
        var stats = await UtilityService.getMonitoringData()
        if (stats) {
            setStatistic(stats)
        }
    }

    async function onSearch(searchCriteria) {
        setLoading(true)
        var data = await UtilityService.getMonitoringDataByType(searchCriteria.dataType, searchCriteria.keyword ?? '')
        setMonitoringData(data)
        setLoading(false)
    }

    function onChange(dataTypeSelected) {
        setMonitoringData(null)
        form.setFieldValue('keyword', '')
        setHint(placeHolders[dataTypeSelected])
    }

    useEffect(() => {
        const getData = async () => { await getStats() }
        getData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const staticticsLayout = (value) => {
        return (
            <>
                <Row gutter={[12, 48]} >
                    <Col span={10}>
                        <Card bordered={false}>
                            <Statistic title="Business Date" value={statistic.businessDate} />
                        </Card>
                    </Col>
                    <Col span={11}>
                        <Tooltip title="Wager Count" color='blue' key='blue'>
                            <Card bordered={false}>
                                <Space size={40}>
                                    <Statistic title="Queue 1" value={statistic.queue1WagerCount} />
                                    <Statistic title="Queue 2" value={statistic.queue2WagerCount} />
                                    <Statistic title="Queue 3" value={statistic.queue3WagerCount} />
                                    <Statistic title="Queue 4" value={statistic.queue4WagerCount} />
                                    <Statistic title="Queue 5" value={statistic.queue5WagerCount} />
                                    <Statistic title="Queue 6" value={statistic.queue6WagerCount} />
                                    <Statistic title="Queue 7" value={statistic.queue7WagerCount} />
                                    <Statistic title="Queue 8" value={statistic.queue8WagerCount} />
                                </Space>
                            </Card>
                        </Tooltip>
                    </Col>
                    <Col span={3}>
                        <Card bordered={false}>
                            <Statistic title="Active Promotion" value={statistic.activePromotionCount} />
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }

    const searchForm = () => {
        return (
            <>
                <Form {...formItemLayout} form={form}>
                    <Row gutter={(18, 8)}>
                        <Col {...colProps}>
                            <Form.Item name="dataType" label='Data Type' labelCol={labelCol}>
                                <Select
                                    allowClear
                                    options={dataTypes}
                                    onChange={onChange}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...colProps}>
                            <Form.Item label="Keywords" labelCol={labelCol}>
                                <Form.Item name="keyword" noStyle>
                                    <Input allowClear />
                                </Form.Item>
                                {hint !== '' ? <Tag className="ant-form-text">Hint: {hint}</Tag> : <></>}
                            </Form.Item>
                        </Col>
                        <Col flex={'auto'} xl={{ span: 0 }}></Col>
                        <Col flex={'none'} xl={{ span: 6 }}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    loading={loading}
                                    onClick={async () => {
                                        setLoading(true)
                                        await onSearch(form.getFieldsValue())
                                        setLoading(false)
                                    }}
                                    icon={<SearchOutlined />}
                                >
                                    Search
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }

    return (
        <>
            <Row gutter={[0, 12]}>
                <Col span={24}>{staticticsLayout(statistic)}</Col>
                <Col span={24}>
                    <Card bordered={false} title='Query Monitoring Data'>
                        {searchForm()}
                        {monitoringData ? <div ><pre style={{ background: '#f5f5f5' }}>
                            <span style={{ color: 'blue' }}>
                                {JSON.stringify(monitoringData, null, 2)}
                            </span></pre></div> : <></>}
                    </Card>
                </Col>
            </Row>
        </>
    )
}
