import React from 'react'
import logo from '../logo.svg'

export const LoggedOutPage = (props) => {
  return (
    <div style={{margin: 'auto'}}>
      <img src={logo} className="app-logo" alt="logo" />
      <h2>Logged out successful</h2>
    </div>
  )
}