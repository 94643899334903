// TODO: Convert Utc to GMT-4 - Gilbert [Done]
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Table, Empty } from 'antd'
import { useTranslation } from 'react-i18next'
import { PrizeItemConstants, TimeConverter } from './core'

export const PrizeItemTable = ({ onRowClick, isLoading, dataSource, pageSize = 50 }) => {
  const { t, i18n } = useTranslation()
  const [page, setPage] = useState(1)

  function handleRowClick(data) {
    if (onRowClick) onRowClick(data)
  }

  const getTranslatedPrizeItemName = () => {
    if (i18n.language === 'en') return 'nameEn'
    if (i18n.language === 'ch') return 'nameCn'
    if (i18n.language === 'th') return 'nameTh'
    return 'nameEn'
  }

  const columns = [
    { title: t('Number'), dataIndex: 'id', render: (text, record, index) => index + 1 + pageSize * (page - 1) },
    {
      title: t('Company'),
      dataIndex: 'company',
      sorter: (a, b) => a.company.localeCompare(b.company)
    },
    {
      title: t('Prize Item Status'),
      dataIndex: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status) => (
        <>
          <span style={{ color: PrizeItemConstants.prizeItemStatuses[status] === 'Enabled' ? 'black' : 'red' }}>{t(PrizeItemConstants.prizeItemStatuses[status])}</span>
        </>
      )
    },
    { title: t('Prize Item ID'), dataIndex: 'id', sorter: (a, b) => a.id - b.id },
    {
      title: t('Prize Item Name'),
      dataIndex: getTranslatedPrizeItemName(),
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, row) => (
        <Button type="link" onClick={() => handleRowClick(row)}>
          {text}
        </Button>
      )
    },
    {
      title: t('Last Updated By'),
      dataIndex: 'updatedBy',
      sorter: (a, b) => a.updatedBy.localeCompare(b.updatedBy)
    },
    {
      title: t('Last Updated Date'),
      dataIndex: 'updatedDate',
      sorter: (a, b) => a.updatedDate.localeCompare(b.updatedDate),
      render: (updatedDt) => (
        <>
          <span>{TimeConverter.UtctoGmtMinus4(updatedDt)}</span>
        </>
      )
    }
  ]

  return (
    <Table
      columns={columns}
      scroll={{ x: 'scroll' }}
      loading={isLoading}
      pagination={{
        pageSize: pageSize,
        showSizeChanger: false,
        onChange(current) {
          setPage(current)
        }
      }}
      dataSource={dataSource}
      rowKey="id"
      size="small"
      locale={{ 
        emptyText: <Empty image={isLoading  ? '' : Empty.PRESENTED_IMAGE_SIMPLE} 
        description={isLoading ? '' : t('No record found')} />
      }}
    />
  )
}

PrizeItemTable.propTypes = {
  onRowClick: PropTypes.func,
  pageSize: PropTypes.number,
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      company: PropTypes.string,
      name: PropTypes.string,
      nameCn: PropTypes.string,
      nameEn: PropTypes.string,
      nameEs: PropTypes.string,
      nameId: PropTypes.string,
      nameTh: PropTypes.string,
      nameVi: PropTypes.string,
      status: PropTypes.string,
      updatedBy: PropTypes.string,
      updatedDate: PropTypes.string
    })
  )
}
