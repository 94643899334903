import axios from 'axios'

const getPageOptionsCompanies = async (lang) => {
    const response = await axios.get(`PageOptions/companies?&Language=${lang}`).catch(console.log)
    return response?.data?.result
}

const getPageOptionsByCompany = async (companyId, lang) => {
    const response = await axios.get(`PageOptions/companyOptions?companyId=${companyId}&language=${lang}`).catch(console.log)
    return response?.data?.result
}

const getPageOptionsBySport = async (sportId, lang) => {
    const response = await axios.get(`PageOptions/sportOptions?sportId=${sportId}&language=${lang}`).catch(console.log)
        return response?.data?.result
}

const getPageOptionsCompetitionsBySport = async (sportId, lang) => {
    const response = await axios.get(`PageOptions/competitionList?sportId=${sportId}&lang=${lang}`).catch(console.log)
    return response?.data?.result
}

export const PageOptionsService = {
    getPageOptionsCompanies,
    getPageOptionsByCompany,
    getPageOptionsBySport,
    getPageOptionsCompetitionsBySport
}