import axios from 'axios'

const getFreeBetValidityInDays = async () => {
    const response = await axios.get(`DailyMissionSetting/freeBetValidityInDays`).catch(console.log)
    return response?.data?.result
}

const getCurrencies = async () => {
    const response = await axios.get(`DailyMissionSetting/currencies`).catch(console.log)
    return response?.data?.result
}

const getWagerTypes = async () => {
    const response = await axios.get(`DailyMissionSetting/wagerTypes`).catch(console.log)
    return response?.data?.result
}

const getSports = async () => {
    const response = await axios.get(`DailyMissionSetting/sports`).catch(console.log)
    return response?.data?.result
}

const getSportBetTypes = async () => {
    const response = await axios.get(`DailyMissionSetting/sportBetTypes`).catch(console.log)
    return response?.data?.result
}

const getCdnUrl = async () => {
    const response = await axios.get(`DailyMissionSetting/cdnUrl`).catch(console.log)
    return response?.data?.result
}

export const DailyMissionSettingService = {
    getFreeBetValidityInDays,
    getCurrencies,
    getWagerTypes,
    getSports,
    getSportBetTypes,
    getCdnUrl
}